import * as katzbatApi from '../../api';
import { API_URL_BASE, getAxionsInstance } from '../../global/repositories/RepositoryBase';

export type DefauleQueryParams = {
  clientId: string;
  licenseNumber: string;
  reportId: string;
  langCode: string;
  includePictures: boolean;
  includeNotes: boolean;
  includeDriver: boolean;
}

export default function RepositoryGeneratePdf() {

  const apiPdf = (clientId?: string) => katzbatApi.PDFSafetyReportApiFactory(undefined, API_URL_BASE, getAxionsInstance(clientId));

  type ExtraEmailQueryParams = DefauleQueryParams & {
    email?: string;
    subject?: string;
    body?: string;
  }

  const generateReportPdf = async ({
    clientId, 
    licenseNumber,
    reportId,
    langCode,
    includePictures,
    includeNotes,
    includeDriver,
  }:DefauleQueryParams): Promise<any> => {
    
    const resutlsFile = await apiPdf(clientId).pdfGenerateVehicleSafetyReportGet(
      clientId, 
      licenseNumber, 
      reportId, 
      langCode,
      includeNotes, 
      includePictures, 
      includeDriver,
      {
        responseType: 'arraybuffer'
      }
    );
    return resutlsFile.data;
  }

  const emailReportPdf = async ({
    clientId, 
    licenseNumber,
    reportId,
    langCode,
    email,
    subject,
    body,
  }: ExtraEmailQueryParams): Promise<any> => {
    const resutls = await apiPdf(clientId).pdfEmailVehicleSafetyReportPost(
      clientId, 
      licenseNumber, 
      reportId,
      langCode,
      email,
      subject,
      body
    );
    return resutls.data;
  }

  return {
    generateReportPdf,
    emailReportPdf
  };
}
