import * as katzbatApi from '../../api';
import { API_URL_BASE, getAxionsInstance } from '../../global/repositories/RepositoryBase';

import { DashboardData } from '../../api';

export default function RepositoryDashboard() {

  const dashboardApi = katzbatApi.DashboardApiFactory(undefined, API_URL_BASE, getAxionsInstance(undefined, true));

  /**
   * Get all clients:
   */
  const getDashboardData = async (): Promise<DashboardData | undefined> => {
    const response = await dashboardApi.dashboardDataGet();
    return response.data;
  };

  const getClientsHeaders = async (): Promise<any> => {
    const response = await dashboardApi.dashboardClientsHeadersGet();
    return response.data;
  }

  return {
    getDashboardData,
    getClientsHeaders
  };
}
