import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RepositoryDashboard from '../../repositories/RepositoryDashboard';
import { ClientHeader } from '../../../api';

// Define a type for the slice state
export type ClientsListState = {
  isLoadingData?: boolean;
  clientsHeadersList?: ClientHeader[];
};

// Define the initial state using that type
const initialState: ClientsListState = {
  isLoadingData: false,
  clientsHeadersList: undefined,
};

export const getClientsHeaders = createAsyncThunk('clientsListSlice/getClientsHeaders', async () => {
  const data = await RepositoryDashboard().getClientsHeaders();
  return data;
});

// Define the clients list slice
export const clientsListSlice = createSlice({
  name: 'clientsListSlice',
  initialState,
  reducers: {
    // Clear when logout:
    clearClientListData: () => initialState,
  },
  extraReducers: (builder) => {

    // Clients headers:
    builder.addCase(getClientsHeaders.pending, (state: ClientsListState, _action) => {
      state.isLoadingData = true;
    });

    builder.addCase(getClientsHeaders.fulfilled, (state: ClientsListState, action) => {
      state.clientsHeadersList = action.payload;
      state.isLoadingData = false;
    });

    builder.addCase(getClientsHeaders.rejected, (state: ClientsListState, _action) => {
      state.clientsHeadersList = undefined;
      state.isLoadingData = false;
    });
  },
});

// Export the actions and reducer
export default clientsListSlice.reducer;
export const { clearClientListData } = clientsListSlice.actions;
