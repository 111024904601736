import { configureStore } from '@reduxjs/toolkit';
import { websiteAppStateSlice } from './websiteAppStateSlice';


export const websiteAppStore = configureStore({
  reducer: {
    appStateSlice: websiteAppStateSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type WebsiteRootState = ReturnType<typeof websiteAppStore.getState>;
export type WebsiteAppDispatch = typeof websiteAppStore.dispatch;
