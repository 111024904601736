
import { LocalizationProvider } from "@mui/x-date-pickers";
import { he } from "date-fns/locale";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// //
// import { he as heLang } from "./langs";
// import { useDashboardAppSelector } from "../../dashboard/hooks/useRedux";

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeLocalization({ children }: Props) {
  // const localeSettings = useDashboardAppSelector(
  //   (state) => state.appStateSlice.appConfig.locale || "he"
  // );
  // const localeLang = localeSettings === heLang.language_code ? he : enUS;
  return (
    <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={he}
        > {children} 
    </LocalizationProvider>
  );
}
