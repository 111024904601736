import { Outlet } from "react-router-dom";

import ThemeProvider from "../../global/theme";
import ThemeLocalization from "../../global/locales";

import RTL from "../../global/theme/options/right-to-left";
import SnackbarProvider from "../../global/components/snackbar/SnackbarProvider";
import { AuthGuard } from "../../app/firebase/guards";
import { UserRole } from "../../api";

export function DriversApp() {
  return (
    <ThemeLocalization>
      <ThemeProvider>
        <RTL themeDirection={"rtl"}>
          <AuthGuard loginRole={UserRole.USER_DRIVER}>
            <SnackbarProvider>
              <Outlet />
            </SnackbarProvider>
          </AuthGuard>
        </RTL>
      </ThemeProvider>
    </ThemeLocalization>
  );
}
