import { configureStore } from '@reduxjs/toolkit';

import { driverOnboardingPageSlice } from '../features/onboarding/driverOnboardingPageSlice';

export const driversAppStore = configureStore({
  reducer: {
    driverOnboardingPageSlice: driverOnboardingPageSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});

export type DriversRootState = ReturnType<typeof driversAppStore.getState>;
export type DriversAppDispatch = typeof driversAppStore.dispatch;
