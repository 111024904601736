import { Helmet } from "react-helmet-async";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";

import { ChangeEvent, useEffect, useMemo } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";

import { useLocales } from "../../../../global/locales";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { DashboardRootState } from "../../../app/dashboardAppStore";
import {
  useDashboardAppSelector,
  useDashboardAppDispatch,
} from "../../../hooks/useRedux";
import Image from "../../../../global/components/image/Image";

import signEample from "../../../assets/images/sign_example.jpg";
import {
  uploadUserSignPic,
  getUserData,
  updateUserData,
} from "./userSettingsPageSlice";
import LoadingView from "../../../components/loading-view/LoadingView";
import { Address, MainUserInfo } from "../../../../api";
import FormProvider from "../../../../global/components/hook-form/FormProvider";
import RHFTextField from "../../../../global/components/hook-form/RHFTextField";
import {
  emailSchemaRequired,
  genericRequiredStringSchema,
  genericStringSchema,
  numberOnlyStringSchema,
  numberOnlyStringSchemaRequired,
  textOnlySchema,
  textOnlySchemaRequired,
} from "../../../../global/utils/formValidators";

export default function SettingsPageUserAndSignature() {
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const dispatch = useDashboardAppDispatch();

  const user = useDashboardAppSelector(
    (state: DashboardRootState) => state.settingsPageSlice.user
  );

  const isLoading = useDashboardAppSelector(
    (state: DashboardRootState) => state.settingsPageSlice.isLoading
  );

  const handleUploadClick = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      const reader = new FileReader();

      reader.onloadend = () => {
        dispatch(uploadUserSignPic(reader.result));
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (user === null) {
      dispatch(getUserData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  type FormFields = {
    companyName: string;
    fullName: string;
    email: string;
    phoneNumber: string;
    address?: Address;
  };

  const UserSchema = Yup.object().shape({
    companyName: genericRequiredStringSchema(translate),
    fullName: textOnlySchemaRequired(translate),
    email: emailSchemaRequired(translate),
    phoneNumber: numberOnlyStringSchemaRequired(translate),
    street: textOnlySchema(translate),
    houseNumber: genericStringSchema(),
    city: textOnlySchema(translate),
    zipCode: numberOnlyStringSchema(translate),
  });

  const defaultValues = useMemo(
    () => ({
      companyName: user?.companyName || "",
      fullName: user?.fullName || "",
      email: user?.email || "",
      street: user?.address?.street || "",
      houseNumber: user?.address?.houseNumber || "",
      city: user?.address?.city || "",
      zipCode: user?.address?.zipCode || "",
      phoneNumber: user?.phoneNumber || "",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  const methods = useForm<FormFields>({
    resolver: yupResolver(UserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (user) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSubmit = async (data: any) => {
    try {
      const updatedUserData: MainUserInfo = {
        companyName: data.companyName,
        fullName: data.fullName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        address: {
          street: data.street,
          houseNumber: data.houseNumber,
          city: data.city,
          zipCode: data.zipCode,
        },
      };
      dispatch(updateUserData(updatedUserData));
    } catch (err) {
      enqueueSnackbar(`${translate("error_update_user_data")}`, {
        variant: "error",
      });
    }
  };

  const UserInfoComponent = () => (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 3 }}>
        <Typography variant="h4">{`${translate(
          "settings_user_details"
        )}`}</Typography>

        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs>
            <RHFTextField
              name="companyName"
              label={`${translate("form_input_company_name")}`}
            />
          </Grid>

          <Grid item xs>
            <RHFTextField
              name="fullName"
              label={`${translate("form_input_name")}`}
            />
          </Grid>

          <Grid item xs>
            <RHFTextField
              name="email"
              label={`${translate("form_input_email")}`}
            />
          </Grid>

          <Grid item xs>
            <RHFTextField
              name="phoneNumber"
              label={`${translate("form_input_phone_number")}`}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3} sx={{ mt: 3 }}>
          <Grid item xs>
            <RHFTextField
              name="street"
              label={`${translate("form_input_street")}`}
            />
          </Grid>

          <Grid item xs>
            <RHFTextField
              name="houseNumber"
              label={`${translate("form_input_house_number")}`}
            />
          </Grid>

          <Grid item xs>
            <RHFTextField
              name="city"
              label={`${translate("form_input_city")}`}
            />
          </Grid>

          <Grid item xs>
            <RHFTextField
              name="zipCode"
              label={`${translate("form_input_zip_code")}`}
            />
          </Grid>
        </Grid>

        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate("global_update_data")}`}
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );

  return (
    <>
      <Helmet>
        <title>{`${translate("nav_subheader_system")}`}</title>
      </Helmet>

      <Container maxWidth="xl">
        <CustomBreadcrumbs
          heading={`${translate("nav_subheader_system")}`}
          links={[
            {
              name: `${translate(
                PATH_DASHBOARD.system.settings_signature.label_key
              )}`,
            },
          ]}
        />

        {!isLoading && user ? (
          <Stack spacing={3}>
            <UserInfoComponent />

            <Stack direction="row" spacing={3}>
              <Card sx={{ width: "33%" }}>
                <CardHeader title={`${translate("settings_singature")}`} />
                <Typography variant="body1">
                  {`${translate("settings_singature_upload_description")}`}
                </Typography>
                <Box sx={{ height: "10px" }} />
                <Stack spacing={2} sx={{ p: 3 }}>
                  {user?.signaturePicUrl ? (
                    <img src={user?.signaturePicUrl} alt="signature" />
                  ) : (
                    <Image
                      sx={{ width: "100%", height: 240 }}
                      src={signEample}
                      alt="sign example"
                    />
                  )}

                  <Box sx={{ height: "10px" }} />

                  <Button variant="contained" component="label">
                    {`${translate("settings_singature_upload")}`}
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={handleUploadClick}
                    />
                  </Button>
                </Stack>
              </Card>
            </Stack>
          </Stack>
        ) : (
          <LoadingView />
        )}
      </Container>
    </>
  );
}
