import { Stack, Typography } from "@mui/material";

export default function DriverOnboardingTerms({
  name,
  govId,
}: {
  name: string;
  govId: string;
}) {
  return (
    <Stack>
      <Typography variant="h5" sx={{ pt: 3 }} color="primary">
        אנא קרא את ההצהרה הבאה:
      </Typography>
      <div>
        <p
          style={{
            textAlign: "start",
          }}
        >
          אני החתום מטה <strong>{name}</strong> בעל תעודת זהות מספר
          <strong> {govId}</strong> מצהיר בזאת כי לא נתגלו אצלי, לפי מיטב
          ידיעתי, מגבלות במערכת העצבים, העצמות, הראיה או השמיעה ומצב בריאותי
          הנוכחי כשיר לנהיגה. בנוסף, אני מצהיר כי:
        </p>
        <ol
          style={{
            textAlign: "start",
          }}
        >
          <li>
            לא נפסלתי מלהחזיק ברשיון נהיגה מ: בית משפט, רשות הרישוי או קצין
            משטרה, ולחלופין רשיון הנהיגה אשר ברשותי לא הותלה על ידי גורמים
            כאמור.
          </li>
          <li>
            אין לי כל מגבלה בריאותית או רפואית המונעת ממני מלהחזיק ברשיון
            הנהיגה.
          </li>
          <li>אינני צורך סמים.</li>
          <li>אינני צורך אלכוהול מעבר לכמות המותרת על פי דין.</li>
        </ol>
        <p>
          <strong>
            אני מתחייב כי במידה ויוטלו איזה שהן הגבלות על רשיון הנהיגה אשר
            ברשותי, ולחלופין במידה יחול שינוי במצב בריאותי באופן המונע ממני
            מלהמשיך ולנהוג, אדווח על כך מיידית לקצין הבטיחות.
          </strong>
        </p>
      </div>

      <Typography variant="h5" sx={{ pt: 3 }} color="primary">
        שעות נהיגה - תקנה 168
      </Typography>
      <div>
        <p
          style={{
            textAlign: "start",
          }}
        >
          <strong>א.</strong> הנוהג רכב ציבורי, אוטובוס פרטי או רכב מסחרי שמשקלו
          הכולל המותר עולה על 6000 ק"ג, והנוהג רכב מנועי מסוג אחר המסיע או
          המורשה על פי רשיון או היתר להסיע אחד עשר אנשים או יותר בנוסף על הנהג -
        </p>
        <ol
          style={{
            textAlign: "start",
          }}
        >
          <li>
            לא יתחיל את יום עבודתו בנהיגה אלא אחרי מנוחה שמחוץ לעבודה במשך 7
            שעות רצופות לפחות;
          </li>
          <li>
            לא ינהג בו יותר מ-12 שעות בכל תקופה של 24 שעות ולא יותר מ-68 שעות
            בכל תקופה של 7 ימים;
          </li>
          <li>
            יפסיק נהיגתו לחצי שעה לפחות במשך כל תקופת נהיגה של 4 שעות רצופות
            כאמור בתקנת משנה (ג), למעט ההפסקות לפי תקנת משנה זו;
          </li>
          <li>
            לא ינהג יותר מ-9 שעות אלא אם הפסיק את נהיגתו לשעה לפחות אחרי נהיגה
            במשך 6 שעות רצופות אך לא יאוחר מאשר אחרי נהיגה במשך 8 שעות ו-30 דקות
            רצופות;
          </li>
          <li>
            יימצא במנוחה שמחוץ לעבודה לפחות במשך 25 שעות רצופות (להלן בתקנה זו –
            יום מנוחה), בכל תקופה של 8 ימים, ובלבד שמספר ימי המנוחה כאמור בשנה
            לא יפחת מ-52; הוראות פסקה זו לא יחולו על נוהג רכב מנועי שאיננו רכב
            ציבורי, אוטובוס פרטי או רכב מסחרי שמשקלו הכולל המותר עולה על 6000
            ק"ג, המסיע שמונה אנשים או יותר בנוסף על הנהג שלא בדרך עיסוקו הרגיל.
          </li>
        </ol>
        <p
          style={{
            textAlign: "start",
          }}
        >
          <strong>ב.</strong> מי שבידו השליטה על רכב מנועי מהסוגים המפורטים
          בתקנת משנה (א) או על הנוהג רכב מנועי כאמור, לא יניח לנוהג לנהוג אלא
          בהתאם להוראות תקנה זו.
        </p>
        <p
          style={{
            textAlign: "start",
          }}
        >
          <strong>ג.</strong> בתקנה זו, "נהיגה" – נהיגה בפועל, עבודה אחרת הקשורה
          ברכב מנועי או בטיפול במטען המובל עליו ובטעינתו, סידורו או פריקתו של
          מטען כאמור או טיפול בנוסעים המוסעים ברכב, לרבות ההפסקות לפי פסקאות (3)
          ו-(4) לתקנת משנה (א), והמתנה או הפסקה הכרוכה בפעולות האמורות במישרין
          או בעקיפין.
        </p>
        <p
          style={{
            textAlign: "start",
          }}
        >
          <strong>ד.</strong> הוראות תקנה זו לא יחולו על הנוהג רכב של צה"ל
          שלגביו חלות הגבלות של שעות נהיגה כפי שנקבעו בפקודות הצבא.
        </p>
      </div>
    </Stack>
  );
}
