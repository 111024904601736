
// ----------------------------------------------------------------------

export const _home_faqs = [
  {
    id: 1,
    question: 'האם יש צורך בהתקנת תוכנה על המחשב?',
    answer: 'לא, אין צורך בהתקנת תוכנה על המחשב. כל הפעולות נעשות על ידי התחברות למערכת דרך האתר.',
  },
  {
    id: 2,
    question: 'האם ניתן להשתמש במערכת מכל מקום?',
    answer: 'כן, ניתן להתחבר למערכת מכל מחשב בכל מקום בעולם.',
  },
  {
    id: 3,
    question: 'מה ניתן לעשות באפליקציה?',
    answer: 'האפליקציה מאפשר לך לבצע את בדיקות הבטיחות החודשיות בשטח, להפיק דוחות ולשמור אותם במערכת.',
  },
  {
    id: 4,
    question: 'האם המידע שלי מאובטח?',
    answer: 'כמובן, כל המידע מאובטח על שרתים מאובטחים.',
  },
  {
    id: 5,
    question: 'האם ניתן להתאים אישית את המערכת?',
    answer: 'כן, אנחנו נשמח להתאים את המערכת לצורך הייחודי של העסק שלך.',
  }
]