import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import { alpha } from "@mui/material/styles";
import { Box, Divider, Typography, MenuItem, IconButton } from "@mui/material";
// routes
import { PATH_AUTH } from "../../../routes/paths";
// auth

// components
import MenuPopover from "../../../components/menu-popover";
import CustomAvatar from "../../../components/custom-avatar/CustomAvatar";
import { useAuthContext } from "../../../../app/firebase/useAuthContext";
import { useSnackbar } from "../../../../global/components/snackbar";
import useLocales from "../../../../global/locales/useLocales";
import { useDashboardAppSelector } from '../../../hooks/useRedux';
import { DashboardRootState, dashboardAppStore } from '../../../app/dashboardAppStore';

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const navigate = useNavigate();
  const { translate } = useLocales();

  const { logout } = useAuthContext();

  const user = useDashboardAppSelector(
    (state: DashboardRootState) => state.settingsPageSlice.user
  );

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      dashboardAppStore.dispatch({type: "USER_LOGOUT"});
      logout();
      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to logout!", { variant: "error" });
    }
  };

  return (
    <>
      <IconButton
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar
          src={user?.profilePictureUrl || ""}
          alt={user?.fullName}
          name={user?.fullName || user?.email || "A"}
        />
      </IconButton>

      <MenuPopover
        disabledArrow
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 200, p: 0, marginTop: 2 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.fullName}
          </Typography>

          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {`${translate("logout_title")}`}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
