import { Container } from "@mui/material";

import Markdown from '../../components/markdown';
// import { HEADER } from '../../layout/config-layout';

const htmlContent = `
<body>
        <strong><h3>מדיניות פרטיות</h3></strong> <p>
          42Geek Studio בנתה את האפליקציה Katzbat כאפליקציה מסחרית. שירות זה ניתן על ידי
          42Geek Studio ומיועד לשימוש כפי שהוא.
        </p> <p>
          עמוד זה משמש להודיע למבקרים לגבי המדיניות שלנו
          בנוגע לאספקה, שימוש וחשיפת מידע אישי
          במקרה שמישהו בחר להשתמש בשירות שלנו.
        </p> <p>
          אם בחרת להשתמש בשירות שלנו, הינך מסכים עם
          לאסוף ולהשתמש במידע ביחס למדיניות זו. המידע האישי שאנו אוספים
          משמש לספק ולשדרג את השירות. לא נשתמש או נשתף את המידע שלך עם
          אחרים למעט כפי שמתואר במדיניות הפרטיות הזו.
        </p> <p>
          המונחים שמשמשים במדיניות פרטיות זו יש להם את אותו המשמעות
          כמו בתנאי השירות שלנו, שניתן לגשת אליהם ב
          Katzbat אלא אם נקבע אחרת במדיניות פרטיות זו.
        </p> <p><strong>איסוף ושימוש במידע</strong></p> <p>
          לשיפור חוויה טובה יותר במהלך השימוש בשירות שלנו, יתכן ונצטרך ממך לספק לנו מספר פרטים אישיים.
          המידע שאנו מבקשים יישמר על ידינו וישמש כפי שמתואר במדיניות הפרטיות הזו.
        </p> <div><p>
          האפליקציה משתמשת בשירותים של צדדים שלישיים שעשויים לאסוף
          מידע שמשמש לזיהוי שלך.
        </p> <p>
          קישור למדיניות הפרטיות של ספקי שירות של צדדים שלישיים
          שמשמשים באפליקציה
        </p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">שירותי Google Play</a></li><li><a href="https://firebase.google.com/support/privacy" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li></ul></div> <p><strong>נתוני יומן</strong></p> <p>
          אנו רוצים להודיע לך כי בכל פעם
          שאתה משתמש בשירות שלנו, במקרה של שגיאה באפליקציה
          אנו אוספים נתונים ומידע (דרך מוצרים של צדדים שלישיים)
          בטלפון שלך, הנקראים נתוני יומן. נתוני יומן אלו עשויים
          לכלול מידע כגון כתובת האינטרנט של המכשיר שלך, שם המכשיר, גרסת המערכת הפעלה, הגדרות האפליקציה בעת השימוש בשירות שלנו,
          זמן ותאריך השימוש בשירות, וסטטיסטיקות נוספות.
        </p> <p><strong>עוגיות</strong></p> <p>
          עוגיות הם קבצים עם כמות קטנה של מידע
          הנהוגים כמזהים ייחודיים אנונימיים. אלו נשלחים
          לדפדפן שלך מהאתרים שאתה בוקר בהם ומאוחסנים
          בזיכרון הפנימי של המכשיר שלך.
        </p> <p>
          שירות זה אינו משתמש ב "עוגיות" אלו באופן פרטי. אף על פי כן,
          האפליקציה עשויה להשתמש בקוד וספריות של צדדים שלישיים שמשתמשים
          ב "עוגיות" לאיסוף מידע ושיפור שירותיהם.
          יש לך את האפשרות לקבל או לסרב לעוגיות אלו
          ולדעת מתי עוגיה נשלחת למכשיר שלך. אם תבחר לסרב לעוגיות שלנו, ייתכן שלא תוכל להשתמש בקטעים מסוימים של שירות זה.
        </p> <p><strong>ספקי שירות</strong></p> <p>
          אנו עשויים לפעול עם חברות ו
          אנשים של צדדים שלישיים מסיבות הבאות:
        </p> <ul><li>לסייע בשיפור השירות שלנו;</li> <li>לספק את השירות במקומנו;</li> <li>לבצע שירותים קשורים לשירות שלנו; או</li> <li>לעזור לנו בניתוח כיצד השירות שלנו נעשה שימוש בו.</li></ul> <p>
          אנו רוצים להודיע למשתמשים של שירות זה
          שלצדדים שלישיים אלו יש גישה למידע האישי שלהם.
          הסיבה היא לביצוע המשימות שהוטחו להם למענה על
          פעולות שהוטחו להם מטעמנו. עם זאת, הם חייבים לא ל
          חשוף או להשתמש במידע למטרה אחרת.
        </p> <p><strong>אבטחה</strong></p> <p>
          אנו מעריכים את האמון שלך בספק לנו את ה
          מידע האישי שלך, ולכן אנו מתאמצים להשתמש באמצעים
          הסבירים מסחרית. אך זכור שאף אמצעי
          העברת מידע דרך האינטרנט, או שמירתו באמצעות אלקטרוניקה,
          אינם 100% מאובטחים ואמינים, ואין לנו
          את היכולת להבטיח את בטחונך המוחלט.
        </p> <p><strong>קישורים לאתרים אחרים</strong></p> <p>
          שירות זה עשוי לכלול קישורים לאתרים אחרים. אם תלחץ על
          קישור מצד שלישי, תועבר לאותו אתר. שים
          לב שאתרים חיצוניים אלו אינם מופעלים על ידינו.
          לכן, אנו ממליצים לך לבדוק את
          מדיניות הפרטיות של אתרים אלו. אנו
          אין לנו שליטה ואין לנו אחריות לתוכן,
          מדיניות הפרטיות או הפעולות של אתרים חיצוניים או
          שירותים.
        </p> <p><strong>פרטיות ילדים</strong></p> <div><p>
          שירותים אלו אינם מתייחסים לפרטים מתחת לגיל 13.
          אנו אינו אוספים במכולת פרטים אישיים
          מילדים בגיל 13 ומטה. במקרה
          בו נגלה כי ילד בגיל 13 ומטה סיפק
          לנו מידע אישי, אנו מוחקים אותו ממשרת השרתים שלנו מיד. אם אתה הורה או הוראה
          ואתה מודע לכך שילדך סיפק לנו מידע אישי, אנא צור
          קשר איתנו כדי שנוכל לפעול בהתאם.
        </p></div> <p><strong>שינויים במדיניות הפרטיות הזו</strong></p> <p>
          יתכן ונעדכן את מדיניות הפרטיות שלנו
          מעת לעת. לכן, אנו ממליצים לך לבדוק את דף זה
          מדי פעם לשינויים. אנו
          נודיע לך על כל שינוי על ידי פרסום מדיניות פרטיות חדשה בדף זה.
        </p> <p>מדיניות זו בתוקף מ-2023-12-01</p> <p><strong>יצירת קשר</strong></p> <p>
          אם יש לך שאלות או הצעות בנוגע ל
          מדיניות הפרטיות שלנו, אל תהסס ליצור קשר איתנו בכתובת
          app@katzbat.co.il.
        </p> <p>דף מדיניות הפרטיות הזה נוצר באתר <a href="https://privacypolicytemplate.net" target="_blank" rel="noopener noreferrer">privacypolicytemplate.net </a>והותאם/נוצר על ידי <a href="https://app-privacy-policy-generator.nisrulz.com/" target="_blank" rel="noopener noreferrer">מחולל מדיניות הפרטיות של אפליקציות</a></p>
      </body>
`
export function AppPrivacyPolicy() {
  return (
    <>
      <Container
        sx={{
          py: 10,
        }}
      >
        <Markdown sx={{pt: 5}}content={htmlContent} />
      </Container>
    </>
  );
}
