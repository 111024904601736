import { Suspense, lazy, ElementType } from 'react';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// AUTH
export const DashboardLoginView = Loadable(lazy(() => import('../features/auth/DashboardLoginView')));
export const RegisterView = Loadable(lazy(() => import('../features/auth/register/RegisterView')));
export const NewPasswordPage = Loadable(lazy(() => import('../features/auth/NewPasswordPage')));
export const ResetPasswordPage = Loadable(lazy(() => import('../features/auth/ResetPasswordPage')));