import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from '../../../dashboard/components/loading-view';
//
import { useAuthContext } from '../useAuthContext';
import DriverLoginPage from '../../../drivers/features/login/DriverLoginPage';
import { UserRole } from '../../../api';
import { PATH_AUTH } from '../../../dashboard/routes/paths';

// ----------------------------------------------------------------------

type AuthGuardProps = {
  loginRole: UserRole;
  children: React.ReactNode;
};

export function AuthGuard({ loginRole, children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized } = useAuthContext();

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isInitialized) {
    return <LoadingScreen showLogo/>;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }

    switch (loginRole) {
      case UserRole.USER_MAIN:
        return <Navigate to={PATH_AUTH.login} />;
      default:
        return <DriverLoginPage />;
    }
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <> {children} </>;
}
