import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CompleteDriverOnboardingReq, DriverUser } from "../../../api";
import RepositoryDriver from "../../../dashboard/repositories/RepositoryDriver";

export type DriverOnboardingPageState = {
    isLoading: boolean;
    driverUser: DriverUser | undefined;
    onCompleted: boolean;
};

const initialState: DriverOnboardingPageState = {
    isLoading: false,
    driverUser: undefined,
    onCompleted: false,
};

export const getDriverOnboarding = createAsyncThunk('driverOnboardingPage/getDriverOnboarding', async () => {
    const data = await RepositoryDriver().getDriverOnboarding();
    return data;
});

export const completeOnboarding = createAsyncThunk('driverOnboardingPage/completeOnboarding', async (completeDriverOnboardingReq: CompleteDriverOnboardingReq) => {
    const data = await RepositoryDriver().completeOnboarding(completeDriverOnboardingReq);
    return data;
});

export const driverOnboardingPageSlice = createSlice({
    name: "driverOnboardingPage",
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        // === GET: Get driver ===
        builder.addCase(getDriverOnboarding.pending, (state) => {
            state.isLoading = true;
            state.driverUser = undefined;
        });

        builder.addCase(getDriverOnboarding.fulfilled, (state, action: PayloadAction<DriverUser | undefined>) => {
            state.driverUser = action.payload;
            state.isLoading = false;
        });

        builder.addCase(getDriverOnboarding.rejected, (state) => {
            state.isLoading = false;
            state.driverUser = undefined;
        });

        // === POST: Complete onboarding ===
        builder.addCase(completeOnboarding.pending, (state) => {
            state.isLoading = true;
        });

        builder.addCase(completeOnboarding.fulfilled, (state, action: PayloadAction<boolean | undefined>) => {
            state.isLoading = false;
            state.onCompleted = action.payload ?? false;
        });

        builder.addCase(completeOnboarding.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export default driverOnboardingPageSlice.reducer;