import { Key } from 'react';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";


export type GenericSelectOptionItem<T> =  {
  key: Key | null | undefined,
  title: String,
  value: T
}

export default function GenericSelectOptions<T>({
  filedTitle,
  currentValue,
  options,
  onSelect
}: {
  filedTitle: String;
  currentValue?: T;
  options: GenericSelectOptionItem<T>[];
  onSelect?: (_value: T) => void;
}) {
  
  return (
    <FormControl fullWidth>
      
      <InputLabel id="demo-simple-select-label">{`${filedTitle}`}</InputLabel>
      <Select
        value={currentValue ? JSON.stringify(currentValue) : ""}
        onChange={(event: SelectChangeEvent) => {
          const slectedValue = options.find((item: GenericSelectOptionItem<T>) => JSON.stringify(item.value) === event.target.value)?.value;
          if (onSelect) {
            onSelect(slectedValue as T);
          }
        }}
      >
        
        {options.map((item: GenericSelectOptionItem<T>) => (
          <MenuItem key={item.key} value={JSON.stringify(item.value)}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
