import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-view';

// ----------------------------------------------------------------------

const Loadable = (showLoading: boolean, Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={showLoading && <LoadingScreen showLogo/>}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

// DASHBOARD
export const GeneralAppPage = Loadable(false, lazy(() => import('../features/main/DashboardPage')));

// MANEGMENT
export const ClientsListPage = Loadable(false, lazy(() => import('../features/clientsList/ClientsListPage')));
export const ClientPage = Loadable(false, lazy(() => import('../features/clientPage/main/ClientPage')));
export const VehiclePage = Loadable(false, lazy(() => import('../features/vehicles/VehiclePage')));
export const VehicleReportPage = Loadable(false, lazy(() => import('../features/vehicles/report/VehicleReportPage')));
export const DriverPage = Loadable(false, lazy(() => import('../features/drivers/DriverPage')));

// MAIN
export const PageError = Loadable(true, lazy(() => import('../features/common/views/PageError')));

// SYSTEM
export const SettingsPage = Loadable(false, lazy(() => import('../features/settings/user/SettingsPageSignature')));

