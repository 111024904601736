import { styled } from "@mui/material/styles";
import { Button, Typography, SxProps } from '@mui/material';

const StyledContent = styled("div")(({ theme }) => ({
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

type Props = {
  title: string;
  subtitle: string;
  buttonTitle?: string;
  onAction?: Function;
  sx?: SxProps;
};
export default function GenericMessageView({
  title,
  subtitle,
  buttonTitle,
  onAction,
  sx
}: Props) {
  return (
    <StyledContent sx={{ textAlign: "center", alignItems: "center", ...sx }}>
      <Typography variant="h3" paragraph>
        {`${title}`}
      </Typography>

      <Typography sx={{ color: "text.secondary" }}>{`${subtitle}`}</Typography>

      {(buttonTitle && onAction) && (
        <Button
          size="large"
          variant="contained"
          sx={{ marginTop: 4 }}
          onClick={() => onAction()}
        >
          {`${buttonTitle}`}
        </Button>
      )}
    </StyledContent>
  );
}
