import * as katzbatApi from '../../api';

import { API_URL_BASE, getAxionsInstance } from '../../global/repositories/RepositoryBase';
import { SendFeedbackReq, WebsiteProduct } from '../../api';

export interface IRepositoryWebsiteApp {
    sendFeedback: (_sendFeedbackReq: SendFeedbackReq) => Promise<boolean>;
    getProductsDetails: () => Promise<WebsiteProduct[]>;
    // registerNewUser: (_registerNewUserReq: RegisterNewUserReq) => Promise<string>;
    // checkForStripeSession: () => Promise<string>;
    // createNewStripeSession: (_createNewCheckoutSessionReq: CreateNewCheckoutSessionReq) => Promise<string>;
}

export default function RepositoryWebsiteApp(): IRepositoryWebsiteApp {

    const appApi = katzbatApi.AppApiFactory(undefined, API_URL_BASE, getAxionsInstance());
    const websiteApi = katzbatApi.WebsiteApiFactory(undefined, API_URL_BASE, getAxionsInstance());
    
    const sendFeedback = async (sendFeedbackReq: SendFeedbackReq): Promise<boolean> => {
        const response = await appApi.appSendFeedbackPost(sendFeedbackReq);
        return response.data;
    }

    const getProductsDetails = async (): Promise<WebsiteProduct[]> => {
        const response = await websiteApi.websitePricingGet();
        return response.data;
    }

    // const registerNewUser = async (registerNewUserReq: RegisterNewUserReq): Promise<string> => {
    //     const encryptedPassword = CryptoJS.AES.encrypt(registerNewUserReq.password, process.env.REACT_APP_ENCRYPTION_KEY || "").toString();
    //     registerNewUserReq.password = encryptedPassword;
    //     const response = await websiteApi.websiteRegisterPost(registerNewUserReq);
    //     return response.data.stripeClientSecret;
    // }

    // const checkForStripeSession = async (): Promise<string> => {
    //     const response = await websiteApi.websiteCheckStripeSessionGet();
    //     return response.data;
    // }

    // const createNewStripeSession = async (createNewCheckoutSessionReq: CreateNewCheckoutSessionReq): Promise<string> => {
    //     const response = await websiteApi.websiteCreateCheckoutSessionPost(createNewCheckoutSessionReq);
    //     return response.data;
    // }

    return {
        sendFeedback,
        getProductsDetails,
        // registerNewUser,
        // checkForStripeSession,
        // createNewStripeSession
    };
}