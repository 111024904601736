import * as katzbatApi from '../../api';
import { API_URL_BASE, getAxionsInstance } from '../../global/repositories/RepositoryBase';

import { VehicleReportType, DocumentConfig, DocumentCategory, DocumentConfigOption, VehicleType, DocumentInput, VehicleReportConfig, WebAppConfig } from '../../api';


export interface IRepositoryConfig {
    getWebAppConfig: (_clientId?: string) => Promise<WebAppConfig | undefined>;
    staticTestCategories: (_reportType: VehicleReportType) => Promise<DocumentCategory[] | undefined>;
    getDocumentConfig: (_langCode: string, _reportType: VehicleReportType) => Promise<DocumentConfig | undefined>;
    getVehicleAvilableReportTypes: (_langCode: string, _vehicleType: VehicleType) => Promise<DocumentConfigOption[] | undefined>;
    getStaticDocumentInputs: (_langCode: string) => Promise<DocumentInput[] | undefined>;
    getVehicleTestConfig: (_reportType: VehicleReportType) => Promise<VehicleReportConfig | undefined>;
    updateVehicleTestConfig: (_reportType: VehicleReportType, _config: VehicleReportConfig) => Promise<boolean | undefined>;
    setDaysBeforeAlert: (_days: number) => Promise<boolean | undefined>;
}


export default function RepositoryConfig(): IRepositoryConfig {

    const appConfigApi = (clientId?: string) => katzbatApi.AppConfigApiFactory(undefined, API_URL_BASE, getAxionsInstance(clientId));

    const getWebAppConfig = async (clientId?: string): Promise<WebAppConfig | undefined> => {
        const response = await appConfigApi(clientId).appConfigWebAppConfigGet();
        return response.data;
    }

    const staticTestCategories = async (reportType: VehicleReportType): Promise<DocumentCategory[] | undefined> => {
        const response = await appConfigApi().appConfigStaticTestCategoriesQuestionsGet(reportType);
        return response.data;
    }

    const getDocumentConfig = async (langCode: string, reportType: VehicleReportType): Promise<DocumentConfig | undefined> => {
        const response = await appConfigApi().appConfigDocumentConfigGet(langCode, reportType);
        return response.data;
    }

    const getVehicleAvilableReportTypes = async (langCode: string, vehicleType: VehicleType): Promise<DocumentConfigOption[] | undefined> => {
        const response = await appConfigApi().appConfigAvailableReportTypesGet(langCode, vehicleType);
        return response.data;
    }

    const getStaticDocumentInputs = async (langCode: string): Promise<DocumentInput[] | undefined> => {
        const response = await appConfigApi().appConfigStaticDocumentConfigGet(langCode);
        return response.data;
    }

    const getVehicleTestConfig = async (reportType: VehicleReportType): Promise<VehicleReportConfig | undefined> => {
        const response = await appConfigApi().appConfigVehicleTestsConfigGet(reportType);
        return response.data;
    }

    const updateVehicleTestConfig = async (reportType: VehicleReportType, config: VehicleReportConfig): Promise<boolean | undefined> => {
        const response = await appConfigApi().appConfigVehicleTestsConfigPatch(reportType, config);
        return response.data;
    }

    const setDaysBeforeAlert = async (days: number): Promise<boolean | undefined> => {
        const response = await  appConfigApi().appConfigSetDaysBeforeAlertsPost(days);
        return response.data;
    }


    return {
        getWebAppConfig,
        staticTestCategories,
        getDocumentConfig,
        getVehicleAvilableReportTypes,
        getStaticDocumentInputs,
        getVehicleTestConfig,
        updateVehicleTestConfig,
        setDaysBeforeAlert
    };
}