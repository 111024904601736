// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}/${sublink}`;
}

// ----------------------------------------------------------------------

export const PATH_PAGE = {
  error: '/error',
};

// ----------------------------------------------------------------------

const ROOTS_AUTH = '/auth';
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, 'login'),
  // register: path(ROOTS_AUTH, 'register'),
  resetPassword: path(ROOTS_AUTH, 'reset-password'),
  verify: path(ROOTS_AUTH, 'verify'),
  newPassword: path(ROOTS_AUTH, 'new-password')
};

// ----------------------------------------------------------------------
const ROOTS_DRIVER = '/driver';
export const PATH_DRIVER = {
  root: ROOTS_DRIVER,
  login: path(ROOTS_DRIVER, 'login'),
  onboarding: path(ROOTS_DRIVER, 'onboarding')
};

// ----------------------------------------------------------------------
const ROOTS_DASHBOARD = '/dashboard';
export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  
  general: {
    label_key: "nav_subheader_general",
    app: { 
      label_key: "nav_app", 
      path: path(ROOTS_DASHBOARD, 'app'),
    },
  },

  management: {
    label_key: "nav_subheader_management",
    
    clients: {
      label_key: "nav_clients", 
      path: path(ROOTS_DASHBOARD, 'clients'),
      
      client: (clientId: string) => path(ROOTS_DASHBOARD, `clients/${clientId}`),

      vheicle: {
        label_key: "nav_clients_vehicle",
        path: (clientId: string, licenseNumber: string, innerPage: string | undefined = undefined) =>  path(ROOTS_DASHBOARD, `clients/${clientId}/vehicle/${licenseNumber}${innerPage ? `#${innerPage}` : ''}`),
      },

      driver: {
        label_key: "nav_clients_driver",
        path: (clientId: string, driverId: string) =>  path(ROOTS_DASHBOARD, `clients/${clientId}/driver/${driverId}`),
      },

      report: {
        label_key: "nav_clients_reports",
        path: (clientId: string, licenseNumber: string, reportId: string) =>  path(ROOTS_DASHBOARD, `clients/${clientId}/report/${licenseNumber}/${reportId}`),
      }
    },
  },
  
  system: {
    label_key: "nav_subheader_system",
    settings: {
      label_key: "nav_settings",
    },
    settings_vehicle_report: { 
      label_key: "nav_settings_vehicle_report", 
      path: path(ROOTS_DASHBOARD, 'settings_vehicle_report'),
    },
    settings_signature: { 
      label_key: "nav_settings_signature", 
      path: path(ROOTS_DASHBOARD, 'settings_signature'),
    },
  },
};