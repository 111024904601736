import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

// import { usePathname } from '../../routes/hooks';
// import { RouterLink } from '../../routes/components';

import { useBoolean } from '../../../global/hooks/use-boolean';
// import { useResponsive } from '../../hooks/use-responsive';

import Iconify from '../../../global/components/iconify';

import { NavSubListProps } from './nav/types';
import { PATH_WEBSITE } from '../../routes/paths';
// import { pageLinks, navConfig } from './config-navigation';

// ----------------------------------------------------------------------


// ----------------------------------------------------------------------

export function ListDesktop({ list }: { list: NavSubListProps }) {
  // const pathname = usePathname();

  return (
    <Stack spacing={1.5} alignItems="flex-start">
      <Typography variant="subtitle2">{list.subheader}</Typography>

      {list.items?.map((link) => 
        // const active = pathname === link.path || pathname === `${link.path}/`;

         (
          <Link
            // component={RouterLink}
            key={link.title}
            href={link.path}
            variant="caption"
            sx={{
              color: 'text.secondary',
              '&:hover': {
                color: 'text.primary',
              },
              // ...(active && {
              //   color: 'text.primary',
              //   fontWeight: 'fontWeightSemiBold',
              // }),
            }}
          >
            {link.title}
          </Link>
        )
      )}
    </Stack>
  );
}

// ----------------------------------------------------------------------

export function ListMobile({ list }: { list: NavSubListProps }) {
  // const pathname = usePathname();

  const listExpand = useBoolean();

  return (
    <Stack spacing={1.5} alignItems="flex-start">
      <Typography
        variant="subtitle2"
        onClick={listExpand.onToggle}
        sx={{
          cursor: 'pointer',
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        {list.subheader}
        <Iconify
          width={16}
          icon={listExpand.value ? 'carbon:chevron-down' : 'carbon:chevron-right'}
          sx={{ ml: 0.5 }}
        />
      </Typography>

      <Collapse in={listExpand.value} unmountOnExit sx={{ width: 1 }}>
        <Stack spacing={1.5} alignItems="flex-start">
          {list.items?.map((link) => (
            <Link
              // component={RouterLink}
              key={link.title}
              href={link.path}
              variant="caption"
              sx={{
                color: 'text.secondary',
                '&:hover': {
                  color: 'text.primary',
                },
                // ...(pathname === `${link.path}/` && {
                //   color: 'text.primary',
                //   fontWeight: 'fontWeightSemiBold',
                // }),
              }}
            >
              {link.title}
            </Link>
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
}

// ----------------------------------------------------------------------

export default function Footer() {
  // const mdUp = useResponsive('up', 'md');

  // const pathname = usePathname();

  // const mobileList = navConfig.find((i) => i.title === 'Pages')?.children || [];

  // const desktopList = pageLinks.sort((listA, listB) => Number(listA.order) - Number(listB.order));

  // const renderLists = mdUp ? desktopList : mobileList;

  // const isHome = pathname === '/';

  const _simpleFooter = (
    <Container sx={{ py: 8, textAlign: 'center' }}>
      {/* <Logo /> */}

      <Typography variant="caption" component="div" sx={{ color: 'text.secondary' }}>
        © 2024. כל הזכויות שמורות
      </Typography>
    </Container>
  );

  const mainFooter = (
    <>
      

      <Divider sx={{pt: 5}}/>

      <Container>
        <Stack
          spacing={2.5}
          direction={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          sx={{ py: 3, textAlign: 'center' }}
        >
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            © 2024. כל הזכויות שמורות
          </Typography>

          <Stack direction="row" spacing={3} justifyContent="center">
            <Link 
              href={PATH_WEBSITE.app_privacy_policy}
              variant="caption" 
              sx={{ color: 'text.secondary', cursor: 'pointer'}}
            >
              מדיניות פרטיות
            </Link>

            {/* <Link 
              href={PATH_WEBSITE.support}
              variant="caption" 
              sx={{ color: 'text.secondary' }}
            >
              תמיכה טכנית
            </Link> */}
          </Stack>
        </Stack>
      </Container>
    </>
  );

  return <footer>{mainFooter}</footer>;
}
