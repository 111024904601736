import * as Yup from "yup";

export const textOnlySchema = (translate: Function) =>
    Yup.string()
        .matches(/^[a-zA-Zא-ת\s]*$/, translate("form_validation_text_only"));

export const textOnlySchemaRequired = (translate: Function) =>
    textOnlySchema(translate)
        .required(`${translate("form_validation_global")}`);

export const phoneNumberSchema = () =>
    Yup.string();



export const numberOnlySchema = (translate: Function) =>
    Yup.number().typeError(`${translate("form_validation_numbers_only")}`);

export const numberWithDecimalOnlySchema = (translate: Function) =>
    Yup.number().nonNullable().typeError(`${translate("form_validation_numbers_only")}`);

export const numberOnlySchemaRequired = (translate: Function) =>
    Yup.number().nonNullable().required(`${translate("form_validation_global")}`);



export const numberOnlyStringSchema = (translate: Function) =>
    Yup.string()
        .matches(/^[0-9]*$/, `${translate("form_validation_numbers_only")}`);

export const numberOnlyStringSchemaRequired = (translate: Function) =>
    numberOnlyStringSchema(translate)
        .required(`${translate("form_validation_global")}`);


export const genericStringSchema = () =>
    Yup.string();

export const genericRequiredStringSchema = (translate: Function) =>
    Yup.string().required(`${translate("form_validation_global")}`);

export const genericRequiredAnySchema = (translate: Function) =>
    Yup.mixed().required(`${translate("form_validation_global")}`);

export const genericAnySchema = () =>
    Yup.mixed();

export const genericRequiredDateSchema = (translate: Function) =>
    Yup.date().required(`${translate("form_validation_global")}`);

export const genericDateSchema = () =>
    Yup.date();

export const emailSchema = (translate: Function) =>
    Yup.string()
        .email(`${translate("form_validation_email_message")}`);

export const emailSchemaRequired = (translate: Function) =>
    emailSchema(translate)
        .required(`${translate("form_validation_email")}`);

export const passwordSchemaRequired = (translate: Function) =>
    Yup.string().required(`${translate(`form_validation_password`)}`);

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const phoneSchema = (translate: Function) =>
    genericRequiredStringSchema(translate).matches(
        phoneRegExp,
        "מספר טלפון לא תקין"
    );