import * as katzbatApi from '../../api';
import { API_URL_BASE, getAxionsInstance } from '../../global/repositories/RepositoryBase';

import { DriverUser } from '../../api';
import { DriverDocumentCreateReq, DriverDocumentHeader, CompleteDriverOnboardingReq, DriverLicenseRes, UploadDriverLicenseReq } from '../../api/api';

export interface IRepositoryDriver {
  addDriver(_clientId: string, _shouldNotifyDriver: boolean, _driver: DriverUser): Promise<DriverUser | undefined | 204>;
  getDrivers(_clientId: string): Promise<DriverUser[] | undefined>;
  getDriverData(_driverId: string): Promise<DriverUser | undefined>;
  getDriverOnboarding(_driverId?: string): Promise<DriverUser | undefined>;
  getDriverLicenseRes(_driverId: string): Promise<DriverLicenseRes | undefined>;
  updateDriver(_driverId: string, _driver: DriverUser): Promise<DriverUser | undefined>;
  deleteDriver(_driverId: string): Promise<string | undefined>;
  completeOnboarding(_completeDriverOnboardingReq: CompleteDriverOnboardingReq): Promise<boolean | undefined>;
  uploadDriverLicense(_uploadDriverLicenseReq: UploadDriverLicenseReq): Promise<boolean | undefined>;
  postNewDriverDocumentCreateReq(_req: DriverDocumentCreateReq): Promise<DriverDocumentHeader | undefined>;
  getDriverDocumentsHeaders(_driverId: string): Promise<DriverDocumentHeader[] | undefined>;
  downloadDriverDocument(_driverId: string, _documentId: string): Promise<any>;
  sendDriverOnboardingNotification(_driverId: string): Promise<boolean | undefined>;
  downloadDriverLicense(_driverId: string): Promise<any>;
}

export default function RepositoryDriver(): IRepositoryDriver {

  const apiDriver = (clientId?: string) => katzbatApi.DriverApiFactory(undefined, API_URL_BASE, getAxionsInstance(clientId));

  // === POST: Add driver ===
  const addDriver = async (clientId: string, shouldNotifyDriver: boolean, driver: DriverUser): Promise<DriverUser | undefined | 204> => {
    const resutls = await apiDriver(clientId).driverPost(clientId, shouldNotifyDriver, driver);
    if (resutls.status === 204) return 204;
    return resutls.data;
  }

  // === GET: Get drivers ===
  const getDrivers = async (clientId: string): Promise<DriverUser[] | undefined> => {
    const resutls = await apiDriver(clientId).driverAllForMainUserAndClientGet(clientId);
    return resutls.data;
  }

  const getDriverOnboarding = async (driverId?: string): Promise<DriverUser | undefined> => {
    const resutls = await apiDriver().driverGetDriverDataForOnboardingGet(driverId);
    return resutls.data;
  }

  const getDriverData = async (driverId: string): Promise<DriverUser | undefined> => {
    const resutls = await apiDriver().driverGetDriverDataGet(driverId);
    return resutls.data;
  }

  const getDriverLicenseRes = async (driverId: string): Promise<DriverLicenseRes | undefined> => {
    const resutls = await apiDriver().driverGetDriverLicenseGet(driverId);
    return resutls.data;
  }

  // === PATCH: Update driver ===
  const updateDriver = async (driverId: string, driver: DriverUser): Promise<DriverUser | undefined> => {
    const resutls = await apiDriver().driverPatch(driverId, driver);
    return resutls.data;
  }

  // DELETE: Delete driver
  const deleteDriver = async (driverId: string): Promise<string | undefined> => {
    const resutls =  await apiDriver().driverDelete(driverId);
    return resutls.data;
  }

  // POST: Complete onboarding
  const completeOnboarding = async (completeDriverOnboardingReq: CompleteDriverOnboardingReq): Promise<boolean | undefined> => {
    const resutls = await apiDriver().driverCompleteOnboardingPost(completeDriverOnboardingReq);
    return resutls.data;
  }

  // POST: Upload driver license
  const uploadDriverLicense = async (uploadDriverLicenseReq: UploadDriverLicenseReq): Promise<boolean | undefined> => {
    const resutls = await apiDriver().driverUploadDriverLicensePost(uploadDriverLicenseReq);
    return resutls.data;
  }

  // POST: Post new driver document create req
  const postNewDriverDocumentCreateReq = async (req: DriverDocumentCreateReq): Promise<DriverDocumentHeader | undefined> => {
    const resutls = await apiDriver().driverGenerateNewDriverDocumentPost(req);
    return resutls.data;
  }

  // GET: Get driver document
  const getDriverDocumentsHeaders = async (driverId: string): Promise<DriverDocumentHeader[] | undefined> => {
    const resutls = await apiDriver().driverGetDriverDocumentsHeadersGet(driverId);
    return resutls.data;
  }

  // GET: Download driver document
  const downloadDriverDocument = async (driverId: string, documentId: string): Promise<any> => {
    const resutls = await apiDriver().driverGetDriverDocumentPdfGet(
      driverId, 
      documentId,
      {
        responseType: 'arraybuffer'
      });
    return resutls.data;
  }

  const sendDriverOnboardingNotification = async (driverId: string): Promise<boolean | undefined> => {
    const resutls = await apiDriver().driverSendDriverOnboardingNotificationGet(driverId);
    return resutls.data;
  }

  const downloadDriverLicense = async (driverId: string): Promise<any> => {
    const resutls = await apiDriver().driverDownloadDriverLicenseCopyPdfGet(
      driverId,
      {
        responseType: 'arraybuffer'
      });
    return resutls.data;
  }

  return {
    addDriver,
    getDrivers,
    getDriverOnboarding,
    getDriverLicenseRes,
    updateDriver,
    deleteDriver,
    completeOnboarding,
    getDriverData,
    uploadDriverLicense,
    postNewDriverDocumentCreateReq,
    getDriverDocumentsHeaders,
    downloadDriverDocument,
    sendDriverOnboardingNotification,
    downloadDriverLicense
  };
}
