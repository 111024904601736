// import { useRef } from 'react';

import {
  Link,
  Box,
  Stack,
  Button,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";

import { PATH_WEBSITE } from "../../routes/paths";

import { useResponsive } from "../../../global/hooks/use-responsive";
// import { useBoundingClientRect } from '../../hooks/use-bounding-client-rect';

import { bgGradient } from "../../../global/theme/css";
// import { HEADER } from '../../layout/config-layout';

import Image from "../../../global/components/image";
import Iconify from "../../../global/components/iconify";
import { HEADER } from "../../../config-global";

// ----------------------------------------------------------------------

export default function HomeHero() {
  const theme = useTheme();

  // const containerRef = useRef<HTMLDivElement>(null);

  const mdUp = useResponsive("up", "md");

  // const container = useBoundingClientRect(containerRef);

  // const offsetLeft = container?.left;

  return (
    <Box
      sx={{
        ...bgGradient({
          color: alpha(theme.palette.background.default, 0.9),
          imgUrl: "/assets/background/overlay_1.jpg",
        }),
        mt: `${HEADER.H_DESKTOP}px`,
      }}
    >
      <Container
        sx={{
          display: { md: "flex" },
          alignItems: { md: "center" },
          overflow: "hidden",
        }}
      >
        <Grid container alignContent={"center"}>
          <Grid
            item
            xs={12}
            md={6}
            lg={5}
            sx={{
              textAlign: { xs: "center", md: "left" },
            }}
            alignContent={"center"}
          >
            <Stack
              spacing={5}
              justifyContent="center"
              alignItems={{ xs: "center", md: "flex-start" }}
              sx={{
                py: 15,
                textAlign: { xs: "center", md: "left" },
              }}
            >
              <Typography variant="h1">
                מערכת ניהול <br /> לקציני בטיחות
                <Box component="span" sx={{ color: "primary.main" }}>
                  {` בתעבורה`}
                </Box>
              </Typography>

              <Typography variant="subtitle1" sx={{ color: "text.secondary" }}>
                פתרון קל ונוח ללא התקנה! נבנה במיוחד עבור קציני בטיחות המעונינים
                להתייעל ולצמצם משמעותית את השימוש בניירת.
              </Typography>
              
              <Stack direction="row" spacing={2}>
                <Link href={`#${PATH_WEBSITE.home_anchor_pricing}`}>
                  <Button
                    color="primary"
                    size="large"
                    variant="contained"
                    endIcon={<Iconify icon="bxs:left-arrow" />}
                    target="_blank"
                    rel="noopener"
                    href={""}
                  >
                    התחל עכשיו
                  </Button>
                </Link>

                <Link href='https://wa.me/+972547831008' target="_blank">
                  <Image src="/assets/icons/wp-bt.png" sx={{width: 210}} />
                </Link>
              </Stack>
              
            </Stack>
          </Grid>

          {mdUp && (
            <Grid item xs={12} md={6} lg={7} alignContent={"center"}>
              <Image
                sx={{ mt: 8 }}
                visibleByDefault
                disabledEffect
                src="/assets/images/home/home_hero.png"
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </Box>
  );
}
