import Box, { BoxProps } from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";

import { PATH_WEBSITE } from "../../routes/paths";

import { useOffSetTop } from "../../hooks/use-off-set-top";
import { useResponsive } from "../../../global/hooks/use-responsive";

// import { bgBlur } from '../../../global/theme/css';

import Logo from "../../../global/components/logo";

import NavMobile from "./nav/mobile";
import NavDesktop from "./nav/desktop";
import { HEADER } from "../config-layout";
// import Searchbar from '../common/searchbar';
import { navConfig } from "./config-navigation";

// ----------------------------------------------------------------------

function HeaderShadow({ sx, ...other }: BoxProps) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        m: "auto",
        height: 24,
        zIndex: -1,
        opacity: 0.48,
        borderRadius: "50%",
        position: "absolute",
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}

type Props = {
  headerOnDark: boolean;
  showMenu: boolean;
};

export default function WebsiteHeader({ headerOnDark, showMenu }: Props) {
  const theme = useTheme();

  const offset = useOffSetTop();

  const mdUp = useResponsive("up", "md");

  const renderContent = (
    <>
      <Box sx={{ lineHeight: 0, position: "relative" }}>
        <Logo textColor="white" />
      </Box>

      {showMenu && (
        <>
          <>
            <Stack
              flexGrow={1}
              alignItems="center"
              sx={{
                height: 1,
                display: { xs: "none", md: "flex" },
              }}
            >
              <NavDesktop data={navConfig} />
            </Stack>

            <Box sx={{ flexGrow: { xs: 1, md: "unset" } }} />
          </>

          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              variant="contained"
              color="inherit"
              href={PATH_WEBSITE.link_to_dashboard}
              target="_blank"
              rel="noopener"
              sx={{
                display: { xs: "none", md: "inline-flex" },
              }}
            >
              כניסה למערכת
            </Button>
          </Stack>

          {!mdUp && <NavMobile data={navConfig} />}
        </>
      )}
    </>
  );

  return (
    <AppBar>
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(["height", "background-color"], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(headerOnDark && {
            color: "common.white",
          }),
          // ...(offset && {
          //   ...bgBlur({ color: theme.palette.background.default }),
          //   color: 'text.primary',
          //   height: {
          //     md: HEADER.H_DESKTOP - 16,
          //   },
          // }),
        }}
      >
        <Container
          sx={{
            height: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {renderContent}
        </Container>
      </Toolbar>

      {offset && <HeaderShadow />}
    </AppBar>
  );
}
