import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// @mui
import {
  Box,
  Stack,
  Drawer,
  // Card,
  // Typography,
  // Button,
  Dialog,
  Card,
  Typography,
  Button,
} from "@mui/material";
// import { Email } from "@mui/icons-material";
// hooks
import { Email } from "@mui/icons-material";
import useResponsive from "../../../hooks/useResponsive";
// config
import { APP_KATZBAT_VER, NAV } from "../../../../config-global";
// components
import Logo from "../../../../global/components/logo";
import { NavSectionVertical } from "./nav-section";
//
import { getNavConfig } from "./config-navigation";
import NavAccount from "./NavAccount";
import useLocales from "../../../../global/locales/useLocales";
import ContactUsForm from "../../../features/common/views/ContactUsForm";
import { useDashboardAppDispatch, useDashboardAppSelector } from "../../../hooks/useRedux";
import { DashboardRootState } from "../../../app/dashboardAppStore";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { getWebAppConfig } from "../../../app/dashboardAppStateSlice";

// import Image from "../../../components/image/Image";

// ----------------------------------------------------------------------

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
};

export default function NavVertical({ openNav, onCloseNav }: Props) {
  const dispatch = useDashboardAppDispatch();
  const { pathname } = useLocation();
  const { translate } = useLocales();

  const isDesktop = useResponsive("up", "lg");

  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);

  const user = useDashboardAppSelector(
    (state: DashboardRootState) => state.settingsPageSlice.user
  );

  const appConfig = useDashboardAppSelector(
    (state: DashboardRootState) => state.appStateSlice.appConfig
  );

  useEffect(() => {
    if (appConfig.disabledFeatures === undefined) {
      dispatch(getWebAppConfig({}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appConfig.disabledFeatures]);

  const onSendMessageSent = () => {
    setIsContactDialogOpen(false);
  };

  const handleClose = () => {
    setIsContactDialogOpen(false);
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Stack
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
        }}
      >
        <Logo 
          linkTo={PATH_DASHBOARD.root}
        />

        {user && <NavAccount user={user} />}
      </Stack>

      <NavSectionVertical data={getNavConfig(appConfig.disabledFeatures)} />

      <Box sx={{ flexGrow: 1 }} />

      {/* Download App QR Code */}
      {/* <Card sx={{ p: 3, mx: 3 }}>
        <Stack spacing={1} alignItems="center" justifyContent="center">
          <Link
            href="https://apps.apple.com/de/app/%D7%A7%D7%A6%D7%91-%D7%AA/id6450900555"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/images/app/app_store.png" alt="downloadApp" />
          </Link>

          <Link
            href="https://play.google.com/store/apps/details?id=com.katzbat.mobile"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/assets/images/app/play_store.png" alt="downloadApp" />
          </Link>
        </Stack>
      </Card> */}

      {/* Contact us */}
      <Card sx={{ padding: 2, margin: 3 }}>
        <Stack spacing={1}>
          <Typography variant="subtitle1" align="center">
            {`${translate("contact_found_problem_feature_request")}`}
          </Typography>
          <Button
            onClick={() => setIsContactDialogOpen(true)}
            variant="contained"
            startIcon={<Email />}
          >
            {`${translate("global_contact_us")}`}
          </Button>
        </Stack>
      </Card>


      {/* Current app version */}
      <Typography variant="subtitle2" align="center" sx={{ padding: 2 }}>
        {`v: ${APP_KATZBAT_VER}`}
      </Typography>
    </Stack>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV.W_DASHBOARD },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              zIndex: 0,
              width: NAV.W_DASHBOARD,
              bgcolor: "transparent",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      <Dialog open={isContactDialogOpen} onClose={handleClose}>
        <ContactUsForm
          translate={translate}
          onSendMessageSent={onSendMessageSent}
        />
      </Dialog>
    </Box>
  );
}
