// form
import { useFormContext, Controller } from "react-hook-form";
// @mui
import { DatePicker } from "@mui/x-date-pickers";

// import { parseISO } from "date-fns";
import { SxProps } from "@mui/material";

// ----------------------------------------------------------------------

interface Props {
  name: string;
  disabled?: boolean;
  label?: string;
}

export default function RHFDatePicker({ name, disabled, label }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          disabled={disabled === true}
          label={label}
          value={field.value || null}
          onChange={(newValue: any) => {
            field.onChange(newValue);
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              error: !!error,
              helperText: error?.message,
            },
          }}
        />
      )}
    />
  );
}

export function KDatePicker({
  sx,
  value,
  label,
  onValueChanged,
}: {
  sx: SxProps;
  value: string;
  label?: string;
  onValueChanged: (_newValue: any) => void;
}) {
  return (
    <DatePicker
      sx={sx}
      label={label}
      value={value || null}
      onChange={(newValue: any) => {
        onValueChanged(newValue);
      }}
    />
  );
}
