import { LinearProgress, Stack, Typography, styled } from "@mui/material";
//
import Logo from "../../../global/components/logo";
import useLocales from "../../../global/locales/useLocales";

// ----------------------------------------------------------------------

type LoadingScreenProps = {
  showLogo?: boolean;
};
export default function LoadingView({ showLogo }: LoadingScreenProps) {
  const { translate } = useLocales();

  const StyledContent = styled("div")(({ theme }) => ({
    margin: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
  }));

  return (
    <>
      {/** Container to show the  LinearProgress in the center of the screen */}
      <StyledContent>
        <Stack spacing={3} alignItems="center">
          {showLogo && <Logo />}
          <LinearProgress color="inherit" sx={{ width: 360 }} />
          <Typography variant="subtitle1" noWrap>
            {`${translate("global_loading_data")}`}
          </Typography>
        </Stack>
      </StyledContent>
    </>
  );
}
