import * as Yup from "yup";
import { useMemo } from "react";
// form
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import { LoadingButton } from "@mui/lab";
import { Box, Card, Stack, Typography } from "@mui/material";
// utils
// routes
// components
import FormProvider, { RHFTextField } from "../../../../global/components/hook-form";
import { useDashboardAppDispatch, useDashboardAppSelector } from "../../../hooks/useRedux";
import { sendFeedback } from "../../../app/dashboardAppStateSlice";
import { DashboardRootState } from "../../../app/dashboardAppStore";
import { genericRequiredStringSchema } from "../../../../global/utils/formValidators";

// ----------------------------------------------------------------------

interface FormValuesProps extends Omit<any, "avatarUrl"> {
  yourMessage: string;
}

type Props = {
  translate: Function;
  onSendMessageSent: VoidFunction;
};

export default function ContactUsForm({ translate, onSendMessageSent }: Props) {
  const dispatch = useDashboardAppDispatch();

  const user = useDashboardAppSelector(
    (state: DashboardRootState) => state.settingsPageSlice.user
  );
  
  const NewUserSchema = Yup.object().shape({
    yourMessage: genericRequiredStringSchema(translate),
  });

  const defaultValues = useMemo(
    () => ({
      yourMessage: "",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await dispatch(sendFeedback({email: user?.email || '', message: `${data.yourMessage}`}));
      reset();
      onSendMessageSent();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 3 }}>
        <Typography variant="h4">{`${translate(
          "global_contact_us"
        )}`}</Typography>
        <Typography variant="subtitle2">{`${translate(
          "global_contact_us_description"
        )}`}</Typography>
        <Stack justifyContent="space-between">
          {/* Company Details */}
          <Stack sx={{ marginTop: 3 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                width: "550px",
              }}
            >
              <RHFTextField
                fullWidth
                multiline
                rows={10}
                name="yourMessage"
                label={`${translate("form_how_can_we_help")}`}
              />
            </Box>
          </Stack>
        </Stack>

        <Stack alignItems="flex-end" sx={{ mt: 3 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {`${translate("global_send")}`}
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );
}
