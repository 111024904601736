import { useTranslation } from 'react-i18next';
// utils
import { useEffect, useState } from 'react';
import localStorageAvailable from '../utils/localStorageAvailable';

import i18n from './i18n';

//
import { allLangs, defaultLang, LangObject, getLanguageByCode } from './config-lang';

// ----------------------------------------------------------------------

export default function useLocales() {
  const { t: translate } = useTranslation();
  
  const storageAvailable = localStorageAvailable();

  const langStorage = storageAvailable ? localStorage.getItem('i18nextLng') : '';

  // const currentLang = allLangs.find((lang) => lang.value === langStorage) || defaultLang;
  const [availableLanguages, setAvailableLanguages] = useState<LangObject[]>([]);
  const [currentLang, setCurrentLang] = useState<LangObject>(
    allLangs.find((_lang) => _lang.value === langStorage) || defaultLang
  );

  useEffect(() => {
    setAvailableLanguages(allLangs);

    // If the language is not available, set the default language:
    if (!allLangs.find((_lang) => _lang.value === langStorage)) {
      setCurrentLang(allLangs.at(0) || defaultLang);
      i18n.changeLanguage(defaultLang.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allLangs]);

  const handleChangeLanguage = (newlang: string) => {
    setCurrentLang(getLanguageByCode(newlang));
    i18n.changeLanguage(newlang);
  };

  return {
    onChangeLang: handleChangeLanguage,
    translate: (text: any, options?: any) => translate(text, options),
    currentLang,
    availableLanguages,
  };
}
