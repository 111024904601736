import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
//
import Main from './Main';
import Header from './header';
import NavVertical from './nav/NavVertical';

import { useAuthContext } from "../../../app/firebase/useAuthContext";
import { useDashboardAppDispatch } from "../../hooks/useRedux";
import { getUserData } from '../../features/settings/user/userSettingsPageSlice';
import { getVehicleConfig } from '../../app/dashboardAppStateSlice';

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;

  const { user } = useAuthContext();
  const dispatch = useDashboardAppDispatch();

  const getData = () => {
    if (user?.uid !== undefined && user?.uid !== null) {
      dispatch(getUserData());
      dispatch(getVehicleConfig());
    }
  }

  useEffect(() => {
    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.uid]);

  return (
    <>
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  );
}
