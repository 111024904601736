// @mui
import { Localization, heIL } from '@mui/material/locale';
import { he } from './langs';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export type LangObject = {
  label: string;
  value: string;
  systemValue: Localization;
  icon: string;
}

export const allLangs: LangObject[] = [
  {
    label: he.language_name,
    value: he.language_code,
    systemValue: heIL,
    icon: '/assets/icons/flags/ic_flag_he.svg',
  },
  // {
  //   label: en.language_name,
  //   value: en.language_code,
  //   systemValue: enUS,
  //   icon: '/assets/icons/flags/ic_flag_en.svg',
  // },
];

export const defaultLang = allLangs[0]; // Hebrew
export const getLanguageByCode = (code: string): LangObject => allLangs.find((lang) => lang.value === code) || defaultLang;
