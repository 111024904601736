import {
  RecaptchaVerifier,
  UserCredential,
  signInWithPhoneNumber,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { Card, Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Helmet } from "react-helmet-async";
import { useLocales } from "../../../global/locales";
import Logo from "../../../global/components/logo";
import { useAuthContext } from "../../../app/firebase/useAuthContext";

const generateNewRecaptchaContainerId = () =>
  `recaptcha-container-${Math.random().toString(36).substring(7)}`;

export default function DriverLoginPage() {
  const recaptchaContainerId = generateNewRecaptchaContainerId();
  const { translate } = useLocales();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showVerificationCode, setShowVerificationCode] = useState(false);
  const [code, setCode] = useState("");

  const [confirmationResult, setConfirmationResult] = useState<any>(null);
  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier | null>(null);

  const { authInstance } = useAuthContext();

  useEffect(() => {
    if (authInstance) {
      try {
        const verifier = new RecaptchaVerifier(
          authInstance,
          recaptchaContainerId,
          {
            size: "invisible",
            // sitekey: process.env.REACT_APP_FIREBASE_RECAPTCHA_SITE_KEY
            // callback: (_response: any) => {
            //   setRecaptchaVerifier(verifier);
            // },
          }
        );

        if (!recaptchaVerifier) {
          verifier.render();
          setRecaptchaVerifier(verifier);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      console.error("Auth instance is not initialized");
    }

    return () => {
      setRecaptchaVerifier(null);
      recaptchaVerifier?.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authInstance]);

  const handleSendCode = async () => {
    if (phoneNumber.length > 0) {
      setIsLoading(true);
      // remove 0 from the beginning of the phone number

      let phoneNumberWithCountryCode = phoneNumber;
      // Apply only when not is dev environment:
      if (process.env.NODE_ENV !== "development") {
        const phoneNumberWithoutZero = phoneNumber.substring(1);
        phoneNumberWithCountryCode = `+972${phoneNumberWithoutZero}`;
      }

      if (recaptchaVerifier) {
        try {
          const result = await signInWithPhoneNumber(
            authInstance,
            phoneNumberWithCountryCode,
            recaptchaVerifier
          );

          setShowVerificationCode(true);
          setConfirmationResult(result);
          setIsLoading(false);
        } catch (error) {
          console.error(error);
          if (
            error.code === "auth/invalid-phone-number" ||
            error.code === "auth/invalid-verification-code"
          ) {
            setErrorMessage(`${translate(error.code)}`);
          } else {
            setErrorMessage(`${translate("error_global_subtitle")}`);
          }
        } finally {
          setIsLoading(false);
        }
      } else {
        console.error("RecaptchaVerifier is not initialized");
        setErrorMessage(`${translate("error_global_subtitle")}`);
        setIsLoading(false);
      }
    } else {
      console.error("Phone number is empty");
      setErrorMessage(
        `${translate("driver_page_login_error_enter_phone_number")}`
      );
      setIsLoading(false);
    }
  };

  const handleVerifyCode = async () => {
    setIsLoadingVerify(true);
    await confirmationResult
      .confirm(code)
      .then(async (_result: UserCredential) => {
        setIsLoadingVerify(false);
      })
      .catch((error: any) => {
        console.error(error);
        setErrorMessage(error);
        setIsLoadingVerify(false);
      })
      .finally(() => {
        setIsLoadingVerify(false);
      });
  };

  return (
    <>
      <Helmet>
        <title>
          {`${translate("app_name")} | ${translate("login_title")}`}
        </title>
      </Helmet>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
        sx={{
          minHeight: "80vh",
          padding: 5,
        }}
      >
        {/** Logo */}
        <Logo disabledLink />

        {/** Contnet */}
        <Stack spacing={2}>
          <Stack
            spacing={1}
            sx={{
              textAlign: "center",
            }}
          >
            <Typography variant="h4">
              {`${translate("driver_page_login_title")}`}
            </Typography>
            <Typography variant="body1">
              {`${translate("driver_page_login_by_phone_help_text")}`}
            </Typography>
          </Stack>

          <Card
            sx={{
              padding: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <TextField
              id="phone-number"
              label={`${translate("global_phone_number")}`}
              variant="outlined"
              type="tel"
              value={phoneNumber}
              error={errorMessage.length > 0 && phoneNumber.length === 0}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
                setErrorMessage("");
              }}
            />
            <LoadingButton
              loading={isLoading}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
              onClick={handleSendCode}
            >
              {`${translate("driver_page_login_send_code_button_text")}`}
            </LoadingButton>

            {showVerificationCode && (
              <>
                <TextField
                  label={`${translate("global_verification_code")}`}
                  variant="outlined"
                  value={code}
                  sx={{ mt: 3 }}
                  onChange={(e) => setCode(e.target.value)}
                />
                <LoadingButton
                  loading={isLoadingVerify}
                  variant="contained"
                  color="success"
                  sx={{ mt: 2 }}
                  onClick={handleVerifyCode}
                >
                  {`${translate("global_verify_code")}`}
                </LoadingButton>
              </>
            )}
          </Card>

          {errorMessage.length > 0 && (
            <Typography
              variant="h6"
              color="error"
              sx={{
                alignSelf: "center",
              }}
            >
              {errorMessage}
            </Typography>
          )}
        </Stack>

        {/** Bottom Copyright */}
        <Typography variant="body2">
          {`${translate("driver_page_login_copyright_text")}`}
        </Typography>
      </Stack>

      <div id={recaptchaContainerId} />
    </>
  );
}
