import * as katzbatApi from '../../api';
import { API_URL_BASE, getAxionsInstance } from '../../global/repositories/RepositoryBase';
import { VehicleConfig, MainUserInfo, SendFeedbackReq } from '../../api';

export interface IRepositoryApp {
    getUserData: () => Promise<MainUserInfo | undefined>;
    getVehicleConfig: () => Promise<VehicleConfig | undefined>;
    uploadSignPic: (_base64File: string | ArrayBuffer | null) => Promise<any>;
    updateUserData: (_user: MainUserInfo) => Promise<MainUserInfo | undefined>;
    sendFeedback: (_sendFeedbackReq: SendFeedbackReq) => Promise<boolean>;
}

export default function RepositoryApp(): IRepositoryApp {

    const appApi = (clientId?: string) => katzbatApi.AppApiFactory(undefined, API_URL_BASE, getAxionsInstance(clientId, true));
    const userApi = (clientId?: string) => katzbatApi.UserApiFactory(undefined, API_URL_BASE, getAxionsInstance(clientId, true));

    const getUserData = async (): Promise<MainUserInfo | undefined> => {
        const response = await userApi().meGet()
        return response.data;
    }

    const updateUserData = async (user: MainUserInfo): Promise<MainUserInfo | undefined> => {
        const response = await userApi().mePatch(user)
        return response.data;
    }

    const uploadSignPic = async (base64File: string | ArrayBuffer | null): Promise<any> => {
        const fileWithoutHeader = base64File?.toString().split(",")[1];
        const response = await userApi().meUploadSignaturePost(fileWithoutHeader);
        return response.data;
    }

    
    const getVehicleConfig = async (): Promise<VehicleConfig | undefined> => {
        const response = await appApi().appVehicleConfigGet();
        return response.data;
    }

    const sendFeedback = async (sendFeedbackReq: SendFeedbackReq): Promise<boolean> => {
        const response = await appApi().appSendFeedbackPost(sendFeedbackReq);
        return response.data;
    }

    return {
        getUserData,
        getVehicleConfig,
        uploadSignPic,
        updateUserData,
        sendFeedback
    };
}