import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import Label from "../../../global/components/label";
import Image from "../../../global/components/image";
import Iconify from "../../../global/components/iconify";
import { WebsiteProduct, WebsiteProductType } from "../../../api";

// ----------------------------------------------------------------------

export type PricingCardProps = {
  product: WebsiteProduct;
  buttonLabel?: string;
  buttonColor?:
    | "inherit"
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error";
  variant?: "contained" | "outlined";
  isEnabled?: boolean;
  onSelected?: (_product: WebsiteProduct) => void;
};

const getIconByType = (type: WebsiteProductType) => {
  switch (type) {
    case WebsiteProductType.PAID_BAISC:
      return "/assets/icons/pricing/ic_plan_basic03.svg";
    case WebsiteProductType.PAID_PLUS:
      return "/assets/icons/pricing/ic_plan_starter03.svg";
    case WebsiteProductType.PAID_PREMIUM:
      return "/assets/icons/pricing/ic_plan_premium03.svg";
    default:
      return "";
  }
};

export default function PricingCard({
  product,
  buttonLabel,
  buttonColor,
  variant,
  isEnabled = true,
  onSelected,
}: PricingCardProps) {
  return (
    <Card
      sx={{
        p: 5,
        pt: 8,
        boxShadow: (theme) => ({ md: theme.customShadows.z8 }),
        ...(product.type === WebsiteProductType.PAID_PLUS && {
          boxShadow: (theme) => ({ md: theme.customShadows.z24 }),
        }),
      }}
    >
      {product.type === WebsiteProductType.PAID_PLUS && (
        <Label color="info" sx={{ position: "absolute", top: 24, left: 32 }}>
          הכי נמכר
        </Label>
      )}

      <Stack direction="row" justifyContent="space-between">
        <div>
          <Typography
            variant="h4"
            component="div"
            sx={{ color: "primary.main", mb: 2 }}
          >
            {product.title}
          </Typography>

          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography
              variant="h3"
              component="span"
            >{`${product.currency}${product.price}`}</Typography>
            <Typography
              variant="h5"
              component="span"
              sx={{ color: "text.disabled" }}
            >
              /חודש
            </Typography>
          </Stack>
        </div>

        <Image
          alt="icon"
          src={getIconByType(product.type)}
          sx={{ width: 64, height: 64 }}
        />
      </Stack>

      <Typography variant="body2" sx={{ color: "text.secondary", mt: 3 }}>
        {product.description}
      </Typography>

      <Stack spacing={2} sx={{ my: 5 }}>
        {product.featuresList.map((feature) => (
          <Stack
            key={feature.title}
            direction="row"
            alignItems="center"
            sx={{ typography: "body2" }}
          >
            <Iconify
              icon={!feature.included ? "carbon:close" : "carbon:checkmark"}
              sx={{ mr: 2, color: !feature.included ? "gray" : "success.main" }}
            />
            {feature.title}
          </Stack>
        ))}
      </Stack>

      <Typography variant="body2" sx={{ color: "text.secondary", mb: 1 }}>
        *המחיר המוצג אינו כולל מע״מ
      </Typography>

      {onSelected && (
        <Button
          fullWidth
          onClick={() => onSelected(product)}
          disabled={!isEnabled}
          size="large"
          color={
            buttonColor ||
            (product.type === WebsiteProductType.PAID_PREMIUM && "primary") ||
            "inherit"
          }
          variant={
            variant ||
            (product.type === WebsiteProductType.PAID_BAISC && "outlined") ||
            "contained"
          }
        >
          {buttonLabel}
        </Button>
      )}
    </Card>
  );
}
