import React, { useEffect } from 'react';
import { playStoreUrl, appStoreUrl } from '../../app/WebsiteAppConsts';
import { PATH_WEBSITE } from '../../routes/paths';

const DownloadAppRedirect = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes('iphone')) {
      window.location.href = appStoreUrl;
    } else if (userAgent.includes('android')) {
      window.location.href = playStoreUrl;
    } else {
      window.location.href = PATH_WEBSITE.root;
    }
  }, []);

  return <div>מפנה...</div>;
};

export default DownloadAppRedirect;