import * as katzbatApi from '../../api';
import { API_URL_BASE, getAxionsInstance } from '../../global/repositories/RepositoryBase';

import { VehicleReportResponse, VehicleReportHeader } from '../../api';

export interface IRepositoryReports {
  getVehicleReportsHeaders: (_clientId: string, _licenseNumber: string) => Promise<VehicleReportHeader[] | undefined>;
  getFullVehicleReport: (_clientId: string, _licenseNumber: string, _reportId: string) => Promise<VehicleReportResponse | undefined>;
  updateReportData: (_clientId: string, _licenseNumber: string, _reportId: string, _data: any) => Promise<VehicleReportResponse | undefined>;
}


export default function RepositoryReports(): IRepositoryReports {

  const apiReports = (clientId?: string) => katzbatApi.VehicleReportApiFactory(undefined, API_URL_BASE, getAxionsInstance(clientId));

  const getFullVehicleReport = async (clientId: string, licenseNumber: string, reportId: string): Promise<VehicleReportResponse | undefined> => {
    const resutls = await apiReports(clientId).reportsGetDataGet(clientId, licenseNumber, reportId);
    return resutls.data;
  }

  const getVehicleReportsHeaders = async (clientId: string, licenseNumber: string): Promise<VehicleReportHeader[] | undefined> => {
    const resutls = await apiReports(clientId).reportsAllHeadersGet(clientId, licenseNumber);
    return resutls.data;
  }

  const updateReportData = async (clientId: string, licenseNumber: string, reportId: string, data: any): Promise<VehicleReportResponse | undefined> => {
    const resutls = await apiReports(clientId).reportsUpdateDataPatch(clientId, licenseNumber, reportId, data);
    return resutls.data;
  }


  return {
    getVehicleReportsHeaders,
    getFullVehicleReport,
    updateReportData
  };
}
