import "../global.css";

import { Provider as ReduxProvider } from "react-redux";

import { Navigate } from "react-router-dom";
import { WebsiteApp } from "../app/WebsiteApp";
import {
  AppPrivacyPolicy,
  AppDeleteAccountRequest,
} from "../features/legal/index";
import { PATH_WEBSITE } from "./paths";
import HomeView from "../features/home/view/home-view";
import DownloadAppRedirect from "../features/download/DownloadAppRedirect";

import {
  // Auth
  DashboardLoginView,
  NewPasswordPage,
  ResetPasswordPage,
} from "./elements";

import { websiteAppStore } from "../app/websiteAppStore";

import ThemeProvider from "../../global/theme";
import ThemeLocalization from "../../global/locales";
import RTL from "../../global/theme/options/right-to-left";
import MainLayout from "../layout/main";
import { PATH_AUTH } from "../../dashboard/routes/paths";

// ----------------------------------------------------------------------

export const websiteRoutes = [
  // Auth
  {
    path: PATH_AUTH.root,
    element: (
      <ReduxProvider store={websiteAppStore}>
        <ThemeProvider>
          <ThemeLocalization>
            <RTL themeDirection={"rtl"}>
              <MainLayout />
            </RTL>
          </ThemeLocalization>
        </ThemeProvider>
      </ReduxProvider>
    ),
    children: [
      {
        path: PATH_AUTH.login,
        element: (
          <DashboardLoginView />
        ),
      },
      // {
      //   path: PATH_AUTH.register,
      //   element: (
      //     <RegisterView />
      //   ),
      // },
      {
        path: PATH_AUTH.resetPassword,
        element: <ResetPasswordPage />,
      },
      {
        path: PATH_AUTH.newPassword,
        element: <NewPasswordPage />,
      }
    ],
  },

  // Website
  {
    path: PATH_WEBSITE.root,
    element: (
      <ReduxProvider store={websiteAppStore}>
        <WebsiteApp />
      </ReduxProvider>
    ),
    children: [
      // Home:
      {
        path: "",
        element: <HomeView />,
      },

      // Privacy Policy HTML page:
      {
        path: PATH_WEBSITE.app_privacy_policy,
        element: <AppPrivacyPolicy />,
      },
      // Delete Account:
      {
        path: PATH_WEBSITE.app_delete_my_account,
        element: <AppDeleteAccountRequest />,
      },
      // Support:
      // {
      //   path: PATH_WEBSITE.support,
      //   element: <SupportView />,
      // },
      // Download App:
      {
        path: PATH_WEBSITE.download_app,
        element: <DownloadAppRedirect />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <Navigate
        to={`${PATH_WEBSITE.root}/#${PATH_WEBSITE.home_anchor_top}`}
        replace
      />
    ),
  },
];
