import { PATH_WEBSITE } from '../../routes/paths';
import { NavItemProps } from './nav/types';

// ----------------------------------------------------------------------

export const navConfig: NavItemProps[] = [
  { title: 'ראשי', path: `#${PATH_WEBSITE.home_anchor_top}` },
  { title: 'אודות המערכת', path: `#${PATH_WEBSITE.home_anchor_about}` },
  {
    title: 'הצעת מחיר',
    path: `#${PATH_WEBSITE.home_anchor_pricing}`,
    children: undefined,
    // children: [pageLinks[0], pageLinks[1], pageLinks[2], pageLinks[3], pageLinks[4], pageLinks[5]],
  },
  { title: 'שאלות נפוצות', path: `#${PATH_WEBSITE.home_anchor_faq}` },
  // { title: 'תמיכה', path: `${PATH_WEBSITE.home_menu_support}`, externalLink: false},
  { title: 'צור קשר', path: `#${PATH_WEBSITE.home_anchor_contact}` }
];
