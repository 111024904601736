
import ThemeProvider from '../../global/theme';
import ThemeLocalization from "../../global/locales";

import MainLayout from '../layout/main';

import ProgressBar from '../components/progress-bar';
import { MotionLazy } from '../components/animate/motion-lazy';
import RTL from '../../global/theme/options/right-to-left';
import SnackbarProvider from "../../global/components/snackbar/SnackbarProvider";

export function WebsiteApp() {
  return (
    <ThemeLocalization>
      <ThemeProvider>
          <RTL themeDirection={'rtl'}>
            <SnackbarProvider>
              <MotionLazy>
                <ProgressBar />
                <MainLayout disabledSpacing />
              </MotionLazy>
            </SnackbarProvider>
          </RTL>
        </ThemeProvider>
      </ThemeLocalization>
  );
}
