import { useEffect, useState } from 'react';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link } from '@mui/material';

import FormProvider, { RHFTextField } from '../../../global/components/hook-form';
import { useWebsiteAppDispatch } from '../../hooks/useRedux';
import { sendFeedback } from '../../app/websiteAppStateSlice';
import { useSnackbar } from '../../../global/components/snackbar';
import { SendFeedbackReq } from '../../../api';
import { useLocales } from '../../../global/locales';
import { emailSchemaRequired, genericRequiredStringSchema } from '../../../global/utils/formValidators';
import Image from "../../../global/components/image";

// ----------------------------------------------------------------------

export type HomeContactFormProps = {
  isContactUsClicked: boolean;
}

export default function HomeContactForm(
  { isContactUsClicked }: HomeContactFormProps
) {
  const { translate } = useLocales();
  const dispatch = useWebsiteAppDispatch();
  const { enqueueSnackbar } = useSnackbar();


  const ContactSchema = Yup.object().shape({
    fullName: Yup.string(),
    email: emailSchemaRequired(translate),
    subject: Yup.string(),
    phone: Yup.string(),
    message: genericRequiredStringSchema(translate),
  });
  
  const [defaultValues] = useState({
    fullName: '',
    subject: '',
    email: '',
    phone: '',
    message: '',
  });

  const methods = useForm({
    resolver: yupResolver(ContactSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    setValue('subject', isContactUsClicked ? 'בקשה להדגמת המערכת' : '');
    setValue('message', isContactUsClicked ? 'שלום רב, אשמח לקבל הדגמה אודות המערכת' : '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContactUsClicked]);


  const showSnackbar = (message: string, severity: 'success' | 'error' | 'warning' | 'info' | 'default' | undefined) => {
    enqueueSnackbar(message, { variant: severity, anchorOrigin: { vertical: 'bottom', horizontal: 'center' }});
  }

  const onSubmit = handleSubmit(async (data) => {
    try {

      const sendFeedbackReq: SendFeedbackReq = {
        email: data.email,
        phone: data.phone,
        message: data.message,
        subject: data.subject,
        fullName: data.fullName,
      } as SendFeedbackReq;

      await dispatch(
        sendFeedback({sendFeedbackReq, callback: (isSuccess) => {
          if (isSuccess) {
            showSnackbar('ההודעה נשלחה בהצלחה', 'success');
          } else {
            showSnackbar('שגיאה בשליחת ההודעה', 'error');
          }
          reset();
        }})
      )
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Container
      sx={{
        py: 3,
      }}
    >
      <Grid container spacing={3} justifyContent="center">
        <Grid xs={12} md={8}>
          <Stack spacing={2} sx={{ mb: 5, textAlign: 'center' }}>
            <Typography variant="h3">צור קשר</Typography>

            <Typography sx={{ color: 'text.secondary' }}>
              לכל שאלה או בקשה, אנא מלאו את הטופס ונחזור אליכם בהקדם האפשרי.
            </Typography>
          </Stack>

          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack spacing={2.5}>
              <RHFTextField name="fullName" label="שם מלא" />

              <RHFTextField name="email" label="כתובת דואר אלקטרוני" />

              <RHFTextField name="phone" label="מספר טלפון" />

              <RHFTextField name="subject" label="נושא הפניה" />

              <RHFTextField name="message" multiline rows={4} label="הודעה" sx={{ pb: 2.5 }} />

              <Stack alignItems="center" width={1} spacing={3}>
                <LoadingButton
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={isSubmitting}
                >
                  שלח הודעה
                </LoadingButton>

                <Link href='https://wa.me/+972547831008' target="_blank">
                  <Image src="/assets/icons/wp-bt.png" sx={{width: 210}} />
                </Link>
              </Stack>
            </Stack>
          </FormProvider>
        </Grid>
      </Grid>
    </Container>
  );
}
