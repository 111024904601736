import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import { AnalyticsProvider, AuthProvider } from "./firebase/FirebaseContext";
import MainRouter from "./MainRouter";

// ----------------------------------------------------------------------
export default function MainApp() {
  return (
    <AuthProvider>
      <AnalyticsProvider>
        <HelmetProvider>
          <BrowserRouter>
            <MainRouter />
          </BrowserRouter>
        </HelmetProvider>
      </AnalyticsProvider>
    </AuthProvider>
  );
}
