import { useEffect, useRef, useState } from "react";

import { Button, Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Helmet } from "react-helmet-async";
import ReactSignatureCanvas from "react-signature-canvas";
import LicenseFrame from "../../../dashboard/features/common/views/LicenseFrame";
import GenericMessageView from "../../../dashboard/features/common/views/GenericMessageView";
import { useLocales } from "../../../global/locales";
import Logo from "../../../global/components/logo";
import { useDriversAppDispatch, useDriversAppSelector } from "../../hooks/useRedux";
import {
  completeOnboarding,
  getDriverOnboarding,
} from "./driverOnboardingPageSlice";
import palette from "../../../global/theme/palette";
import DriverOnboardingTerms from "./DriverOnboardingTerms";
import useWindowDimensions from "../../../dashboard/hooks/useWindowDimensions";
import { enqueueSnackbar } from "../../../global/components/snackbar";
import { useAuthContext } from "../../../app/firebase/useAuthContext";
import { DriversRootState } from "../../app/driversAppStore";

function DriverOnboardingPageInfoItem({
  title,
  value,
}: {
  title: string;
  value: string;
}) {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        width: "100%",
        padding: 1,
        borderBottom: "1px solid #ccc",
      }}
    >
      <Typography variant="body1">{title}</Typography>
      <Typography variant="subtitle1">{value}</Typography>
    </Stack>
  );
}

export default function DriverOnboardingPage() {
  const { width: screenWidth } = useWindowDimensions();
  const signaturePadding = 45;
  const signatureCanvasWidth = screenWidth - signaturePadding;
  const signatureCanvasHeight = signatureCanvasWidth / 2 + signaturePadding;

  const { translate } = useLocales();
  const dispatch = useDriversAppDispatch();
  const { logout } = useAuthContext();

  const [frontLicenseBase64, setFrontLicenseBase64] = useState<string | null>();
  const [frontLicenseImgUrl, setFrontLicenseImgUrl] = useState<
    string | undefined
  >();

  const [backLicenseBase64, setBackLicenseBase64] = useState<string | null>();
  const [backLicenseImgUrl, setBackLicenseImgUrl] = useState<
    string | undefined
  >();

  const [userAsSinged, setUserAsSinged] = useState(false);
  const [allowFineConvert, setAllowFineConvert] = useState(false);
  const signatureRef = useRef<any>(null);

  const isLoadingData = useDriversAppSelector(
    (state: DriversRootState) => state.driverOnboardingPageSlice.isLoading
  );

  const onCompleted = useDriversAppSelector(
    (state: DriversRootState) => state.driverOnboardingPageSlice.onCompleted
  );

  const driverUser = useDriversAppSelector(
    (state: DriversRootState) => state.driverOnboardingPageSlice.driverUser
  );

  const handleSaveSignature = () => {
    const signatureData = signatureRef.current.toDataURL();
    const signaturePrefixToRemoveRange = signatureData.indexOf(",") + 1;
    const signatureDataWithoutPrefix = signatureData.substring(
      signaturePrefixToRemoveRange
    );

    if (frontLicenseBase64 && backLicenseBase64 && signatureDataWithoutPrefix) {
      dispatch(
        completeOnboarding({
          drivingLicenseFrontBase64: frontLicenseBase64,
          drivingLicenseBackBase64: backLicenseBase64,
          signatureBase64: signatureDataWithoutPrefix,
        })
      );
    } else {
      enqueueSnackbar(`${translate("error_global_subtitle")}`, {
        variant: "error",
      });
    }
  };

  // This will prompt the user to take a photo using the device camera or choose a file from the device's file system.
  const handleTakeLicensePhoto = (isFront: boolean) => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.capture = "environment";
    fileInput.onchange = (e) => {
      const target = e.target as HTMLInputElement;
      const file: File = (target.files as FileList)[0];

      // Convert the file to base64 string
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String = reader.result as string;
        const prefixToRemoveRange = base64String.indexOf(",") + 1;
        const base64StringWithoutPrefix =
          base64String.substring(prefixToRemoveRange);
        if (isFront) {
          setFrontLicenseBase64(base64StringWithoutPrefix);
        } else {
          setBackLicenseBase64(base64StringWithoutPrefix);
        }
      };

      // Convert the file to a URL
      const url = URL.createObjectURL(file);
      if (isFront) {
        setFrontLicenseImgUrl(url);
      } else {
        setBackLicenseImgUrl(url);
      }
    };
    fileInput.click();
  };

  useEffect(() => {
    if (!driverUser) {
      dispatch(getDriverOnboarding());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [driverUser]);

  useEffect(() => {
    if (onCompleted) {
      enqueueSnackbar(
        `${translate("driver_page_onboarding_completed_title")} ${translate(
          "driver_page_onboarding_completed_subtitle"
        )}`,
        {
          variant: "success",
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCompleted]);

  return (
    <>
      <Helmet>
        <title>
          {`${translate("app_name")} | ${translate("login_title")}`}
        </title>
      </Helmet>
      <Stack
        direction="column"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        sx={{
          minHeight: "100vh",
          padding: 5,
        }}
      >
        {/** Logo */}
        <Logo disabledLink />

        {/** Contnet */}
        {!isLoadingData ? (
          <>
            {driverUser && !onCompleted ? (
              <Stack
                sx={{
                  textAlign: "center",
                }}
              >
                <Typography variant="h4">{`${translate(
                  "driver_page_onboarding_title"
                )}`}</Typography>
                <Typography variant="body1">{`${translate(
                  "driver_page_onboarding_subtitle"
                )}`}</Typography>

                <Stack
                  spacing={1}
                  sx={{
                    mt: 2,
                    textAlign: "center",
                  }}
                >
                  {/** Full Name */}
                  <DriverOnboardingPageInfoItem
                    title={`${translate("global_full_name")}`}
                    value={driverUser.fullName}
                  />

                  {/** Gov ID */}
                  <DriverOnboardingPageInfoItem
                    title={`${translate("global_id")}`}
                    value={driverUser.govId}
                  />

                  {/** License number */}
                  <DriverOnboardingPageInfoItem
                    title={`${translate("global_driver_license_number")}`}
                    value={driverUser.licenseNumber}
                  />

                  {/** Drivers License */}
                  <Stack>
                    <Typography variant="h5" sx={{ pt: 3 }} color="black">
                      {`${translate(
                        "driver_page_onboarding_license_photo_title"
                      )}`}
                    </Typography>

                    {(!frontLicenseImgUrl || !backLicenseImgUrl) && (
                      <Typography variant="body1">{`${translate(
                        "driver_page_onboarding_license_photo_subtitle"
                      )}`}</Typography>
                    )}
                  </Stack>

                  <Stack spacing={2}>
                    <LicenseFrame
                      isFront
                      currentImgUrl={frontLicenseImgUrl}
                      translate={translate}
                      onClicked={() => {
                        handleTakeLicensePhoto(true);
                      }}
                    />
                    <LicenseFrame
                      isFront={false}
                      currentImgUrl={backLicenseImgUrl}
                      translate={translate}
                      onClicked={() => {
                        handleTakeLicensePhoto(false);
                      }}
                    />
                  </Stack>

                  {/** Legal Stuff */}
                  <DriverOnboardingTerms
                    name={driverUser.fullName}
                    govId={driverUser.govId}
                  />

                  <Typography variant="h5" sx={{ pt: 1 }} color="error">
                    אני מצהיר בזאת כי קראתי והבנתי את תקנה 168 וכי הצהרתי הנ"ל אמת
                  </Typography>

                  <Typography variant="h5" sx={{ pt: 1 }} color="primary">
                    {`${translate("driver_page_onboarding_signature_title")}`}
                  </Typography>

                  <ReactSignatureCanvas
                    ref={signatureRef}
                    onEnd={() => {
                      setUserAsSinged(
                        signatureRef.current?.isEmpty() === false
                      );
                    }}
                    canvasProps={{
                      width: signatureCanvasWidth,
                      height: signatureCanvasHeight,
                      className: "signature-canvas",
                      style: {
                        border: "2px solid #00618A",
                        borderRadius: "15px",
                        backgroundColor: palette("light").grey[100],
                      },
                    }}
                  />

                  <Button
                    onClick={() => {
                      setUserAsSinged(false);
                      setAllowFineConvert(false);
                      signatureRef.current.clear();
                    }}
                  >
                    {`${translate("driver_page_onboarding_signature_clear")}`}
                  </Button>

                  <Typography variant="h5">
                    {`${translate(
                      "driver_page_onboarding_fines_convert_agreement"
                    )}`}
                  </Typography>

                  {/** Legal Stuff Confirm buttons */}
                  <Stack spacing={2}>
                    <Button
                      disabled={!userAsSinged}
                      variant="contained"
                      color={!allowFineConvert ? "success" : "error"}
                      onClick={() => {
                        setAllowFineConvert(!allowFineConvert);
                      }}
                    >
                      {`${translate(
                        !allowFineConvert
                          ? "driver_page_onboarding_fines_convert_agreement_confirm"
                          : "driver_page_onboarding_fines_convert_agreement_cancel"
                      )}`}
                    </Button>

                    <LoadingButton
                      loading={isLoadingData}
                      disabled={
                        !userAsSinged ||
                        !allowFineConvert ||
                        (!frontLicenseImgUrl && !backLicenseImgUrl)
                      }
                      variant="contained"
                      color="success"
                      onClick={handleSaveSignature}
                    >
                      {`${translate("driver_page_onboarding_confirm")}`}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Stack>
            ) : onCompleted ? (
              <GenericMessageView
                title={`${translate("driver_page_onboarding_completed_title")}`}
                subtitle={`${translate(
                  "driver_page_onboarding_completed_subtitle"
                )}`}
              />
            ) : (
              <GenericMessageView
                title={`${translate("driver_page_onboarding_no_data_title")}`}
                subtitle={`${translate(
                  "driver_page_onboarding_no_data_subtitle"
                )}`}
              />
            )}
          </>
        ) : (
          <GenericMessageView
            title={`${translate("global_loading_data")}`}
            subtitle={""}
          />
        )}

        {/** Footer */}
        <Stack
          spacing={1}
          sx={{
            textAlign: "center",
          }}
        >
          <Typography variant="body2">
            {`${translate("driver_page_login_copyright_text")}`}
          </Typography>

          {/** Logout button */}
          <Button
            variant="text"
            color="error"
            onClick={() => {
              logout();
            }}
          >
            {`${translate("logout_title")}`}
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
