import axios, { AxiosInstance, ResponseType } from 'axios';
import { forcedLogout } from '../../app/firebase/FirebaseContext';
import { COOKIE_NAME_RELATED_CLIENT_ID, getCookie } from '../../dashboard/utils/cookieService';

export const IS_DEV_MODE = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

const API_VERSION = '2';
const API_URL_DEV = process.env.REACT_APP_BACKEND_URL_LOCAL;
const API_URL_PROD = process.env.REACT_APP_BACKEND_URL_LIVE;

export const API_URL_BASE = IS_DEV_MODE ? API_URL_DEV : API_URL_PROD;

export const log = (message: string) => {
  if (IS_DEV_MODE) {
    console.log(message);
  }
};

const axiosConfig = {
  baseURL: `${API_URL_BASE}/v/${API_VERSION}`,
  headers: { 'Cache-Control': 'no-cache' },
};

const axiosInstance = axios.create(axiosConfig);
export default axiosInstance;

export function getAxionsInstance(clientId?: string, foreceDelRelated: boolean = false): AxiosInstance {
  let relatedUserId = getCookie({ name: COOKIE_NAME_RELATED_CLIENT_ID, id: clientId })
  if (foreceDelRelated) {
    relatedUserId = undefined;
  }

  if (relatedUserId && relatedUserId.length > 0) {
    axiosInstance.defaults.headers['x-related-user-id'] = relatedUserId;
  } else {
    delete axiosInstance.defaults.headers['x-related-user-id'];
  }

  return axiosInstance;
}

// axiosInstance.interceptors.request.use(config => {
//   store.dispatch(setIsLoadingData(true));
//   return config;
// },
//   error => {
//     store.dispatch(setIsLoadingData(false));
//     Promise.reject(error);
//   }
// );

axiosInstance.interceptors.response.use(response => response,
  error => {
    if (error.response?.status === 403) {
      forcedLogout();
    }

    if (error.response?.status === 402) {
      forcedLogout();
    }
    return Promise.reject(error);
  }
);


// GET: Generic
export const genericAxiosGet = async (path: string, clientId: string, extraParams: any, responseType?: ResponseType): Promise<any> => {
  const params = {
    clientId,
    ...extraParams
  };

  const response = await getAxionsInstance(clientId).get(path, { params, responseType });
  return response.data;
}

// POST: Generic
export const genericAxiosPost = async <T>(path: string, data: T, queryParams?: any, responseType?: ResponseType, clientId?: string): Promise<any> => {
  const response = await getAxionsInstance(clientId).post(path, data, {params: queryParams, responseType });
  return response;
}


// PUT: Generic
export const genericAxiosPut = async <T>(path: string, data?: T, queryParams?: any, clientId?: string): Promise<any> => {
  const response = await getAxionsInstance(clientId).put(path, data, queryParams);
  return response.data;
}


// PATCH: Generic
export const genericAxiosPatch = async <T>(path: string, data?: T, queryParams?: any, clientId?: string): Promise<any> => {
  const response = await getAxionsInstance(clientId).patch(path, data, { params: queryParams });
  return response.data;
}

// DELETE: Generic
export const genericAxiosDelete = async (path: string, queryParams?: any, clientId?: string): Promise<any> => {
  const response = await getAxionsInstance(clientId).delete(path, queryParams);
  return response.data;
}

export const downloadFile = (data: any, fileName: string, fileType: string) => {
  const blob = new Blob([data], { type: fileType });
  const url = window.URL.createObjectURL(blob);
  // Download the file:
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    `${fileName}.pdf`,
  );

  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
};


export const extractRelatedClientId = (data: string): string | undefined => {
  const splited = data.split('_');
  return splited.length > 1 ? splited[1] : undefined;
}

export const extractRelatedUserId = (data: string): string => {
  const splited = data.split('_');
  return splited[0] ?? "";
}

