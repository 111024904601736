// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}/${sublink}`;
}

// ----------------------------------------------------------------------
const ROOTS_WEBSITE = '/website';
export const PATH_WEBSITE = {
  root: ROOTS_WEBSITE,
  
  app_privacy_policy: path(ROOTS_WEBSITE, 'app_privacy_policy'),
  app_delete_my_account: path(ROOTS_WEBSITE, 'app_delete_my_account'),
  support: path(ROOTS_WEBSITE, 'support'),
  download_app: path(ROOTS_WEBSITE, 'app_download_qr'),

  none: '',
  link_to_dashboard: '/dashboard',
  home_anchor_top: 'home',
  home_anchor_about: 'about',
  home_anchor_pricing: 'pricing',
  home_anchor_faq: 'faq',
  home_anchor_contact: 'contact',
  home_menu_support: 'support',
};