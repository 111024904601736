import { Outlet } from 'react-router-dom';
import Box, { BoxProps } from '@mui/material/Box';

import WebsiteHeader from './header';
import Footer from './footer';
import { HEADER } from '../config-layout';

// ----------------------------------------------------------------------

type Props = BoxProps & {
  headerOnDark?: boolean;
  disabledSpacing?: boolean;
  showMenu?: boolean;
};

export default function MainLayout({
  headerOnDark = false,
  disabledSpacing = false,
  showMenu = true,
  sx,
  ...other
}: Props) {
  return (
    <Box
      sx={{
        height: 1,
        display: 'flex',
        flexDirection: 'column',
        ...sx,
      }}
      {...other}
    >
      <WebsiteHeader showMenu={showMenu} headerOnDark={headerOnDark} />

      <Box component="main" sx={{ flexGrow: 1 }}>
        {!(disabledSpacing || headerOnDark) && (
          <Box
            sx={{
              height: { xs: HEADER.H_MOBILE, md: HEADER.H_DESKTOP },
            }}
          />
        )}

        <Outlet />
      </Box>

      <Footer />
    </Box>
  );
}
