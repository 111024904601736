import { Link as RouterLink } from "react-router-dom";
// @mui
import { styled, alpha } from "@mui/material/styles";
import { Box, Link, Typography } from "@mui/material";
// components
import { CustomAvatar } from "../../../components/custom-avatar";
import { MainUserInfo } from "../../../../api";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

type NavAccountProps = {
  user: MainUserInfo;
};
export default function NavAccount({ user }: NavAccountProps) {
  return (
    <Link component={RouterLink} to={``} underline="none" color="inherit">
      <StyledRoot>
        <CustomAvatar
          src={user?.companyLogoUrl || ""}
          alt={user?.companyName || ""}
          name={user?.companyName || user?.email || "A"}
        />

        <Box sx={{ ml: 2, minWidth: 0 }}>
          <Typography variant="subtitle2" noWrap>
            {`${user?.companyName || ""}`}
          </Typography>

          <Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>
            {`${user?.companyId || ""}`}
          </Typography>
        </Box>
      </StyledRoot>
    </Link>
  );
}
