
export const en = {
  language_code: 'en',
  language_name: 'English',
  app_name: 'Katzbat',

  global_search: 'Search',
  global_new_client: 'New Client',
  global_save: 'Save',
  global_save_changes: 'Save Changes',
  global_add_new: 'Add New',
  global_status: 'Status',
  global_created: 'Created',
  global_id: 'ID Number',
  global_contact_us: 'Contact Us',
  global_contact_us_description: 'Please fill in the form below and we will get back to you as soon as possible.',
  global_send: 'Send',
  global_yes: 'Yes',
  global_no: 'No',
  global_number: 'N.',
  global_click_to_view: 'Click to view',
  global_open_gallary: 'Open Gallary',
  global_id_number: 'ID Number',
  global_id_number_short: 'ID:',
  global_send_request: 'Send Request',
  global_phone: 'Phone',
  global_country_code: 'Country Code',
  global_email: 'Email',
  global_actions: 'Actions',
  global_delete: 'Delete',
  global_cancel: 'Cancel',
  global_download_picture: 'Download Picture',
  global_download_app_scan_qr_code: 'Scan To Download App',
  global_loading_data: 'Loading Data...',
  global_address: 'Address',
  global_try_again: 'Try Again',
  global_edit: 'Edit Details',
  global_no_data: 'No Data',
  global_update_data: 'Update Data',
  global_all: 'All',
  global_access_denied_title: 'Access Denied',
  global_access_denied_subtitle: 'You do not have access to this page',
  global_access_denied_subtitle_missing_payments: 'Your monthly payment is missing, please contact support',
  global_phone_number: 'Phone Number',
  global_verify_code: 'Recived Verify Code',
  global_verification_code: 'Verification Code',
  global_full_name: 'Full Name',
  global_driver_license_number: 'Driver License Number',
  global_other: 'Other',
  global_remove: 'Remove',
  global_downloading: 'Downloading',
  global_downloading_file_message: 'Please wait while we are downloading the file',
  global_filter: 'Filter',
  global_select_all: 'Select All',
  globa_download: 'Download',
  global_options: 'Options',
  global_clear: 'Clear',
  global_date: 'Date',
  global_rows_per_page: 'Rows per page',
  global_feature_not_available_title: 'Feature Not Available',
  global_feature_not_available: 'This feature is not available',


  // Messages:
  message_are_you_sure_delete: 'Are you sure you want to delete?',

  // Login / Logout:
  login_title: 'Login',
  login_email: 'Email',
  login_password: 'Password',
  login_remember_me: 'Remember Me',
  login_forgot_password: 'Forgot Password?',
  login_forgot_password_message: 'Please enter your email address and we will send you a link to reset your password.',
  login_reset_pssword: 'Reset Password',
  login_button: 'Login',
  login_error: 'Email or Password is incorrect',
  logout_title: 'Logout',
  logout_message: 'Are you sure you want to logout?',
  forgot_password_return_to_sign_in: 'Return to Sign In',
  password_request_sent_title: 'Check your mailbox!',
  password_request_sent_message: 'If you have an account, you will receive an email with a link to reset your password.',
  
  // Register:
  register_title: 'Register',

  // Errros:
  'Firebase: Error (auth/user-not-found).': 'Email not found',
  'Firebase: Error (auth/wrong-password).': 'Wrong password',
  'auth/invalid-phone-number': 'Invalid phone number',
  'auth/invalid-verification-code': 'Invalid verification code',
  error_client_list_empty_title: 'Your clients list is empty',
  error_client_list_empty_subtitle: 'Add a new client to get started',
  error_client_add_title: 'Error adding client, check if the client ID already in use',
  error_vehicles_list_empty_title: 'Your vehicles list is empty',
  error_vehicles_list_empty_subtitle: 'Add a new vehicle to get started',
  error_vehicle_add_title: 'Error adding or edit vehicle',
  error_vehicle_document_add_title: 'Error adding or edit document',
  error_driver_add_title: 'Error adding or edit driver',
  error_driver_phone_number: 'Phone number is wrong',
  error_driver_empty_phone: 'Phone number is required',
  error_vehicle_add_subtitle: 'There was an error adding the vehicle. Please try again later.',
  error_vehicle_reports_list_is_empty_title: 'No reports found',
  error_vehicle_reports_list_is_empty_subtitle: 'This vehicle has no reports yet, use the app to create a new report.',
  error_drivers_list_empty_title: 'Your drivers list is empty',
  error_drivers_list_not_found_title: 'No drivers found',
  error_drivers_list_not_found_description: 'No drivers found for this search input.',
  error_drivers_list_empty_subtitle: 'Add a new driver to get started',
  error_no_drivers_found_title: 'No drivers found, you need to add a new driver to this client first',
  error_global_title: 'Error',
  error_global_subtitle: 'There was an error, please try again.',
  error_global_empty: 'No data found',
  error_driver_not_selected_title: 'No driver selected',
  error_linked_drivers_list_empty_title: 'No permenent drivers linked to this vehicle',
  error_linked_drivers_list_empty_subtitle: 'Click the button below to link a driver to this vehicle',
  error_update_user_data: 'Error updating user data',
  error_client_no_vehicles_for_type: 'No vehicles found for this type',


  contact_found_problem_feature_request: `Found a problem? Have a feature request?`,

  language_title: `English`,
  nav_subheader_general: `General`,
  nav_subheader_management: `Management`,
  nav_app: `Dashboard`,
  nav_clients: `Clients`,
  nav_clients_add: `Add Client`,
  nav_clients_vehicle: `Vehicles`,
  nav_clients_driver: `Drivers`,
  nav_clients_reports: `Reports`,
  nav_subheader_system: `System Settings`,
  nav_settings: `Settings`,
  nav_settings_signature: `Details & Signature`,
  nav_settings_vehicle_report: `Vehicle Report`,


  // Dashboard:
  dashboard_title: `Dashboard`,
  dashboard_total_clients: `Clients`,
  dashboard_total_vehicles: `Vehicles`,
  dashboard_total_drivers: `Drivers`,
  dashboard_total_reports: `Reports`,

  // Clients List:
  clients_title: `Clients`,
  clients_add_client: `Add Client`,
  clients_edit_title: `Edit Client`,
  clients_list_table_title_name: `Client Name`,
  clients_list_table_title_id: `ID`,
  clients_list_table_title_total_vehicles: `Vehicles`,
  clients_list_table_title_total_reports: `Reports`,
  clients_list_table_title_status: `Status`,
  clients_list_table_title_created: `Created`,
  clients_list_table_title_address: `Address`,
  clients_list_table_title_vehicles_number: `Vehicle`,
  clients_list_table_title_drivers_number: `Drivers`,
  client_list_status_clear: `All Clear`,
  client_list_status_missing: `Missing Info`,
  client_list_status_problem: `Problem!`,
  client_singature: `Signature`,
  client_singature_upload: `Upload Signature`,
  client_singature_upload_description: `Upload the client signature to be used in the reports`,

  // Client:
  client_page_title: `Client File`,
  client_page_about: `About`,
  client_page_tab_profile: `Profile`,
  client_page_tab_vehicles: `Vehicles`,
  client_page_tab_drivers: `Drivers`,
  client_page_tab_settings: `Settings`,
  client_page_news_updates: `News & Updates`,
  client_page_news_updates_empty: `No news or updates`,
  client_page_search_vehicles: `Search Vehicles`,
  client_page_search_drivers: `Search Drivers`,
  client_page_delete_client: `Delete Client`,
  client_page_show_archive: `Show Archive`,

  // Client Vehicle:
  client_page_vehicle_id: `Vehicle ID`,
  client_page_vehicle_make: `Make`,
  client_page_vehicle_model: `Model`,
  client_page_vehicle_year: `Year`,
  client_page_vehicle_type: `Type`,
  client_page_vehicle_vin: `VIN`,
  client_page_vehicle_add: `Add Vehicle`,
  client_page_vehicle_edit: `Edit Vehicle`,
  client_page_vehicle_details: `Vehicle Details`,
  client_page_vehicle_form_validation_id: `Vehicle ID is required`,
  client_page_vehicle_form_validation_id_numbers_only: `Vehicle ID must be numbers only`,
  client_page_vehicle_form_validation_make: `Make is required`,
  client_page_vehicle_form_validation_year: `Year is required`,
  client_page_vehicle_filter_by_type: `Filter by type`,

  // Client Driver:
  client_page_driver_add: `Add Driver`,
  client_page_driver_subtitle: `Drivers`,
  client_page_driver_edit: `Edit Driver`,
  client_page_driver_subtitle_edit: `Edit Driver`,
  client_page_driver_link: `Link Driver`,
  client_page_driver_details: `Driver Details`,
  client_page_driver_name: `Name`,
  client_page_driver_type: `Type`,
  client_page_driver_license: `License`,
  client_page_driver_license_expiration: `License Expiration Date`,
  client_page_driver_birthday: `Birthday`,
  client_page_driver_notify: `Notify Driver`,
  client_page_driver_notify_success: `Notification has been sent successfully`,
  client_page_driver_notify_failed: `Error sending notification`,
  client_page_driver_license_issue_date: `License Issue Date`,
  client_page_driver_form_validation_name: `Name is required`,
  client_page_driver_form_validation_license: `License is required`,
  client_page_driver_form_validation_license_numbers_only: `License must be numbers only`,
  client_page_driver_form_validation_license_expiration: `Expiration is required`,
  client_page_driver_form_validation_license_issue_date: `Issue Date is required`,
  client_page_driver_details_work: `Work Details`,
  client_page_driver_work_start_date: `Work Start Date`,
  client_page_driver_work_branch: `Work Branch`,
  client_page_driver_work_vehicle_type: `Work Vehicle Type`,
  client_page_driver_work_job_title: `Job Title`,
  client_page_driver_work_job_devision: `Job Devision`,

  // Client Settings:
  client_page_settings_alert_emails: `Alert Emails`,
  client_page_settings_add: `Add`,
  client_page_settings_search_email: `Search Email`,
  error_client_settings_list_empty_title: `No emails found`,
  error_client_settings_list_empty_subtitle: `Add a new email to get started`,
  client_page_settings_add_address: `Add Email`,
  client_settings_page_title_add_alert: `Add Alert`,

  client_settings_alert_type_vehicle_document_expired: `Vehicle document expired`,
  client_settings_alert_type_vehicle_generic_alert: `Vehicle generic alert`,
  client_settings_alert_type_vehicle_report_alert: `Vehicle report alert`,
  client_settings_alert_type_driver_document_expired: `Driver document expired`,
  client_settings_alert_type_driver_generic_alert: `Driver generic alert`,
  client_settings_alert_type_user_account_alert: `User account alert`,
  client_settings_alert_type_generic_alert: `Generic alert`,


  // Vehicle Page:
  vehicle_page_title: `Vehicle Info`,
  vehicle_page_tab_reports: `Reports`,
  vehicle_page_tab_info: `Info`,
  vehicle_page_tab_documents: `Documents`,
  vehicle_page_tab_invoices: `Invoices`,
  vehicle_page_search_reports: `Search Reports`,
  vehicle_page_move_to_archive: `Move to Archive`,
  vehicle_page_move_to_archive_description: `Are you sure you want to move this vehicle to the archive?`,
  vehicle_page_related_drivers: `Related Drivers`,
  vehicle_page_related_drivers_add: `Add Driver`,
  vehicle_page_related_drivers_add_description: `Add drivers that are related to this client.`,
  vehicle_page_related_drivers_empty: `No drivers found`,
  vehicle_page_related_drivers_empty_description: `First you must add the drivers to the client.`,

  // Vehicle Types:
  vehicle_type_car: `Private Car`,
  vehicle_type_commercial: `Commercial`,
  vehicle_type_truck: `Truck`,
  vehicle_type_truck_small: `Small Truck`,
  vehicle_type_bus: `Bus`,
  vehicle_type_minibus: `Minibus`,
  vehicle_type_taxi: `Taxi`,
  vehicle_type_motorcycle: `Motorcycle`,
  vehicle_type_tractor: `Tractor`,
  vehicle_type_trailer: `Trailer`,
  vehicle_type_fork_lift: `Forklift`,
  vehicle_type_motor_home: `Motor Home`,
  vehicle_type_other: `Other`,

  // Vehicle Documents:
  vehicle_documents_page_title_is_expired: `Expired`,
  vehicle_documents_page_title_document_title: `Title`,
  vehicle_documents_page_title_document_type: `Type`,
  vehicle_documents_page_title_expiration_date: `Expiration Date`,
  vehicle_documents_page_title_download_file: `Download File`,
  vehicle_documents_page_title_update_date: `Update Date`,
  vehicle_documents_page_title_documents_list: `Documents List`,
  vehicle_documents_page_title_search_documents: `Search Documents`,
  vehicle_documents_page_title_no_title: `No Title`,
  vehicle_documents_page_title_actions: `Actions`,
  vehicle_documents_page_title_download: `View/Download File`,
  vehicle_documents_page_title_add_document: `Add Document`,
  vehicle_documents_page_title_edit_document: `Edit Document`,
  vehicle_documents_page_title_document_file: `Document File`,
  vehicle_documents_page_title_upload_file: `Click here to upload file`,
  vehicle_documents_page_title_file_selected: `File Selected`,
  vehicle_documents_page_title_clear_file: `Clear File`,
  vehicle_documents_page_error_adding_document: `Error adding document`,
  vehicle_documents_page_error_updating_expiration_date: `Error updating expiration date`,
  error_vehicle_document_add_no_file: `Please select a file`,
  error_vehicle_documents_page_title_empty: `No documents found`,
  error_vehicle_documents_page_subtitle_empty: `This vehicle has no documents yet, use the app to add a new document.`,
  vehicle_document_type_vehicle_license: 'Vehicle license',
  vehicle_document_type_vehicle_license_appendix: 'Vehicle license appendix',
  vehicle_document_type_compulsory_insurance: 'Compulsory insurance',
  vehicle_document_type_brakes_certificate: 'Brakes certificate',
  vehicle_document_type_winter_examination: 'Winter examination',
  vehicle_document_type_operating_license: 'Operating license',
  vehicle_document_type_bus_transport_certificate: 'Bus transport certificate',
  vehicle_document_type_bus_children_forgetting_certificate: 'Bus children forgetting certificate',
  vehicle_document_type_tachograph_certificate: 'Tachograph certificate',
  vehicle_document_type_truck_lift_ramp_certificate: 'Truck lift ramp certificate',
  vehicle_document_type_truck_transforter_certificate: 'Truck transporter certificate',
  vehicle_document_type_cooling_box_certificate: 'Cooling box certificate',
  vehicle_document_type_other: 'Other',
  vehicle_document_type_undefined: 'Undefined',

  // Vehicle Invoices:
  vehicle_invoices_page_title_invoices_list: `Invoices List`,
  vehicle_invoices_page_title_add_invoice: `Add Invoice`,
  vehicle_invoices_page_title_edit_invoice: `Edit Invoice`,
  vehicle_invoices_page_title_search_invoices: `Search Invoices`,
  error_vehicle_invoices_page_title_empty: `No invoices found`,
  error_vehicle_invoices_page_subtitle_empty: `This vehicle has no invoices yet, use the app to add a new invoice.`,
  vehicle_invoices_page_title_invoice_issuedBy: `Issued By`,
  vehicle_invoices_page_title_upload_file: `Upload File`,
  vehicle_invoices_page_title_invoice_number: `Invoice Number`,
  vehicle_invoices_page_title_invoice_amount: `Amount`,
  vehicle_invoices_page_title_invoice_details: `Details`,
  vehicle_invoices_page_title_invoice_issueDate: `Issue Date`,
  vehicle_invoices_page_title_due_date: `Due Date`,
  vehicle_invoices_page_title_file_selected: `File Selected`,
  vehicle_invoices_page_title_download: `Download Invoce Copy`,
  vehicle_invoices_page_title_no_file: `No file found`,
  error_vehicle_invoice_add_title: `Error adding or edit invoice`,
  vehicle_invoices_page_title_no_title: `No Title`,
  vehicle_invoices_page_error: `Error adding invoice`,

  // Vehicle Reports:
  report_page_title: `Vehicle Report`,
  report_page_title_more_details: `More Details`,
  report_page_title_details: `Car Details`,
  report_page_title_download_report: `Download Report`,
  report_page_title_send_report: `Send Report`,
  report_page_title_report_number: `Report Number`,
  report_page_title_car_details: `Car Details`,
  report_page_title_car_id: `Car ID`,
  report_page_title_car_make: `Make`,
  report_page_title_car_model: `Model`,
  report_page_title_car_type: `Vehicle Type`,
  report_page_title_car_manufacturer_date: `Manufacturer Date`,
  report_page_title_odometer: `Odometer`,
  report_page_title_client_name: `Client Name`,
  report_page_title_date: `Date`,
  report_page_title_driver_details: `Driver Details`,
  report_page_title_driver_permenant: `Permenant`,
  report_page_title_driver_id: `Driver ID`,
  report_page_title_driver_name_full: `Full Name`,
  report_page_title_driver_license_expiration: `Driver License Expiration`,
  report_page_title_results: `Tests Results`,
  report_page_title_search_tests: `Search Tests`,
  report_page_title_test_name: `Test Name`,
  report_page_title_test_result: `Result`,
  report_page_title_test_notes: `Notes`,
  report_page_title_test_attachments: `Pictures`,
  report_page_title_test_pass: `Pass`,
  report_page_title_test_fail: `Fail`,
  report_page_title_test_no_tested: `No Tested`,
  report_page_title_notes_exists: `Notes Exists`,
  report_page_title_notes_not_exists: `No Notes`,
  report_page_title_attachments_exists: `Attachments Exists`,
  report_page_title_test_location: `Test Location`,
  report_page_title_test_type: `Test Type`,
  report_page_title_vin: `VIN`,
  report_page_title_download_report_description: `Generate a report for this vehicle and download it.`,
  report_page_title_email_report_description: `Generate a report for this vehicle and send it to the client.`,
  report_page_title_generate_report_include_photos: `Include Photos`,
  report_page_title_generate_report_include_notes: `Include Notes`,
  report_page_title_generate_report_include_driver: `Include Driver Details`,
  report_page_title_generate_report_include_options: `Include Options`,
  report_page_title_report_status: `Report Status`,
  report_page_title_report_status_open: `Open`,
  report_page_title_report_status_closed: `Closed`,
  report_page_title_close_report: `Close Report`,
  report_page_title_close_report_description: `Are you sure you want to close this report?`,

  // Notifications:
  notifications_title: `Notifications`,
  notifications_not_read_placeholder: `You have % unread notifications`,

  // Form Validation:
  form_validation_global: `This field is required`,
  form_validation_name: `Name is required`,
  form_validation_email: `Email is required`,
  form_validation_password: `Password is required`,
  form_validation_email_message: `Email must be a valid email address`,
  form_validation_phone_number: `Phone number is required`,
  form_validation_address: `Address is required`,
  form_validation_city: `City is required`,
  form_validation_zip_code: `Zip code is required`,
  form_validation_company_name: `Company name is required`,
  form_validation_company_id: `Company ID is required`,
  form_validation_numbers_only: `This field must be numbers only`,
  form_validation_text_only: `This field must be text only`,
  form_input_has_permenent_driver: `Has Permenent Driver`,
  form_input_has_permenent_driver_select: `Select Permenent Driver`,

  // From Inputs Company:
  form_input_name: `Name`,
  form_input_email: `Email`,
  form_input_phone_number: `Phone Number`,
  form_input_street: `Address`,
  form_input_house_number: `House Number`,
  form_input_city: `City`,
  form_input_zip_code: `Zip Code`,
  form_input_company_name: `Company Name`,
  form_input_company_id: `Company ID`,
  form_input_save_changes: `Save Changes`,
  form_input_create_client: `Create Client`,
  form_input_has_contact_info: `Contact info differs from company info`,
  form_input_title_company_info: `Company Info`,
  form_input_title_contact_info: `Contact Info`,
  form_how_can_we_help: `How can we help?`,

  form_message_success_message: `Client has been created successfully`,
  form_message_error_message: `Something went wrong, please try again`,
  form_message_update_success_message: `Client has been updated successfully`,
  form_message_already_exists: `Already exists`,
  
  // Settings:
  settings_title: `Settings`,
  settings_user_details: `User Details`,
  settings_singature: `Signature`,
  settings_singature_upload_description: `Upload your signature to be used in the reports\n\nMake sure to use a white background and a dark pen, here is an example:`,
  settings_singature_upload: `Upload Signature`,

  settings_vehicle_report_selection: `Vehicle Report Selection`,
  settings_vehicle_type_selection: `Select vehicle type`,
  settings_vehicle_type_report_selection: `Select report type`,
  settings_vehicle_report_doc_input_title: `Document Additional Info`,
  settings_vehicle_report_doc_input_table_field: `Field`,
  settings_vehicle_report_doc_input_table_included: `Included`,
  settings_vehicle_report_doc_input_table_mendatory: `Mendatory`,
  settings_vehicle_report_question_title: `Questions to include in the report`,
  settings_days_before_alert_label: `Days before alert`,
  settings_days_before_alert_title: `Set days before alert`,

  // Alerts:
  alert_type_vehicle_document_expired: `Vehicle document expired`,
  alert_type_vehicle_generic_alert: `Vehicle generic alert`,
  alert_type_vehicle_report_alert: `Vehicle report alert`,
  alert_type_driver_document_expired: `Driver document expired`,
  alert_type_driver_generic_alert: `Driver generic alert`,
  alert_type_user_account_alert: `User account alert`,
  alert_type_generic_alert: `Generic alert`,

  alert_vehicle_document_expired_title: `Vehicle document`,
  alert_vehicle_document_expired_with_name_title: `with title`,
  alert_vehicle_document_expired_for_vehicle: `for vehicle`,
  alert_vehicle_document_expired_expired_at: `expired at`,

  // Country codes:
  country_lable_IL: `Israel`,
  country_lable_DE: `Germany`,

  // Driver types:
  driver_type_truck: `Truck`,
  driver_type_bus: `Bus`,
  driver_type_taxi: `Taxi`,
  driver_type_commercial: `Commercial`,
  driver_type_private: `Private`,
  driver_type_other: `Other`,

  // Driver pages:
  driver_page_login_title: `Driver Login`,
  driver_page_login_by_phone_help_text: `Please enter your phone number and we will send you a code to login.`,
  driver_page_login_copyright_text: 'Katzbat all rights reserved',
  driver_page_login_send_code_button_text: `Send Code`,
  driver_page_login_error_enter_phone_number: `Please enter your phone number`,
  driver_page_onboarding_title: `Driver Onboarding`,
  driver_page_onboarding_subtitle: `Please fill in the form below to complete your onboarding process`,
  driver_page_onboarding_confirm: `Click here to sign`,
  driver_page_onboarding_signature_title : `Please sign in the box below`,
  driver_page_onboarding_signature_clear: `Clear`,
  driver_page_onboarding_license_photo_title: `Your drivers license`,
  driver_page_onboarding_license_photo_subtitle: `Please upload a photo of your drivers license`,
  driver_page_onboarding_license_photo_front: `Front`,
  driver_page_onboarding_license_photo_back: `Back`,
  driver_page_onboarding_fines_convert_agreement: `I agree to convert my fines to points`,
  driver_page_onboarding_fines_convert_agreement_confirm: `I agree`,
  driver_page_onboarding_fines_convert_agreement_cancel: `I don't agree`,
  driver_page_onboarding_no_data_title: `No data found`,
  driver_page_onboarding_no_data_subtitle: `Please contact your manager to complete your onboarding process`,
  driver_page_onboarding_completed_title: `Onboarding completed`,
  driver_page_onboarding_completed_subtitle: `You can now close this page`,

  // Driver Details:
  driver_page_details_title: `Driver Details`,
  driver_page_tab_info: `Info`,
  driver_page_tab_documents: `Documents`,
  driver_page_breadcrumb_back_to_client: `Back to client`,
  driver_page_details_onboarding_completed: `Onboarding completed`,
  driver_page_details_onboarding_not_completed: `Onboarding not completed`,
  driver_page_details_driving_license: `Driving License`,
  driver_page_details_driving_license_copy_front: `Driving License Front`,
  driver_page_details_driving_license_copy_back: `Driving License Back`,
  driver_page_details_driving_license_download: `Download License`,
  driver_page_details_download_click: `Click to download`,
  driver_page_driver_license_levels: `License Levels`,
  driver_page_driver_address: `Address Details`,

  driver_page_driver_license_details: `Driver License Details`,
  driver_page_driver_license_levels_one: `1`,
  driver_page_driver_license_levels_a1: `A1`,
  driver_page_driver_license_levels_a2: `A2`,
  driver_page_driver_license_levels_a: `A`,
  driver_page_driver_license_levels_b: `B`,
  driver_page_driver_license_levels_c1: `C1`,
  driver_page_driver_license_levels_c: `C`,
  driver_page_driver_license_levels_ce: `CE`,
  driver_page_driver_license_levels_d: `D`,
  driver_page_driver_license_levels_d1: `D1`,
  driver_page_driver_license_levels_d2: `D2`,
  driver_page_driver_license_levels_d3: `D3`,
  driver_page_driver_license_levels_e: `E`,

  driver_page_driver_license_certificates: `Certificates`,
  driver_page_driver_license_certificate_crain: `Crain`,
  driver_page_driver_license_certificate_forklift: `Forklift`,
  driver_page_driver_license_certificate_materials: `Dengerous Materials`,

  // Driver Documents:
  driver_documents_generate_title: `Generate Driver Report`,
  driver_documents_generate_ֿֿsend_to_title: `Send To`,
  driver_documents_generate_date: `Date`,
  driver_documents_generate_ֿֿsend_to_subtitle: `Please fill in the form below to send the report to the driver`,
  driver_documents_subtitle_completed: `Completed`,
  driver_documents_subtitle_incomplete: `Incomplete`,
  driver_documents_generate_title_type: `Report Type`,
  driver_documents_generate_title_vehicle: `Vehicle`,
  driver_documents_generate_use_driver_signature: `Use Driver Signature`,
  driver_documents_generate_new_document: `New Document`,
  driver_documents_upload_new_document: 'Upload New Document',
  driver_documents_upload_title: `Upload Driver Document`,
  driver_documents_upload_subtitle: `Please fill in the form below to upload a new document`,
  driver_documents_upload_type: `Document Type`,
  driver_document_upload_title_driver_law_info: `Driver Info`,
  driver_document_upload_title_other: `Other`,
  driver_documents_expire_date_title: `Expire Date`,
  driver_documents_upload_name_title: `Document Name`,
  driver_documents_page_title_upload_file: `Upload File`,
  driver_documents_page_title_file_selected: `File Selected`,
  error_driver_document_add_no_file: `Please select a file`,
  driver_documents_hide_file_title: `Hide File`,
  driver_documents_page_error_adding_document: `Error adding document`,

  driver_documents_list_title: `Driver Documents`,
  driver_documents_list_empty_title: `No documents found`,
  driver_document_title_accident_report: `Accident Report`,
  driver_document_title_fine_change_parking: `Fine Change Parking`,
  driver_document_title_fine_change_court: `Fine Change Court`,
  driver_document_title_fine_change_traffic: `Fine Change Traffic`,
  driver_document_title_fine_change_generic: `Fine Change Generic`,
  driver_document_title_test_drive: `Test Drive`,
  driver_document_title_driver_declaration: `Driver Declaration`,
  driver_document_title_law_168: `Law 168`,
  driver_document_title_other: `Other`,

  driver_documents_generate_fine_number: `Fine Number`,
  driver_documents_generate_fine_recived_date: `Fine Recived Date`,
  driver_documents_generate_violation_date: `Violation Date`,
  driver_documents_generate_judge_name: `Judge Name`,
  driver_documents_generate_court_location: `Court Location`,
  driver_documents_generate_upload_success_message: `Document has been uploaded successfully`,
  driver_documents_generate_upload_error_message: `Error uploading document`,
  driver_documents_generate_send_to_address: `Send To Address`,

  driver_documents_search_documents: `Search Documents`,
  driver_documents_type: `Type`,
  driver_document_create_date: `Create Date`,
  driver_document_fineViolationDate: `Violation Date`,
  driver_document_fineReceivedDate: `Recived Date`,
  driver_document_license_number: `License Number`,
  driver_documents_sent_to: `Sent To`,

  driver_documents_generate_send_to_name: `Name`,
  driver_documents_generate_send_to_address_street: `Street`,
  driver_documents_generate_send_to_address_city: `City`,
  driver_documents_generate_send_to_address_zipCode: `Zip Code`,
  driver_documents_generate_send_to_address_houseNumber: `House Number`,
  driver_documents_generate_send_to_address_additionalAddress: `Additional Address`,


  // Invoces:
  invoice_title: `Invoices`,
  invoice_title_start_date: `Start Date`,
  invoice_title_end_date: `End Date`,
  invoice_title_search: `Search Invoices`,
  invoice_title_found: `Found`,

  invoice_analytics_title_total: `Total`,
  invoice_analytics_title_paid: `Paid`,
  invoice_analytics_title_unpaid: `Unpaid`,

  invoice_status_title: `Status`,
  invoice_status_title_all: `All`,
  invoice_status_title_paid: `Paid`,
  invoice_status_title_not_paid: `Unpaid`,
  invoice_status_title_refunded: `Refunded`,
  invoice_status_title_canceled: `Canceled`,

  invoice_type_title: `Type`,
  invoice_type_title_service: `Service`,
  invoice_type_title_parking: `Parking`,
  invoice_type_title_recharge_electric: `Recharge Electric`,
  invoice_type_title_fuel_card: `Fuel Card`,
  invoice_type_title_test: `Test`,
  invoice_type_title_insurence: `Insurence`,
  invoice_type_title_rental: `Rental`,
  invoice_type_title_leasing: `Leasing`,
  invoice_type_title_loan: `Loan`,
  invoice_type_title_road_6: `Road 6`,
  invoice_type_title_road_6_north: `Road 6 North`,
  invoice_type_title_road_carmel: `Road Carmel`,
  invoice_type_title_others: `Others`,

  invoice_table_head_title_number: `Invoice Number`,
  invoice_table_head_title_issued_date: `Issued Date`,
  invoice_table_head_title_due_date: `Due Date`,
  invoice_table_head_title_amount: `Amount`,
  invoice_table_head_title_from_name: `From`,
  invoice_table_head_title_status: `Status`,

  invoice_details_title: `Invoice Details`,
  invoice_details_title_number: `Invoice Number`,
  invoice_details_title_description: `Description`,
  invoice_details_title_notes: `Notes`,
  invoice_details_title_subtotal: `Subtotal`,
  invoice_details_title_tax: `Tax`,
  invoice_details_title_total: `Total`,
  invoice_details_title_shipping: `Shipping`,
  invoice_details_title_discount: `Discount`,
  invoice_details_title_issue_date: `Issue Date`,
  invoice_details_title_due_date: `Due Date`,

  invoice_add_title: `Add Invoice`,
  inovice_add_update: `Update Invoice`,
  invoice_add_item: `Add Item`,
  invoice_add_item_title: `Title`,
  invoice_add_item_description: `Description`,
  invoice_add_item_price: `Price`,

  invoice_page_create_title_from: `From`,
  invoice_page_create_title_issuedBy: `Issued By`,
  invoice_page_create_title_address: `Address`,
  invoice_page_create_title_phone: `Phone`,
  invoice_page_create_title_invoice_number: `Invoice Number`,
};

export default en;
