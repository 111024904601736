import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RepositoryApp from '../../../repositories/RepositoryApp';
import { MainUserInfo } from '../../../../api';


export type SettingsState = {
  user?: MainUserInfo;
  isLoading: boolean;
};

const initialState: SettingsState = {
  isLoading: false,
};

// AsyncThunk to get user data from server:
export const getUserData = createAsyncThunk('appState/getUserData', async () => {
  const userData = await RepositoryApp().getUserData();
  return userData;
});

export const uploadUserSignPic = createAsyncThunk('appState/uploadUserSignPic', async (file: string | ArrayBuffer | null) => {
  const signPic = await RepositoryApp().uploadSignPic(file);
  return signPic;
});

export const updateUserData = createAsyncThunk('appState/updateUserData', async (user: MainUserInfo) => {
  const userData = await RepositoryApp().updateUserData(user);
  return userData;
});


export const userSettingsPageSlice = createSlice({
  name: 'settingsPageSlice',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(getUserData.pending, (state, _action) => {
      state.isLoading = true;
      state.user = undefined;
    });

    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
    });

    builder.addCase(getUserData.rejected, (state, _action) => {
      state.user = undefined;
      state.isLoading = false;
    });


    builder.addCase(uploadUserSignPic.pending, (state, _action) => {
      state.isLoading = true;
    });

    builder.addCase(uploadUserSignPic.fulfilled, (state, action) => {
      if (state.user)  {
        state.user.signaturePicUrl = action.payload;
      }
      state.isLoading = false;
    });

    builder.addCase(uploadUserSignPic.rejected, (state, _action) => {
      state.isLoading = false;
    });

    builder.addCase(updateUserData.pending, (state, _action) => {
      state.isLoading = true;
    });

    builder.addCase(updateUserData.fulfilled, (state, action) => {
      state.user = action.payload;
      state.isLoading = false;
    });

    builder.addCase(updateUserData.rejected, (state, _action) => {
      state.isLoading = false;
    });

  }


});

export default userSettingsPageSlice.reducer;
