import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { he } from '../../global/locales/langs';
import RepositoryApp from '../repositories/RepositoryApp';
import { SendFeedbackReq, VehicleConfig, WebAppFeature } from '../../api/api';
import RepositoryConfig from '../repositories/RepositoryConfig';

export type AppConfig = {
  vehicleConfig?: VehicleConfig;
  availableLanguageCode?: string[];
  disabledFeatures?: WebAppFeature[];
  daysBeforeAlerts?: number;
  isLoading?: boolean;
  locale?: string;
};

export type AppState = {
  appConfig: AppConfig;
};

const initialState: AppState = {
  appConfig: {
    availableLanguageCode: [he.language_code],
    disabledFeatures: undefined,
    daysBeforeAlerts: 0,
    locale: he.language_code,
    isLoading: false
  }
};

export const getWebAppConfig = createAsyncThunk('appState/getWebAppConfig', async ({
  clientId
}:{
  clientId?: string
}) => {
  const appConfig = await RepositoryConfig().getWebAppConfig(clientId);
  return appConfig;
});

export const getVehicleConfig = createAsyncThunk('appState/getVehicleConfig', async () => {
  const vehicleConfig = await RepositoryApp().getVehicleConfig();
  return vehicleConfig;
});

export const sendFeedback = createAsyncThunk('appState/sendFeedback', async (sendFeedbackReq: SendFeedbackReq) => {
  const response = await RepositoryApp().sendFeedback(sendFeedbackReq);
  return response;
});

export const setDaysBeforeAlerts = createAsyncThunk('appState/setDaysBeforeAlerts', async (days: number) => {
  const response = await RepositoryConfig().setDaysBeforeAlert(days);
  return response;
});


export const dashboardAppStateSlice = createSlice({
  name: 'appState',
  initialState,
  reducers: {
    onLanguageChange(state, action: PayloadAction<string>) {
      state.appConfig.locale = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getVehicleConfig.fulfilled, (state, action) => {
      state.appConfig.vehicleConfig = action.payload;
    });

    builder.addCase(getVehicleConfig.rejected, (state, _action) => {
      state.appConfig.vehicleConfig = undefined;
    });

    builder.addCase(getWebAppConfig.fulfilled, (state, action) => {
      if (action.meta.arg?.clientId === undefined) {
        state.appConfig.disabledFeatures = action.payload?.disabledFeatures;
      }
      state.appConfig.daysBeforeAlerts = action.payload?.daysBeforeAlerts || 0;
    });

    builder.addCase(getWebAppConfig.rejected, (state, _action) => {
      state.appConfig.disabledFeatures = undefined;
    });

    builder.addCase(setDaysBeforeAlerts.pending, (state, action) => {
      state.appConfig.isLoading = true;
      state.appConfig.daysBeforeAlerts = action.meta.arg;
    });

    builder.addCase(setDaysBeforeAlerts.fulfilled, (state, action) => {
      state.appConfig.isLoading = false;
      state.appConfig.daysBeforeAlerts = action.meta.arg;
    });

    builder.addCase(setDaysBeforeAlerts.rejected, (state, _action) => {
      state.appConfig.isLoading = false;
      state.appConfig.daysBeforeAlerts = 0;
    });
  }


});

export const { onLanguageChange } = dashboardAppStateSlice.actions;
export default dashboardAppStateSlice.reducer;
