import { Alert } from "src/api";
import * as katzbatApi from '../../api';
import { API_URL_BASE, getAxionsInstance } from '../../global/repositories/RepositoryBase';

export interface IAlertRepository {
    getUnreadAlertsForClient: (_clientId: string) => Promise<Alert[]>;
    markAlertAsRead: (_clientId: string, _alertId: string) => Promise<Alert>;
}

export default function RepositoryAlerts() {
    const alertsApi = (clientId?: string) => katzbatApi.AlertsApiFactory(undefined, API_URL_BASE, getAxionsInstance(clientId));

    const getUnreadAlertsForClient = async (clientId: string): Promise<Alert[]> => {
        const resutls = await alertsApi(clientId).alertsUnreadGet(clientId);

        // Sort by date:
        return resutls.data.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    }

    const markAlertAsRead = async (clientId: string, alertId: string): Promise<Alert> => {
        const resutls = await alertsApi(clientId).alertsUpdateGet(clientId, alertId, true);
        return resutls.data;
    }

    return {
        getUnreadAlertsForClient,
        markAlertAsRead
    }
}