import { VehicleType } from "../../../api";

export function vehicleTypeFromString(value: string): VehicleType {
    return Object.keys(VehicleType).find((item) => item.toString() === value) as VehicleType ?? VehicleType.OTHER;
}

export function vehicleTypeToString(value: VehicleType): string {
    return Object.keys(VehicleType).find((item) => item === value) ?? VehicleType.OTHER;
}

export function getVehicleTypeTranslationKey(value: VehicleType): string {
    return `vehicle_type_${vehicleTypeToString(value).toLowerCase()}`;
}

export const getVehicleTypeLabelKey = (type: VehicleType): string => getVehicleTypeTranslationKey(type)