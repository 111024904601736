import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import RepositoryWebsiteApp from '../repositories/RepositoryWebsiteApp';
import { SendFeedbackReq, WebsiteProduct } from '../../api';

export type WebsiteAppState = {
  producetsDetails?: WebsiteProduct[]
};

const initialState: WebsiteAppState = {
  producetsDetails: undefined
};

/** >>> sendFeedback <<< */
export const sendFeedback = createAsyncThunk('appState/sendFeedback', async (
  {
    sendFeedbackReq
  }:{
    sendFeedbackReq: SendFeedbackReq,
    callback: (_isSuccess: boolean) => void
  }
) => {
  const response = await RepositoryWebsiteApp().sendFeedback(sendFeedbackReq);
  return response;
});


/** >>> getProductsDetails <<< */
export const getProductsDetails = createAsyncThunk('appState/getProductsDetails', async () => {
  const response = await RepositoryWebsiteApp().getProductsDetails();
  return response;
});


/** >>> registerNewUser <<< */
// export const registerNewUser = createAsyncThunk('appState/registerNewUser', async ({
//   registerNewUserReq
// }:{
//   registerNewUserReq: RegisterNewUserReq,
//   callback: (_stripeClientSec?: string) => void
// }) => {
//   const response = await RepositoryWebsiteApp().registerNewUser(registerNewUserReq);
//   return response;
// });

/** >>> checkForStripeSession <<< */
// export const checkForStripeSession = createAsyncThunk('appState/checkForStripeSession', async (
//   _callback: (_stripeClientSec?: string) => void
// ) => {
//   const response = await RepositoryWebsiteApp().checkForStripeSession();
//   return response;
// });

/** >>> Create new checkout session */
// export const createNewStripeSession = createAsyncThunk('appState/createNewStripeSession', async ({
//   createNewCheckoutSessionReq
// }:{
//   createNewCheckoutSessionReq: CreateNewCheckoutSessionReq,
//   callback: (_stripeClientSec?: string) => void
// }) => {
//   const response = await RepositoryWebsiteApp().createNewStripeSession(createNewCheckoutSessionReq);
//   return response;
// });


export const websiteAppStateSlice = createSlice({
  name: 'websiteAppState',
  initialState,
  reducers: {
    
  },

  extraReducers: (builder) => {

    // >>> sendFeedback <<<
    builder.addCase(sendFeedback.fulfilled, (_state, action) => {
      action.meta.arg.callback(action.payload);
    });

    builder.addCase(sendFeedback.rejected, (_state, action) => {
      action.meta.arg.callback(false);
    });

    // >>> getProductsDetails <<<
    builder.addCase(getProductsDetails.pending, (state, _action) => {
      state.producetsDetails = undefined;
    });

    builder.addCase(getProductsDetails.fulfilled, (state, action) => {
      state.producetsDetails = action.payload || [];
    });

    builder.addCase(getProductsDetails.rejected, (state, _action) => {
      state.producetsDetails = undefined;
    });

    // >>> registerNewUser <<<
    // builder.addCase(registerNewUser.fulfilled, (_state, action) => {
    //   action.meta.arg.callback(action.payload);
    // });

    // builder.addCase(registerNewUser.rejected, (_state, action) => {
    //   action.meta.arg.callback(undefined);
    // });

    // // >>> checkForStripeSession <<<
    // builder.addCase(checkForStripeSession.fulfilled, (_state, action) => {
    //   action.meta.arg(action.payload);
    // });

    // builder.addCase(checkForStripeSession.rejected, (_state, action) => {
    //   action.meta.arg(undefined);
    // });

    // // >>> createNewStripeSession <<<
    // builder.addCase(createNewStripeSession.fulfilled, (_state, action) => {
    //   action.meta.arg.callback(action.payload);
    // });

    // builder.addCase(createNewStripeSession.rejected, (_state, action) => {
    //   action.meta.arg.callback(undefined);
    // });
  }


});

export default websiteAppStateSlice.reducer;
