import { m } from 'framer-motion';
import { useState, useCallback } from 'react';

import { 
  Stack, 
  Box,
  Accordion,
  Container,
  Typography,
  AccordionDetails,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import Grid from '@mui/material/Unstable_Grid2';
import AccordionSummary, { accordionSummaryClasses } from '@mui/material/AccordionSummary';

import { useResponsive } from '../../../global/hooks/use-responsive';

import { _home_faqs } from '../../_data';

import Image from '../../../global/components/image';
import Iconify from '../../../global/components/iconify';
import { varFade, MotionViewport } from '../../components/animate';

// ----------------------------------------------------------------------

export default function HomeFAQs() {
  const mdUp = useResponsive('up', 'md');

  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChangeExpanded = useCallback(
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    },
    []
  );

  return (
    <Container
    component={MotionViewport}
      sx={{
        pt: { xs: 5, md: 10 },
        pb: { xs: 10, md: 15 },
      }}
    >
      <Grid container spacing={3} justifyContent="space-between" alignItems="center">
        <Grid xs={12} md={6} lg={6}>
          <m.div variants={varFade().in}>
            <Stack spacing={2} sx={{ mb: 5, textAlign: { xs: 'center', md: 'left' } }}>
              <Typography variant="overline" color="text.disabled">
                שאלות נפוצות
              </Typography>

              <Typography variant="h2">שאלות ותשובות נפוצות</Typography>
            </Stack>
          </m.div>

          <Box
            sx={{
              my: { xs: 8, md: 10 },
            }}
          >
            {_home_faqs.map((faq: any) => (
              <m.div key={faq.question} variants={varFade().in}>
                <Accordion
                  key={faq.id}
                  expanded={expanded === faq.question}
                  onChange={handleChangeExpanded(faq.question)}
                >
                  <AccordionSummary
                    sx={{
                      minHeight: 64,
                      [`& .${accordionSummaryClasses.content}`]: {
                        p: 0,
                        m: 0,
                      },
                      [`&.${accordionSummaryClasses.expanded}`]: {
                        bgcolor: 'action.selected',
                      },
                    }}
                  >
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                      {faq.question}
                    </Typography>

                    <Iconify
                      width={24}
                      icon={expanded === faq.question ? 'carbon:subtract' : 'carbon:add'}
                    />
                  </AccordionSummary>

                  <AccordionDetails>{faq.answer}</AccordionDetails>
                </Accordion>
              </m.div>
          ))}
          </Box>

          <Box
            sx={{
              borderWidth: 1,
              borderRadius: 3,
              textAlign: 'center',
              borderStyle: 'dashed',
              borderColor: (theme) => alpha(theme.palette.grey[500], 0.32),
              px: { xs: 3, md: 8 },
              py: { xs: 6, md: 8 },
            }}
          >
            <m.div variants={varFade().inUp}>
              <Typography variant="h3">שאלות נוספות?</Typography>
            </m.div>

            <m.div variants={varFade().inUp}>
              <Typography sx={{ mt: 3, mb: 3, color: 'text.secondary' }}>
                נשמח לעמוד לרשותך ולתת לך מענה מהיר ולעזור בכל שאלה, שלחו לנו הודעה באמצעות הטופס המצורף בתחתית העמוד.
              </Typography>
            </m.div>

            {/* <m.div variants={varFade().inUp}>
              <Button
                size="large"
                color="primary"
                variant="contained"
                href={`${PATH_WEBSITE.support}`}
                endIcon={<Iconify icon="carbon:lifesaver"/>}
              >
                מעבר למרכז התמיכה
              </Button>
            </m.div> */}
          </Box>

        </Grid>

        {mdUp && (
          <Grid xs={12} md={6} lg={5}>
            <Image alt="faqs" src="/assets/illustrations/illustration_faqs.svg" />
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
