import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { m } from "framer-motion";

import Box from "@mui/material/Box";
import { SxProps, Stack, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

import { useEffect } from "react";
import { varFade, MotionViewport } from "../../components/animate";
import FormProvider, {
  RHFTextField,
} from "../../../global/components/hook-form";
import { useLocales } from "../../../global/locales";
import {
  emailSchemaRequired,
  genericRequiredStringSchema,
} from "../../../global/utils/formValidators";
import {
  useWebsiteAppDispatch,
  useWebsiteAppSelector,
} from "../../hooks/useRedux";
import {
  getProductsDetails,
  sendFeedback,
} from "../../app/websiteAppStateSlice";
import { SendFeedbackReq } from "../../../api";
import { useSnackbar } from "../../../global/components/snackbar";
import PricingCard from "./pricing-home-card";

// ----------------------------------------------------------------------

type Props = {
  sx?: SxProps;
};

export default function Pricing({ sx }: Props) {
  const { translate } = useLocales();
  const dispatch = useWebsiteAppDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const ContactSchema = Yup.object().shape({
    fullName: genericRequiredStringSchema(translate),
    email: emailSchemaRequired(translate),
    phone: Yup.string(),
  });

  const defaultValues = {
    fullName: "",
    email: "",
    phone: "",
  };

  const methods = useForm({
    resolver: yupResolver(ContactSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const showSnackbar = (
    message: string,
    severity: "success" | "error" | "warning" | "info" | "default" | undefined
  ) => {
    enqueueSnackbar(message, {
      variant: severity,
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
    });
  };

  const producetsDetails = useWebsiteAppSelector(
    (state) => state.appStateSlice.producetsDetails
  );

  useEffect(
    () => {
      if (!producetsDetails) {
        dispatch(getProductsDetails());
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [producetsDetails]
  );

  const onSubmit = handleSubmit(async (data) => {
    try {
      const sendFeedbackReq: SendFeedbackReq = {
        email: data.email,
        subject: "!!! >>> New lead from pricing page <<< !!!",
        message: data.phone ? `Phone Number: ${data.phone}` : "",
        fullName: data.fullName,
      } as SendFeedbackReq;

      await dispatch(
        sendFeedback({
          sendFeedbackReq,
          callback: (isSuccess) => {
            if (isSuccess) {
              showSnackbar("ההודעה נשלחה בהצלחה", "success");
            } else {
              showSnackbar("שגיאה בשליחת ההודעה", "error");
            }
            reset();
          },
        })
      );
    } catch (error) {
      console.error(error);
    }
  });

  return (
    <Container component={MotionViewport} sx={sx}>
      <Box
        sx={{
          mb: { xs: 8, md: 10 },
          textAlign: "center",
        }}
      >
        <m.div variants={varFade().inDown}>
          <Typography variant="overline" sx={{ color: "text.disabled" }}>
            הצעת מחיר
          </Typography>
        </m.div>

        <m.div variants={varFade().inDown}>
          <Typography variant="h2" sx={{ my: 3 }}>
            הפתרון המלא
            <br />
            לעסק שלך
          </Typography>
        </m.div>

        <m.div variants={varFade().inUp}>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            בלי התחייבות, בלי אותיות קטנות, פשוט וקל, נסה את המערכת עוד היום.
          </Typography>
        </m.div>

        <m.div variants={varFade().inUp}>
          <Typography variant="h4" sx={{ color: "text.primary" }}>
            תקופת ניסיון ללא התחייבות למשך
            <Box component="span" sx={{ color: "primary.main" }}>
              {` 30 ימים! `}
            </Box>
          </Typography>
        </m.div>
      </Box>

      <m.div variants={varFade().inUp}>
        <Box
          sx={{
            gap: 4,
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              md: "repeat(3, 1fr)",
            },
          }}
        >
          {producetsDetails?.map((planItem) => (
            <PricingCard
              key={planItem.id}
              product={planItem}
            />
          ))}
        </Box>
      </m.div>

      <m.div variants={varFade().inUp}>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{
            mt: 6,
            pl: 3,
          }}
        >
          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" sx={{ color: "text.secondary", mb: 3 }} textAlign={'center'}>
              *פתיחת משתמשים נוספים למערכת תחת אותו המנוי כרוכה בתשלום נוסף.
            </Typography>

            <Typography variant="h6" sx={{ color: "text.primary", mb: 3 }} textAlign={'center'}>
              מעוניין להרישם למערכת? מלא את הפרטים ונחזור אליך בהקדם!
            </Typography>

            <FormProvider methods={methods} onSubmit={onSubmit}>
              <Stack spacing={2.5} justifyContent="center">
                <RHFTextField name="fullName" label="שם" />

                <RHFTextField name="email" label="כתובת דואר אלקטרוני" />

                <RHFTextField name="phone" label="מספר טלפון" />

                <Stack alignItems="center" width={1}>
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    שלח פרטים לקבלת הצעת מחיר
                  </LoadingButton>
                </Stack>
              </Stack>
            </FormProvider>
          </Grid>
        </Grid>
      </m.div>
    </Container>
  );
}
