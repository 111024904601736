import { useRoutes } from "react-router-dom";

import { dashboardRoutes } from "../dashboard/routes/DashboardRoutes";
import { driversRoutes } from "../drivers/routes/DriversRouter";
import { websiteRoutes } from "../website/routes/WebsiteRouter";

export default function MainRouter() {
  return useRoutes([

    // Website Routes:
    ...websiteRoutes,

    // Dashboard Routes:
    ...dashboardRoutes,

    // Drivers Routes:
    ...driversRoutes
  ]);
}
