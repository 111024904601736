import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RepositoryDashboard from '../../repositories/RepositoryDashboard';
import { DashboardData } from '../../../api';

// Define a type for the slice state
export type DashoboardState = {
  isLoadingData?: boolean;
  dashboardData?: DashboardData | null;
};

// Define the initial state using that type
const initialState: DashoboardState = {
  isLoadingData: false,
  dashboardData: undefined,
};

// Create a createAsyncThunk function to get the clients list from the repository
export const getDashboardData = createAsyncThunk('dashboardSlice/getDashboardData', async () => {
  const data = await RepositoryDashboard().getDashboardData();
  return data;
});

// Define the clients list slice
export const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {
    // Clear when logout:
    clear: () => initialState,
  },
  extraReducers: (builder) => {
    
    // Dashboard data:
    builder.addCase(getDashboardData.pending, (state: DashoboardState, _action) => {
      state.isLoadingData = true;
      state.dashboardData = null;
    });

    builder.addCase(getDashboardData.fulfilled, (state: DashoboardState, action) => {
      state.dashboardData = action.payload;
      state.isLoadingData = false;
    });

    builder.addCase(getDashboardData.rejected, (state: DashoboardState, _action) => {
      state.dashboardData = null;
      state.isLoadingData = false;
    });
  },
});

// Export the actions and reducer
export default dashboardSlice.reducer;
export const { clear } = dashboardSlice.actions;
