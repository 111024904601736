import { useState } from "react";

import { 
  Box, 
  Button, 
  Typography,
  Dialog
} from "@mui/material";
import { Email } from "@mui/icons-material";
import { WebAppFeature } from "src/api";
import { ForbiddenIllustration } from "../../assets/illustrations";

import useLocales from "../../../global/locales/useLocales";
import ContactUsForm from "../../features/common/views/ContactUsForm";
import { useDashboardAppSelector } from "../../hooks/useRedux";
import { DashboardRootState } from "../dashboardAppStore";

type FeaturesGuardProp = {
  appFeature: WebAppFeature;
  showError?: boolean;
  children: JSX.Element;
};

function FeatureNotActive() {
  const { translate } = useLocales();
  const [isContactDialogOpen, setIsContactDialogOpen] = useState(false);
  const onSendMessageSent = () => {
    setIsContactDialogOpen(false);
  };

  const handleClose = () => {
    setIsContactDialogOpen(false);
  };

  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
        <Typography
          variant={"h4"}
          sx={{ color: "text" }}
        >{`${translate("global_feature_not_available_title")}`}</Typography>
        <Typography
          variant={"subtitle1"}
          sx={{ color: "text.secondary", pt: 1 }}
        >
          {`${translate("global_feature_not_available")}`}
        </Typography>
        <Button
          sx={{ mt: 2 }}
          onClick={() => setIsContactDialogOpen(true)}
          variant="contained"
          startIcon={<Email />}
        >
          {`${translate("global_contact_us")}`}
        </Button>
      </Box>

      <Dialog open={isContactDialogOpen} onClose={handleClose}>
        <ContactUsForm
          translate={translate}
          onSendMessageSent={onSendMessageSent}
        />
      </Dialog>
    </>
  );
}

function OverlayWithBlur({ children }: { children: JSX.Element }): JSX.Element {
  return (
    <div style={{ position: "relative" }}>
      {children}
      <div
        style={{
          position: "absolute",
          top: "-10px",
          left: "-10px",
          right: "-10px",
          bottom: "-10px",
          backdropFilter: "blur(3px)",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          zIndex: 1,
        }}
      />
      <div
        style={{
          position: "absolute",
          justifyContent: "center",
          alignItems: "center",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 2,
        }}
      >
        <FeatureNotActive />
      </div>
    </div>
  );
}

export default function DashboardAppFeaturesGuard({
  appFeature,
  showError = false,
  children,
}: FeaturesGuardProp): JSX.Element {
  const disabledFeatures = useDashboardAppSelector(
    (state: DashboardRootState) => state.appStateSlice.appConfig.disabledFeatures
  );

  if (!disabledFeatures?.includes(appFeature)) {
    return children;
  }

  if (showError) return <OverlayWithBlur>{children}</OverlayWithBlur>;

  return <></>;
}
