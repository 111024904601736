import { useEffect } from "react";

import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";

import { useBoolean } from "../../../../../global/hooks/use-boolean";

import Logo from "../../../../../global/components/logo";
import Iconify from "../../../../../global/components/iconify";
// import Scrollbar from "../../../../../global/components/scrollbar";

import NavList from "./nav-list";
import { NavProps } from "../types";
import { NAV } from "../../../config-layout";

// ----------------------------------------------------------------------

export default function NavMobile({ data }: NavProps) {
  // const pathname = usePathname();

  const mobileOpen = useBoolean();

  useEffect(
    () => {
      if (mobileOpen.value) {
        mobileOpen.onFalse();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ ]
  );

  return (
    <>
      <IconButton onClick={mobileOpen.onTrue} sx={{ ml: 1, color: "inherit" }}>
        <Iconify icon="carbon:menu" />
      </IconButton>

      <Drawer
        anchor="right"
        open={mobileOpen.value}
        onClose={mobileOpen.onFalse}
        PaperProps={{
          sx: {
            pb: 5,
            width: NAV.W_VERTICAL,
          },
        }}
        SlideProps={{ direction: "right" }}
      >
        <Logo sx={{ mx: 2.5, my: 3 }} />

        <List component="nav" disablePadding>
          {data.map((list) => (
            <NavList key={list.title} data={list} />
          ))}
        </List>
      </Drawer>
    </>
  );
}
