import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DocumentCategory, VehicleReportType, DocumentConfigOption, DocumentInput, VehicleReportConfig } from '../../../../api';
import RepositoryConfig from '../../../repositories/RepositoryConfig';
import { VehicleType } from '../../../../api/api';

export type SelectReportState = {
  vehicleType?: VehicleType;
  reportType?: VehicleReportType;
  userConfig?: VehicleReportConfig;
};

export type ReprotConfigState = {
  isLoading: boolean;
  staticDocumentCategoryQuestions: DocumentCategory[] | null;
  staticDocumentInputs: DocumentInput[] | null;
  selectReportState: SelectReportState;
  avilableReportTypes: DocumentConfigOption[];
};

const initialState: ReprotConfigState = {
  isLoading: false,
  staticDocumentCategoryQuestions: null,
  staticDocumentInputs: null,
  selectReportState: {},
  avilableReportTypes: []
};


export const getStaticDocumentConfig = createAsyncThunk('settingsReportConfigSlice/getStaticDocumentConfig', async (
  { reportType }: { reportType: VehicleReportType }
) => {
  const data = await RepositoryConfig().staticTestCategories(reportType);
  return data;
});

export const getVehicleAvilableReportTypes = createAsyncThunk('settingsReportConfigSlice/getVehicleAvilableReportTypes', async (
  { langCode, vehicleType }: { langCode: string, vehicleType: VehicleType }
) => {
  const data = await RepositoryConfig().getVehicleAvilableReportTypes(langCode, vehicleType);
  return data;
});

export const getStaticDocumentInputs = createAsyncThunk('settingsReportConfigSlice/getStaticDocumentInputs', async (
  { langCode }: { langCode: string }
) => {
  const data = await RepositoryConfig().getStaticDocumentInputs(langCode);
  return data;
});

export const getUserVehicleTestConfig = createAsyncThunk('settingsReportConfigSlice/getUserVehicleTestConfig', async (
  { reportType }: { reportType: VehicleReportType }
) => {
  const data = await RepositoryConfig().getVehicleTestConfig(reportType);
  return data;
});

export const updateUserVehicleTestConfig = createAsyncThunk('settingsReportConfigSlice/updateUserVehicleTestConfig', async (
  { reportType, config }: { reportType: VehicleReportType, config: VehicleReportConfig }
) => {
  const data = await RepositoryConfig().updateVehicleTestConfig(reportType, config);
  return data;
});

export const setDaysBeforeAlert = createAsyncThunk('settingsReportConfigSlice/setDaysBeforeAlert', async (
  { days }: { days: number }
) => {
  const data = await RepositoryConfig().setDaysBeforeAlert(days);
  return data;
});


export const settingsReportConfigSlice = createSlice({
  name: 'settingsReportConfigSlice',
  initialState,
  reducers: {
    // Reset current report:
    onResetCurrentReportConfig: (state: ReprotConfigState) => {
      state.selectReportState.reportType = undefined;
      state.selectReportState.userConfig = undefined;
    },

    onSelectedVehicleType: (state: ReprotConfigState, action) => {
      state.selectReportState.vehicleType = action.payload;
    },

    onSelectedReportType: (state: ReprotConfigState, action) => {
      state.selectReportState.reportType = action.payload;
    },

    onDocumentInputChanged: (state: ReprotConfigState, action) => {
      const { dbKey, checked } = action.payload;
      if (checked) {
        state.selectReportState.userConfig!.enabled_test_inputs.push({
          dbKey,
          isMandatory: false,
          order: 0
        });
      } else {
        const index = state.selectReportState.userConfig!.enabled_test_inputs.findIndex((item) => item.dbKey === dbKey);
        if (index > -1) {
          state.selectReportState.userConfig!.enabled_test_inputs.splice(index, 1);
        }
      }
    },

    onDocumentInputMandetoryChanged: (state: ReprotConfigState, action) => {
      const { dbKey, checked } = action.payload;
      const index = state.selectReportState.userConfig!.enabled_test_inputs.findIndex((item) => item.dbKey === dbKey);
      if (index > -1) {
        state.selectReportState.userConfig!.enabled_test_inputs[index].isMandatory = checked;
      }
    },

    onDocumentQuestionChanged: (state: ReprotConfigState, action) => {
      const { id, checked } = action.payload;
      if (checked) {
        state.selectReportState.userConfig!.enabled_test_questions.push(id);
      } else {
        const index = state.selectReportState.userConfig!.enabled_test_questions.findIndex((item) => item === id);
        if (index > -1) {
          state.selectReportState.userConfig!.enabled_test_questions.splice(index, 1);
        }
      }
    }
  },

  extraReducers: (builder) => {

    // Get static document config:
    builder.addCase(getStaticDocumentConfig.fulfilled, (state: ReprotConfigState, action) => {
      if (action.payload) {
        state.staticDocumentCategoryQuestions = action.payload;
      }
    });

    builder.addCase(getStaticDocumentConfig.rejected, (state: ReprotConfigState, _action) => {
      state.staticDocumentCategoryQuestions = [];
    });

    // Get vehicle avilable report types:
    builder.addCase(getVehicleAvilableReportTypes.fulfilled, (state: ReprotConfigState, action) => {
      if (action.payload) {
        state.avilableReportTypes = action.payload;
      }
    });

    builder.addCase(getVehicleAvilableReportTypes.rejected, (_state: ReprotConfigState, _action) => {
      // state.selectedReportConfig = null;
    });

    // Get static document inputs:
    builder.addCase(getStaticDocumentInputs.fulfilled, (state: ReprotConfigState, action) => {
      if (action.payload) {
        state.staticDocumentInputs = action.payload;
      }
    });

    builder.addCase(getStaticDocumentInputs.rejected, (state: ReprotConfigState, _action) => {
      state.staticDocumentInputs = null;
    });

    // Get user vehicle test config:
    builder.addCase(getUserVehicleTestConfig.pending, (state: ReprotConfigState, _action) => {
      state.isLoading = true;
    });

    builder.addCase(getUserVehicleTestConfig.fulfilled, (state: ReprotConfigState, action) => {
      if (action.payload) {
        state.selectReportState.userConfig = action.payload;
      }
      state.isLoading = false;
    });

    builder.addCase(getUserVehicleTestConfig.rejected, (state: ReprotConfigState, _action) => {
      state.selectReportState.userConfig = {
        reportType: state.selectReportState.reportType,
        enabled_test_inputs: [],
        enabled_test_questions: []
      } as VehicleReportConfig;
      state.isLoading = false;
    });



    // update user vehicle test config:
    builder.addCase(updateUserVehicleTestConfig.pending, (state: ReprotConfigState, _action) => {
      state.isLoading = true;
    });

    builder.addCase(updateUserVehicleTestConfig.fulfilled, (state: ReprotConfigState, action) => {
      if (!action.payload) {
        // SHOW ERROR HERE
      }
      state.isLoading = false;
    });

    builder.addCase(updateUserVehicleTestConfig.rejected, (state: ReprotConfigState, _action) => {
      state.isLoading = false;
    });

    // Set days before alert:
    builder.addCase(setDaysBeforeAlert.pending, (state: ReprotConfigState, _action) => {
      state.isLoading = true;
    });

    builder.addCase(setDaysBeforeAlert.fulfilled, (state: ReprotConfigState, _action) => {
      state.isLoading = false;
    });

    builder.addCase(setDaysBeforeAlert.rejected, (state: ReprotConfigState, _action) => {
      state.isLoading = false;
    });
  }


});

export default settingsReportConfigSlice.reducer;
export const {
  onResetCurrentReportConfig,
  onSelectedReportType,
  onSelectedVehicleType,
  onDocumentInputChanged,
  onDocumentQuestionChanged,
  onDocumentInputMandetoryChanged
} = settingsReportConfigSlice.actions;
