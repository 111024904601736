import { m } from 'framer-motion';


import { 
  Link,
  Box,
  Button,
  Container,
  Typography
} from '@mui/material';

import { useResponsive } from '../../../global/hooks/use-responsive';

import Image from '../../../global/components/image';
import Iconify from '../../../global/components/iconify';
import { varFade, MotionViewport } from '../../components/animate';

import { PATH_WEBSITE } from '../../routes/paths';
// ----------------------------------------------------------------------

export type HomeCombinationProps = {
  onContactUsClicked: () => void;
};
export default function HomeCombination({ onContactUsClicked }: HomeCombinationProps) {
  const mdUp = useResponsive('up', 'md');

  const visitBtn = (
    <m.div variants={varFade().inLeft}>
      <Link href={`#${PATH_WEBSITE.home_anchor_contact}`}>
        <Button
          size="large"
          color="primary"
          variant="outlined"
          target="_blank"
          rel="noopener"
          href={''}
          onClick={onContactUsClicked}
          endIcon={<Iconify icon="carbon:chevron-left" width={16} />}
          sx={{ ...(mdUp && { mt: 5 }) }}
        >
          לחץ כאן לתיאום הדגמה
        </Button>
      </Link>
    </m.div>
  );

  return (
    <Container
      component={MotionViewport}
      sx={{
        position: 'relative',
        pt: { xs: 5, md: 10 },
        pb: { xs: 10, md: 15 },
        textAlign: { xs: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          borderRadius: 3,
          p: { xs: 2, md: 10 },
          bgcolor: { xs: 'transparent', md: 'background.neutral' },
        }}
      >
        <m.div variants={varFade().inUp}>
          <Typography variant="overline" sx={{ color: 'text.disabled' }}>
            הדגמת המערכת
          </Typography>
        </m.div>

        <m.div variants={varFade().inUp}>
          <Typography variant="h3" sx={{ my: 3 }}>
            מעוניין לראות איך <br />
            המערכת עובדת?
          </Typography>
        </m.div>

        <m.div variants={varFade().inUp}>
          <Typography
            sx={{
              color: 'text.secondary',
              maxWidth: 360,
              mx: {
                xs: 'auto',
                md: 'unset',
              },
            }}
          >
            נשמח להדגים לך את המערכת ולהסביר כיצד היא יכולה לשפר את תהליכי העבודה שלך.
          </Typography>
        </m.div>

        {mdUp && visitBtn}
      </Box>

      <Box
        sx={{
          top: { md: -40 },
          right: { md: -120 },
          my: { xs: 8, md: 0 },
          position: { md: 'absolute' },
        }}
      >
        <m.div variants={varFade().inDown}>
          <Image
            src="/assets/images/home/main_dashboard.png"
            sx={{
              maxWidth: { md: 790 },
            }}
          />
        </m.div>
      </Box>

      {!mdUp && visitBtn}
    </Container>
  );
}
