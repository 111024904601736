
export const COOKIE_NAME_RELATED_CLIENT_ID = 'relatedClientId';
export const COOKIE_NAME_DISABLED_FEATURES = 'disabledFeatures';


export type Props = { name: string, id?: string }
export function setCookie({ id, name, value }: Props & { value?: any }) {
    if (value) {
        localStorage.setItem(`${id}_${name}`, JSON.stringify(value));
    } else {
        localStorage.removeItem(`${id}_${name}`);
    }
}

export function getCookie({ id, name }: Props) {
    const localValue = localStorage.getItem(`${id}_${name}`);
    if (localValue) {
        return JSON.parse(localValue);
    } 
    return undefined;
}

export function removeCookie({ id, name }: Props) {
    localStorage.removeItem(`${id}_${name}`);
}