import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import RepositoryDriver from '../../repositories/RepositoryDriver';
import { DriverDocumentCreateReq, DriverDocumentHeader, DriverDocumentUpload, DriverDocumentUploadType, DriverLicenseRes, DriverUser, UploadDriverLicenseReq } from '../../../api';
import { downloadFile } from '../../../global/repositories/RepositoryBase';
import RepositoryDriverDocuments from '../../repositories/RepositoryDriverDocuments';

export type DriverDocumentTableItem = {
  id: string;
  title?: string;
  createdAt?: string;
  documentTypeStringId: string;
  isUploaded: boolean;
  raw: DriverDocumentHeader | DriverDocumentUpload;
};

export type DriverPageState = {
  data: DriverUser | undefined;
  driverLicense: DriverLicenseRes | undefined;
  isDownloadingFile: boolean;
  currentDocuments?: DriverDocumentTableItem[];
  isLoadingData: {
    driverData: boolean;
    driverLicense: boolean;
    driverDocuments: boolean;
    uploadDocument: boolean;
  };
};

const initialState: DriverPageState = {
  data: undefined,
  driverLicense: undefined,
  isDownloadingFile: false,
  currentDocuments: undefined,
  isLoadingData: {
    driverData: false,
    driverLicense: false,
    driverDocuments: false,
    uploadDocument: false
  },
};


export const getDriverData = createAsyncThunk('driverPageSlice/getDriverData', async (
  { driverId }: { driverId: string }
) => {
  const data = await RepositoryDriver().getDriverData(driverId);
  return data;
});

// Delete driver:
export const deleteDriverFromApi = createAsyncThunk('driverPageSlice/deleteDriverFromApi', async ({ driverId }: { driverId: string }) => {
  const data = await RepositoryDriver().deleteDriver(driverId);
  return data;
});

// Get driver license data:
export const getDriverLicenseData = createAsyncThunk('driverPageSlice/getDriverLicenseData', async (
  { driverId }: { driverId: string }
) => {
  const data = await RepositoryDriver().getDriverLicenseRes(driverId);
  return data;
});

// Upload driver license:
export const uploadDriverLicense = createAsyncThunk('driverPageSlice/uploadDriverLicense', async (
  { ploadDriverLicenseReq }: { ploadDriverLicenseReq: UploadDriverLicenseReq }
) => {
  const data = await RepositoryDriver().uploadDriverLicense(ploadDriverLicenseReq);
  return data;
});

// Post new driver document create req:
export const postNewDriverDocumentCreateReq = createAsyncThunk('driverPageSlice/postNewDriverDocumentCreateReq', async (
  { req, callback }: { req: DriverDocumentCreateReq, callback: (_docHeader?: DriverDocumentHeader) => void }
) => {
  const data = await RepositoryDriver().postNewDriverDocumentCreateReq(req);
  callback(data);
  return data;
});

// Download driver document:
export const downloadDriverDocument = createAsyncThunk('driverPageSlice/downloadDriverDocument', async (
  { driverId, documentId }: { driverId: string, documentId: string }
) => {
  const data = await RepositoryDriver().downloadDriverDocument(driverId, documentId);
  return data;
});

// Send driver onboarding notification:
export const sendDriverOnboardingNotification = createAsyncThunk('driverPageSlice/sendDriverOnboardingNotification', async (
  { driverId }: { 
    driverId: string,
    onComplete: (_isSuccess?: boolean) => void
  }
) => {
  const data = await RepositoryDriver().sendDriverOnboardingNotification(driverId);
  return data;
});

// Download driver license:
export const downloadDriverLicense = createAsyncThunk('driverPageSlice/downloadDriverLicense', async (
  { driverId }: { driverId: string }
) => {
  const data = await RepositoryDriver().downloadDriverLicense(driverId);
  return data;
});


// Driver Uploaded Documents:

type getDriverUploadDocumentsParams = {
  driverUserId: string;
}

// Get driver documents headers:
export const getDriverDocumentsHeaders = createAsyncThunk('driverPageSlice/getDriverDocumentsHeaders', async (
  { driverId }: { driverId: string }
) => {
  const data = await RepositoryDriver().getDriverDocumentsHeaders(driverId);
  return data;
});

export const getAllDriverDocuments = createAsyncThunk('driverPageSlice/getAllDriverDocuments', async ({ driverUserId }: getDriverUploadDocumentsParams) => {
  const docsHeaders = await RepositoryDriver().getDriverDocumentsHeaders(driverUserId);

  const result: DriverDocumentTableItem[] = [];
  docsHeaders?.forEach((header) => {
    result.push({
      id: header.id,
      createdAt: header.createdAt,
      documentTypeStringId: `driver_document_title_${header.documentType}`,
      isUploaded: false,
      raw: header
    } as DriverDocumentTableItem);
  });

  const docsUploaded = await RepositoryDriverDocuments().getDriverDocuments({
    driverUserId
  });

  docsUploaded?.forEach((doc) => {
    result.push({
      id: doc.id,
      title: doc.title,
      createdAt: doc.expirationDate,
      documentTypeStringId: `driver_document_upload_title_${doc.documentType}`,
      isUploaded: true,
      raw: doc
    } as DriverDocumentTableItem);
  });

  return result;
});

type downloadDriverUploadDocumentsParams = getDriverUploadDocumentsParams & {
  documentType: DriverDocumentUploadType;
  documentId: string;
  onRespose: (_data: DriverDocumentUpload) => void;
}

export const downloadDriverUploadedDocument = createAsyncThunk('driverPageSlice/downloadDriverUploadedDocument', async ({ 
  driverUserId, 
  documentType, 
  documentId, 
  onRespose
 }: downloadDriverUploadDocumentsParams) => {
  const data = await RepositoryDriverDocuments().getDriverDocuemntById({
    driverUserId,
    documentType,
    documentId
  });
  onRespose(data);
});



export type AddDriverUploadDocumentParams = getDriverUploadDocumentsParams & {
  documentType: DriverDocumentUploadType;
  title: string;
  expirationDate: string | null;
  isHidden?: boolean;
  file: File;
  onRespose: (_isSuccess: boolean) => void;
};


export const uploadVehicleDocument = createAsyncThunk('vehiclePageSlice/addVehicleDocument', async ({ 
  driverUserId, 
  documentType, 
  title, 
  expirationDate,
  isHidden,
  file, 
  onRespose
 }: AddDriverUploadDocumentParams) => {
  try {
    const isSuccess = await RepositoryDriverDocuments().addDriverDocument({
      driverUserId,
      documentType,
      expirationDate,
      title,
      isHidden,
      docuemnt: file
    });
    onRespose(isSuccess);
    return isSuccess;
  } catch (error) {
    onRespose(false);
    return false;
  }
});


export const driverPageSlice = createSlice({
  name: 'vehiclePageSlice',
  initialState,
  reducers: {
    // Clear state
    resetDriverData: (state: DriverPageState) => {
      state.data = undefined;
      state.isLoadingData = {
        driverData: false,
        driverLicense: false,
        driverDocuments: false,
        uploadDocument: false
      };
    },
  },
  extraReducers: (builder) => {

    // Get driver data: 
    builder.addCase(getDriverData.pending, (state: DriverPageState) => {
      state.isLoadingData.driverData = true;
    });

    builder.addCase(getDriverData.fulfilled, (state: DriverPageState, action) => {
      state.data = action.payload;
      state.isLoadingData.driverData = false;
    });

    builder.addCase(getDriverData.rejected, (state: DriverPageState) => {
      state.isLoadingData.driverData = false;
    });

    // Get driver license data:
    builder.addCase(getDriverLicenseData.pending, (state: DriverPageState) => {
      state.isLoadingData.driverLicense = true;
    });

    builder.addCase(getDriverLicenseData.fulfilled, (state: DriverPageState, action) => {
      state.driverLicense = action.payload;
      state.isLoadingData.driverLicense = false;
    });

    builder.addCase(getDriverLicenseData.rejected, (state: DriverPageState) => {
      state.driverLicense = undefined;
      state.isLoadingData.driverLicense = false;
    });

    // Delete driver:
    builder.addCase(deleteDriverFromApi.pending, (state: DriverPageState, _action) => {
      state.isLoadingData.driverData = true;
    });

    builder.addCase(deleteDriverFromApi.fulfilled, (state: DriverPageState, _action) => {
      state.data = undefined;
      state.isLoadingData.driverData = false;
    });

    builder.addCase(deleteDriverFromApi.rejected, (state: DriverPageState, _action) => {
      state.isLoadingData.driverData = false;
    });

    // Upload driver license:
    builder.addCase(uploadDriverLicense.pending, (state: DriverPageState, _action) => {
      state.isLoadingData.driverData = true;
    });

    builder.addCase(uploadDriverLicense.fulfilled, (state: DriverPageState, _action) => {
      state.isLoadingData.driverData = false;
    });

    builder.addCase(uploadDriverLicense.rejected, (state: DriverPageState, _action) => {
      state.isLoadingData.driverData = false;
    });

    // Post new driver document create req:
    builder.addCase(postNewDriverDocumentCreateReq.pending, (state: DriverPageState, _action) => {
      state.isLoadingData.driverDocuments = true;
    });

    builder.addCase(postNewDriverDocumentCreateReq.fulfilled, (state: DriverPageState, action) => {
      if (action.payload) {
        state.currentDocuments = [{
          id: action.payload.id,
          documentTypeStringId: `driver_document_upload_title_${action.payload.documentType}`
        } as DriverDocumentTableItem, ...state.currentDocuments || []];
      }
      state.isLoadingData.driverDocuments = false;
    });

    builder.addCase(postNewDriverDocumentCreateReq.rejected, (state: DriverPageState, _action) => {
      state.isLoadingData.driverDocuments = false;
    });

    // Get driver documents headers:
    builder.addCase(getAllDriverDocuments.pending, (state: DriverPageState, _action) => {
      state.isLoadingData.driverDocuments = true;
    });

    builder.addCase(getAllDriverDocuments.fulfilled, (state: DriverPageState, action) => {
      state.currentDocuments = action.payload;
      state.isLoadingData.driverDocuments = false;
    });

    builder.addCase(getAllDriverDocuments.rejected, (state: DriverPageState, _action) => {
      state.isLoadingData.driverDocuments = false;
    });

    // Download driver document:
    builder.addCase(downloadDriverDocument.pending, (state: DriverPageState, _action) => {
      state.isDownloadingFile = true;
    });

    builder.addCase(downloadDriverDocument.fulfilled, (state: DriverPageState, action) => {
      downloadFile(action.payload, `${action.meta.arg.documentId}`, 'application/pdf')
      state.isDownloadingFile = false;
    });

    builder.addCase(downloadDriverDocument.rejected, (state: DriverPageState, _action) => {
      state.isDownloadingFile = false;
    });

    // Send driver onboarding notification:
    builder.addCase(sendDriverOnboardingNotification.pending, (state: DriverPageState, _action) => {
      state.isLoadingData.driverData = true;
    });

    builder.addCase(sendDriverOnboardingNotification.fulfilled, (state: DriverPageState, action) => {
      state.isLoadingData.driverData = false;
      const result = action.payload;
      action.meta.arg.onComplete(result);
    });

    builder.addCase(sendDriverOnboardingNotification.rejected, (state: DriverPageState, action) => {
      state.isLoadingData.driverData = false;
      action.meta.arg.onComplete(false);
    });

    // Download driver license:
    builder.addCase(downloadDriverLicense.pending, (state: DriverPageState, _action) => {
      state.isDownloadingFile = true;
    });

    builder.addCase(downloadDriverLicense.fulfilled, (state: DriverPageState, action) => {
      downloadFile(action.payload, `driver-license`, 'application/pdf')
      state.isDownloadingFile = false;
    });

    builder.addCase(downloadDriverLicense.rejected, (state: DriverPageState, _action) => {
      state.isDownloadingFile = false;
    });

    // Download driver uploaded document:
    builder.addCase(downloadDriverUploadedDocument.pending, (state: DriverPageState, _action) => {
      state.isDownloadingFile = true;
    });

    builder.addCase(downloadDriverUploadedDocument.fulfilled, (state: DriverPageState, action) => {
      downloadFile(action.payload, `${action.meta.arg.documentId}`, 'application/pdf')
      state.isDownloadingFile = false;
    });

    builder.addCase(downloadDriverUploadedDocument.rejected, (state: DriverPageState, _action) => {
      state.isDownloadingFile = false;
    });

    // Upload driver document:
    builder.addCase(uploadVehicleDocument.pending, (state: DriverPageState, _action) => {
      state.isLoadingData.uploadDocument = true;
    });

    builder.addCase(uploadVehicleDocument.fulfilled, (state: DriverPageState, _action) => {
      state.isLoadingData.uploadDocument = false;
    });

    builder.addCase(uploadVehicleDocument.rejected, (state: DriverPageState, _action) => {
      state.isLoadingData.uploadDocument = false;
    });
  },
});

// Export the actions and reducer
export default driverPageSlice.reducer;
export const { resetDriverData } = driverPageSlice.actions;