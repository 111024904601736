import { Provider as ReduxProvider } from "react-redux";
import { PATH_DRIVER } from "./paths";
import DriverLoginPage from "../features/login/DriverLoginPage";
import DriverOnboardingPage from "../features/onboarding/DriverOnboardingPage";
import { DriversApp } from "../app/DriversApp";
import { driversAppStore } from "../app/driversAppStore";

// ----------------------------------------------------------------------

export const driversRoutes = [
  {
    path: PATH_DRIVER.root,
    element: (
      <ReduxProvider store={driversAppStore}>
        <DriversApp />
      </ReduxProvider>
    ),
    children: [
      // Login:
      {
        path: PATH_DRIVER.login,
        element: <DriverLoginPage />,
      },

      // Onboarding:
      {
        path: PATH_DRIVER.onboarding,
        element: <DriverOnboardingPage />,
      }
    ],
  }
];
