import { Link as RouterLink } from "react-router-dom";
// @mui
import { Typography, Box, Link, BoxProps, Stack } from "@mui/material";

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  linkTo?: string;
  textColor?: string;
}

export default function Logo({
  disabledLink = false, 
  linkTo = '/',
  textColor, 
  sx
}: LogoProps) {
  const logo = (
    <Stack direction="row" alignItems="center">
      <Box
        component="img"
        src="/assets/logo.png"
        sx={{ width: 40, height: 40, cursor: "pointer", ...sx }}
      />

      <Typography variant="h6" sx={{ color: textColor || "primary.main", marginInlineStart: 2 }}>
        מערכת קצב״ת
      </Typography>
    </Stack>
  );

  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} to={linkTo} sx={{ display: "contents" }}>
      {logo}
    </Link>
  );
};