import { CSSProperties } from "react";
import il_driver_license_front from "../../../assets/images/driverLicenseExample/il_driver_license_front.jpg";
import il_driver_license_back from "../../../assets/images/driverLicenseExample/il_driver_license_back.jpg";

export default function LicenseFrame({
    isFront,
    currentImgUrl,
    translate,
    onClicked,
  }: {
    isFront: boolean;
    currentImgUrl?: string;
    translate: Function;
    onClicked: () => void;
  }): React.ReactElement {
    const mainContainer: CSSProperties = {
      position: "relative",
      border: "2px solid #00618A",
      borderRadius: "15px",
      display: "flex",
      flexDirection: "column",
      cursor: "pointer",
    };
  
    const imageStyle: CSSProperties = {
      width: "100%",
      height: "auto",
      filter: currentImgUrl ? "none" : "grayscale(100%)",
      opacity: currentImgUrl ? 1 : 0.2,
      borderRadius: "10px",
    };
  
    const textStyle: CSSProperties = {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      fontSize: "22px",
      fontWeight: "bolder",
      textShadow: "2px 2px 42px #ffffff",
      color: "#00618A",
    };
  
    const placeHolderPic = !currentImgUrl
      ? isFront
        ? il_driver_license_front
        : il_driver_license_back
      : currentImgUrl;
  
    return (
      <div
        style={mainContainer}
        onClick={onClicked}
        onKeyDown={onClicked}
        role="button"
        tabIndex={0}
      >
        <img src={placeHolderPic} alt="Drivers License" style={imageStyle} />
  
        {!currentImgUrl && (
          <div style={textStyle}>
            {`${translate(
              isFront
                ? "driver_page_onboarding_license_photo_front"
                : "driver_page_onboarding_license_photo_back"
            )}`}
          </div>
        )}
      </div>
    );
  }
