import * as katzbatApi from '../../api';
import { API_URL_BASE, getAxionsInstance } from '../../global/repositories/RepositoryBase';

import { Vehicle, VehicleHeader } from '../../api';

export default function RepositoryVehicle() {

  const apiVehicle = (clientId?: string) => katzbatApi.VehicleApiFactory(undefined, API_URL_BASE, getAxionsInstance(clientId));

  const getVehicles = async (clientId: string, fromArchive: boolean): Promise<VehicleHeader[] | undefined> => {
    if (fromArchive) {
      const resutls = await apiVehicle(clientId).vehicleArchiveHeadersGet(clientId);
      return resutls.data;
    }

    const resutls = await apiVehicle(clientId).vehicleHeadersGet(clientId);
    return resutls.data;
  }

  const getVehicleData = async (clientId: string, licenseNumber: string): Promise<Vehicle | undefined> => {
    
    const resutls = await apiVehicle(clientId).vehicleGet(clientId, licenseNumber);
    return resutls.data;
  };

  // === POST: Add Vehicle ===
  const addVehicle = async (clientId: string, vehicle: Vehicle): Promise<Vehicle | undefined> => {
    const resutls = await apiVehicle(clientId).vehiclePost(clientId, vehicle);
    return resutls.data;
  }

  // === PATCH: Update Vehicle ===
  const updateVehicle = async (clientId: string, vehicle: Vehicle): Promise<Vehicle | undefined> => {
    const resutls = await apiVehicle(clientId).vehiclePatch(clientId, vehicle);
    return resutls.data;
  }

  // === PATCH: Link driver to vehicle===
  const linkDriverToVehicle = async (clientId: string, licenseNumber: string,  driverId: string): Promise<string | undefined> => {
    const resutls = await apiVehicle(clientId).vehicleLinkDriverPut(clientId, licenseNumber, driverId);
    return resutls.data;
  }

  // === DELETE: Remove driver from vehicle ===
  const removeDriverFromVehicle = async (clientId: string, driverId: string, licenseNumber: string): Promise<string | undefined> => {
    const resutls = await apiVehicle(clientId).vehicleRemoveDriverDelete(clientId, driverId, licenseNumber);
    return resutls.data;
  }


  return {
    getVehicles,
    addVehicle,
    getVehicleData,
    updateVehicle,
    linkDriverToVehicle,
    removeDriverFromVehicle
  };
}
