import { m } from 'framer-motion';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import Iconify from '../../../global/components/iconify';
import { varFade, MotionViewport } from '../../components/animate';

// ----------------------------------------------------------------------

const FEATURE_HIGHLIGHTS = [
  { title: 'ללא התקנה', icon: 'carbon:devices' },
  { title: 'ניהול לקוחות', icon: 'carbon:account' },
  { title: 'ניהול רכבים', icon: 'carbon:shuttle' },
  { title: 'מעקב אחרי מסמכים', icon: 'carbon:document-multiple-02' },
  { title: 'קליטת נהגים', icon: 'carbon:ibm-telehealth' },
  { title: 'הפקת דוחות בדיקה', icon: 'carbon:report' },
  { title: 'אפליקציה לבדיקת הרכב', icon: 'carbon:application-mobile' },
  { title: 'הפקת מסמכים לנהג', icon: 'carbon:text-font' },
  { title: 'קבלת התראות', icon: 'carbon:notification' },
  { title: 'חתימה דיגיטלית', icon: 'carbon:pen' },
  { title: 'תמיכה', icon: 'carbon:help' },
  { title: 'ועוד...', icon: 'carbon:add-alt' },
];

// ----------------------------------------------------------------------

export default function HomeFeatureHighlights() {
  return (
    <Container
      component={MotionViewport}
      sx={{
        overflow: 'hidden',
        pt: { xs: 5, md: 10 },
        pb: { xs: 10, md: 15 },
      }}
    >
      <Grid container spacing={{ xs: 8, md: 3 }} justifyContent={{ md: 'space-between' }}>
        <Grid
          xs={12}
          md={4}
          sx={{
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          <m.div variants={varFade().inUp}>
            <Typography variant="overline" sx={{ color: 'text.disabled' }}>
              יכולות המערכת
            </Typography>
          </m.div>

          <m.div variants={varFade().inUp}>
            <Typography variant="h2" sx={{ my: 3 }}>
              כל מה שצריך במקום אחד
            </Typography>
          </m.div>

          <m.div variants={varFade().inUp}>
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
              המערכת מאפשר לבצע מגוון פעולות בצורה קלה ונוחה מהמחשב או מהסמארטפון, בדיקות רכבים, קליטת נהגים, הפקת מסמכים ודוחות חתומים דיגיטלית, הכל מתבצע אונליין...
            </Typography>
          </m.div>
        </Grid>

        <Grid xs={12} md={7}>
          <Box
            sx={{
              rowGap: 6,
              columnGap: 3,
              display: 'grid',
              gridTemplateColumns: {
                xs: 'repeat(2, 1fr)',
                sm: 'repeat(3, 1fr)',
              },
            }}
          >
            {FEATURE_HIGHLIGHTS.map((feature) => (
              <m.div key={feature.title} variants={varFade({ distance: 40 }).in}>
                <Box sx={{ textAlign: 'center' }}>
                  <Iconify icon={feature.icon} width={32} />

                  <Typography variant="subtitle2" component="div" sx={{ mt: 2 }}>
                    {feature.title}
                  </Typography>
                </Box>
              </m.div>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
