export function AppDeleteAccountRequest() {

    return (
        <html lang="he">
            <head>
                <meta charSet="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>הוראות מחיקת חשבון</title>
                <style>
                    {`
                        body {
                            font-family: Arial, sans-serif;
                            margin: 20px;
                        }

                        h1 {
                            color: #333;
                        }

                        p {
                            color: #555;
                        }

                        .instructions {
                            margin-top: 20px;
                        }

                        .email-address {
                            font-weight: bold;
                            color: #007BFF;
                        }
                    `}
                </style>
            </head>
            <body>
                <h1>הוראות מחיקת חשבון</h1>
                <p>
                    אנו מתנצלים לראותך עוזב. אם ברצונך למחוק את החשבון שלך והנתונים הקשורים אליו, אנא בצע את ההוראות הבאות:
                </p>
                <div className="instructions">
                    <p>
                        כדי להתחיל בתהליך מחיקת החשבון, שלח אימייל אל:
                    </p>
                    <p className="email-address">app@katzbat.co.il</p>
                    <p>
                        באימייל שלך, נא לכלול את פרטי החשבון שלך ולבקש מחיקת חשבון. לאחר קבלת האימייל שלך, נעבוד על בקשתך בהתאם.
                    </p>
                </div>
                <p>
                    אם יש לך שאלות או דאגות, אנא צור קשר עם צוות התמיכה שלנו.
                </p>
                <p>
                    תודה על שימושך באפליקציה שלנו!
                </p>
            </body>
        </html>
    );
}