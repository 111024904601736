import { m } from "framer-motion";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { alpha, styled } from "@mui/material/styles";
import Stack, { StackProps } from "@mui/material/Stack";
import Button, { buttonClasses } from "@mui/material/Button";

import Image from "../../../global/components/image";
import Iconify from "../../../global/components/iconify";
import { varFade, MotionViewport } from "../../components/animate";
import { playStoreUrl, appStoreUrl } from "../../app/WebsiteAppConsts";
import { useResponsive } from "../../../global/hooks/use-responsive";

// ----------------------------------------------------------------------

const StyledAppStoreButton = styled(Button)(({ theme }) => ({
  flexShrink: 0,
  padding: "5px 12px",
  color: theme.palette.common.white,
  border: `solid 1px ${alpha(theme.palette.common.black, 0.24)}`,
  background: `linear-gradient(180deg, ${theme.palette.grey[900]} 0%, ${theme.palette.common.black} 100%)`,
  [`& .${buttonClasses.startIcon}`]: {
    marginLeft: 0,
  },
}));

// ----------------------------------------------------------------------

function AppStoreButton({ ...other }: StackProps) {
  return (
    <Stack direction="row" flexWrap="wrap" spacing={2} {...other}>
      <StyledAppStoreButton
        onClick={() => window.open(appStoreUrl, "_blank")}
        startIcon={<Iconify icon="ri:apple-fill" width={28} />}
      >
        <Stack alignItems="flex-start">
          <Typography variant="caption" sx={{ opacity: 0.72 }}>
            זמינה להורדה
          </Typography>

          <Typography variant="h6" sx={{ mt: -0.5 }}>
            Apple Store
          </Typography>
        </Stack>
      </StyledAppStoreButton>

      <StyledAppStoreButton
        onClick={() => window.open(playStoreUrl, "_blank")}
        startIcon={<Iconify icon="logos:google-play-icon" width={28} />}
      >
        <Stack alignItems="flex-start">
          <Typography variant="caption" sx={{ opacity: 0.72 }}>
            זמינה בחנות
          </Typography>

          <Typography variant="h6" sx={{ mt: -0.5 }}>
            Google Play
          </Typography>
        </Stack>
      </StyledAppStoreButton>
    </Stack>
  );
}

// ----------------------------------------------------------------------

export default function HomeDownloadApp() {
  const mdUp = useResponsive("up", "md");

  const rentedContent = (
    <>
      <Stack
        sx={{
          textAlign: { xs: "center", md: "unset" },
        }}
      >
        <Typography variant="h2"> אפליקציה לנייד </Typography>

        <Typography sx={{ color: "text.secondary", mt: 3, mb: 8 }}>
          אפליקציה חדישה ונוחה המאפשרת לך לבצע את כל הפעולות בקלות ובנוחות.
          האפליקציה מאפשרת לבדוק את תקינות הרכבים בשטח, להפיק ולשלוח את דוחות
          הבדיקה בזמן אמת.
        </Typography>
      </Stack>

      <Stack
        alignItems="center"
        sx={{
          py: 5,
          borderRadius: 2,
          mb: { xs: 8, md: 0 },
          px: { xs: 3, md: 5 },
          border: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        {mdUp && (
          <>
            <Stack spacing={3} direction="row" alignItems="center">
              <Image
                src="/assets/images/home/app_download_qr.png"
                sx={{ width: 120, height: 120 }}
              />

              <Typography variant="h6">
                סרוק את הקוד
                <br /> בכדי להוריד את האפליקציה
              </Typography>
            </Stack>

            <Divider sx={{ my: 5, width: 1, borderStyle: "dashed" }} />
          </>
        )}

        <AppStoreButton direction={{ xs: "column", sm: "row" }} />
      </Stack>
    </>
  );

  const renderImg = (
    <Image
      alt="mobile app"
      src="/assets/images/home/download_app.png"
      sx={{
        maxWidth: 564,
        filter: (theme) =>
          `drop-shadow(0 48px 80px ${alpha(theme.palette.common.black, 0.24)})`,
      }}
    />
  );

  return (
    <Container component={MotionViewport} sx={{ py: { xs: 8, md: 15 } }}>
      <Grid container spacing={3} justifyContent={{ lg: "space-between" }}>
        <Grid xs={12} md={6} lg={6}>
          <m.div variants={varFade().inUp}>
            {mdUp ? renderImg : rentedContent}
          </m.div>
        </Grid>

        <Grid xs={12} md={6} lg={5}>
          <m.div variants={varFade().inUp}>
            {mdUp ? rentedContent : renderImg}
          </m.div>
        </Grid>
      </Grid>
    </Container>
  );
}
