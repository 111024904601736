import { useState } from "react";

import HomeHero from "../home-hero";
import HomeFAQs from "../home-faqs";
import HomeCombination from "../home-combination";
import PricingHome from "../../pricing/pricing-home";
import HomeFeatureHighlights from "../home-feature-highlights";
import HomeContactForm from "../home-contact-form";
import { PATH_WEBSITE } from "../../../routes/paths";
import HomeDownloadApp from "../home-download-app";

// ----------------------------------------------------------------------

export default function HomeView() {
  const [isPreviewRequestClicked, setIsPreviewRequestClicked] = useState(false);
  const handleOnContactUsClicked = () => {
    setIsPreviewRequestClicked(true);
  };

  // const handleProductSelected = (_product: WebsiteProduct) => {};

  return (
    <>
      <section id={PATH_WEBSITE.home_anchor_top}>
        <HomeHero />
      </section>

      <section id={PATH_WEBSITE.home_anchor_about}>
        <HomeFeatureHighlights />
        <HomeDownloadApp />
        <HomeCombination onContactUsClicked={handleOnContactUsClicked} />
      </section>

      <section id={PATH_WEBSITE.home_anchor_pricing}>
        <PricingHome
          // products={[]}
          sx={{
            pt: 5,
            pb: 5,
          }}
          // onSelected={handleProductSelected}
        />
      </section>

      <section id={PATH_WEBSITE.home_anchor_faq}>
        <HomeFAQs />
      </section>

      <section id={PATH_WEBSITE.home_anchor_contact}>
        <HomeContactForm isContactUsClicked={isPreviewRequestClicked} />
      </section>
    </>
  );
}
